import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import ReactDOM from 'react-dom'

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideClickListener = (ref: any, onOutsideClick: () => void) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
         const handleClickOutside = (event: any) =>{ 
            const domNode = ReactDOM.findDOMNode(ref.current) 
            if (!domNode || !domNode.contains(event.target)) {
                onOutsideClick()
            }
        }
        // Bind the event listener
        document.addEventListener("click", handleClickOutside, false)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside, false)
        }
    }, [ref, onOutsideClick])
}

/**
 * Component that alerts if you click outside of it
 */
 const OutsideClickListener = (props: { onOutsideClick: any; children: any }) =>{
    const wrapperRef = useRef(null)
    useOutsideClickListener(wrapperRef, props.onOutsideClick)
    return <div ref={wrapperRef}>{props.children}</div>
}

OutsideClickListener.propTypes = {
    children: PropTypes.element.isRequired,
    onOutsideClick: PropTypes.func.isRequired
}

export default OutsideClickListener
