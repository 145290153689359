import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 8;
  position: fixed;
  justify-content: center;
  align-items: center;
`;

export const HelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(255,255,255,0.8);
  width: 50vw;
  height: 85vh;
  justify-content: space-between;
  align-items: center;
  padding-top: 20pt;
  padding-bottom: 20pt;
  overflow: auto;

  @media(max-width: 450px){
    display: none;
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  cursor: pointer;
  &:hover{
    background-color: rgb(200,200,200, 0.4);
  }
`;

