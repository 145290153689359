import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 15;
  left: 0;
  top: 0;
  width: 100%;
  height: 55px;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  border-bottom: 1px solid rgb(240, 240, 240);
`

export const Container = styled.div`
  display: none;
  background-color: white;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  position: fixed;
  justify-content: center;
  @media(max-width: 450px){
    display: flex;
  }
`;

export const HelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(255,255,255,0.8);
  justify-content: space-between;
  align-items: center;
  height: 100%;
  overflow-y: auto;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-grow: 1; 
  width: 80%;
  flex-direction: column;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  cursor: pointer;
  &:hover{
    background-color: rgb(200,200,200, 0.4);
  }
`;

