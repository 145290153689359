export enum ReviewTypes {
    QUESTIONS_REQUEST = "@review/QUESTIONS_REQUEST",
    QUESTIONS_SUCCESS = "@review/QUESTIONS_SUCCESS",
    QUESTIONS_FAILURE = "@review/QUESTIONS_FAILURE",
    REVIEW_REQUEST = "@review/REVIEW_REQUEST",
    REVIEW_SUCCESS = "@review/REVIEW_SUCCESS",
    REVIEW_FAILURE = "@review/REVIEW_FAILURE",
    QUESTIONS_RESET_STATE= "@review/QUESTIONS_RESET_STATE"
}

export interface Question {
    help: string;
    id: string;
    primary: boolean;
    text: string;
    type: string;
    alternatives?: Alternative[];
}

export interface Alternative {
    text: string;
    value: number;
}

export interface ReviewState {
    readonly data: any;
    readonly error: boolean;
    readonly loading: boolean;
    readonly sent: boolean;
}
