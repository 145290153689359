import { call, put } from "redux-saga/effects";

import api from "../../../services/api";
import {
  venuesSuccess,
  venuesFailure,
  searchVenuesFailure,
  searchVenuesSuccess,
  singleVenueSuccess,
  singleVenueFailure,
} from "./actions";

import {defaultLocation} from '../../../constants'

export function* loadVenues(action: any) {
  try {
    let params = "?locale=pt-br";
    const token = localStorage.getItem("AUTH_DATA");
    const header = {
      Authorization: "",
    };

    if (action.lat && action.lng) {
      params = params + "&lat=" + action.lat + "&lng=" + action.lng;
    } else {
      params = params + "&lat=" + defaultLocation.lat + "&lng=" + defaultLocation.lng;
    }

    if (token) {
      header.Authorization = "Bearer " + token;
    }

      //console.log('Params:\n',params)
    const response = yield call(api.get, "mobile/venues_ext" + params, {
      headers: header,
    });

    yield put(venuesSuccess(response.data.venues));
  } catch (err) {
    yield put(venuesFailure());
  }
}

export function* searchVenues(action: any) {
  try {
    let params = "?locale=pt-br";

    if (action.query !== null){
      params = params + "&query=" + action.query;
    }

    if (action.lat && action.lng) {
      params = params + "&lat=" + action.lat + "&lng=" + action.lng;
    } else {
      params = params + "&lat=" + defaultLocation.lat + "&lng=" + defaultLocation.lng;
    }

    if (action.category_id) {
      params = params + "&category=" + action.category_id;
    }

    if (action.accessibility_filter && action.accessibility_filter !== -1) {
      params = params + "&accessibility_filter=" + action.accessibility_filter;
    }

    if (action.stateUF && action.city){
      params = params + "&state=" + action.stateUF + "&city=" + action.city
    }
    //console.log('Params:\n',params)
    const response = yield call(api.get, "venues" + params);

    yield put(searchVenuesSuccess(response.data));
  } catch (err) {
    yield put(searchVenuesFailure());
  }
}

export function* loadSingleVenue(action: any) {
  try {
    let params = "/" + action.venueId + "?locale=pt-br";

    if (action.lat && action.lng) {
      params = params + "&lat=" + action.lat + "&lng=" + action.lng;
    }
  
    const response = yield call(api.get, "venues" + params);

    yield put(singleVenueSuccess(response.data));
  } catch (err) {
    yield put(singleVenueFailure());
  }
}