import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 6;
  position: fixed;
  justify-content: center;
  align-items: center;
`;


export const Container = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 6;
  position: fixed;
  justify-content: center;
  align-items: center;

  @media(max-width: 450px) {
    display: none;
  }
`;



export const CloseContainer = styled.div`
  position: absolute;
  height: 35px;
  right: 27%;
  top: 7%;
  background-color: rgba(0,0,0,0.4);
  border-radius: 50%;
  cursor: pointer;

  @media(max-width: 1440px) and (min-width: 1024px){
    right: 27%;
  } 

  @media(max-width: 1024px) and (min-width: 768px){
    right: 25%;
  } 

  @media(max-width: 768px) and (min-width: 540px){
    right: 15%;
  } 
  @media(max-width: 540px) and (min-width: 450px){
    right: 5%;
  }
`

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  width: 50vw;
  min-width: 576.5px;
  height: 85vh;
  justify-content: center;
  align-items: center;
  padding-top: 20pt;
  padding-bottom: 20pt;

  img {
    width: 50px;
    height: 70px;
  }

  input {
    width: 24vw;
    min-width: 284.41px;
    padding: 10pt;
    border: 1px solid rgb(200, 200, 200);
    border-radius: 4pt;
    cursor: pointer;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26vw;
    min-width: 304.41px;
    height: 44px;
    border: 0px none;
    border-radius: 4pt;
    cursor: pointer;
    margin-bottom: 10pt;
  }

  @media(max-width: 450px) {
    display: none;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  width: 50vw;
  min-width: 576.5px;
  background-color: rgb(0, 187, 55);
  position: absolute;
  bottom: 4%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12pt;
  margin-top: 30px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37%;
  height: 40pt;
  border-radius: 5pt;
  background-color: #d90368;
  font-size: 14pt;
  color: white;
  border: 0px none;
  cursor: pointer;

  :disabled {
    background-color: rgba(214, 8, 107, 0.4);
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  span {
    color: red;
    font-size: 18px;
  }
`;