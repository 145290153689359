import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: fixed;
`;

export const Header = styled.div`
  width: 100%;
  height: 20%;
  background-color: rgb(0, 187, 55);
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-left: 47%;
    font-size: 18pt;
    font-weight: bold;
    color: white;
  }
`;

export const Body = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  background-color: rgb(247, 247, 247);
  padding-right: 12pt;
  padding-left: 12pt;
  padding-top: 170pt;
  padding-bottom: 70pt;
`;

export const Card = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 16pt;
  padding-left: 10pt;
  padding-right: 10pt;
  padding-bottom: 10pt;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Name = styled.span`
  font-size: 18pt;
  color: rgba(0, 0, 0, 0.77);
  font-weight: bold;
  margin-top: 6pt;
`;

export const State = styled.span`
  margin-top: 10pt;
  font-size: 14pt;
  color: rgba(0, 0, 0, 0.6);
`;

export const City = styled.span`
  font-size: 12pt;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 10pt;
`;

export const Category = styled.span`
  font-size: 13pt;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 1pt;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  position: relative;
  margin-top: -100pt;
  margin-right: 16px;
`;

export const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  padding: 12pt;
  border-radius: 4pt;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
  background-color: rgb(77, 208, 115);
  cursor: pointer;

  span {
    font-family: Helvetica;
    font-size: 10pt;
    color: white;
  }
`;
