import React from "react"
import { Container, HeaderContainer, RateContainer } from "./styles"
import { FiX } from "react-icons/fi"

interface OwnProps {
  handleCloseClicked(): void
  handleBackClicked(): void
  renderBackButton: any
  renderQuestions: any
  renderFinalScreen: any
  renderNextButton: any
  renderHelpButton: any
}

export default ({
  handleCloseClicked,
  handleBackClicked,
  renderBackButton,
  renderQuestions,
  renderFinalScreen,
  renderNextButton,
  renderHelpButton,
}: OwnProps) => {
  return (
    <Container>
      <HeaderContainer>
        <div
          style={{
            marginLeft: 10,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleBackClicked}
        >
          {renderBackButton}
          <span style={{ marginLeft: 20 }}>Avaliar</span>
        </div>
        <FiX
          style={{ cursor: "pointer", position: "fixed", right: 15, top: 15 }}
          size={25}
          color={"rgb(48,48,48)"}
          onClick={handleCloseClicked}
        />
      </HeaderContainer>

      <RateContainer>
        {renderQuestions}
        {renderFinalScreen}
        {renderNextButton}
        {renderHelpButton}
      </RateContainer>
    </Container>
  )
}
