import { FeedbackState, FeedbackTypes } from "./types";
import { Reducer } from "redux";

const INITIAL_STATE = {
  loading: false,
  error: false,
};

const reducer: Reducer<FeedbackState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FeedbackTypes.FEEDBACK_REQUEST:
      return { ...state, loading: true };
    case FeedbackTypes.FEEDBACK_SUCCESS:
      return { ...state, loading: false, error: false };
    case FeedbackTypes.FEEDBACK_FAILURE:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};

export default reducer;
