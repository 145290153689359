import styled from "styled-components";

interface Props {
  borderRadiusTop?: boolean;
  borderRadiusBottom?: boolean;
  titleMargin?: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Header = styled.div`
  flex: 1;
  height: 20%;
  background-color: rgb(0, 187, 55);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 12pt;
  padding-right: 12pt;

  p {
    margin-left: 20pt;
    font-size: 18pt;
    font-weight: bold;
    color: white;
  }
`;

export const Body = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  background-color: rgb(247, 247, 247);
  padding-right: 12pt;
  padding-left: 12pt;
  padding-top: 30pt;
  padding-bottom: 30pt;

  a {
    text-decoration: none;
  }
`;

export const Card = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  margin-top: -70pt;
  margin-bottom: 16pt;
  padding-left: 10pt;
  padding-right: 10pt;
  padding-bottom: 10pt;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Name = styled.span`
  font-size: 18pt;
  color: rgba(0, 0, 0, 0.77);
  font-weight: bold;
  margin-top: 6pt;
`;

export const State = styled.span`
  margin-top: 10pt;
  font-size: 14pt;
  color: rgba(0, 0, 0, 0.6);
`;

export const City = styled.span`
  font-size: 12pt;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 10pt;
`;

export const Category = styled.span`
  font-size: 13pt;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 1pt;
`;

export const Title = styled.h1`
  font-size: 16pt;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 16pt;
  margin-top: ${(props: Props) => `${props.titleMargin}pt`};
`;

export const DetailsCard = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  border-top-left-radius: ${(props: Props) =>
    props.borderRadiusTop === true ? "4px" : "0px"};
  border-top-right-radius: ${(props: Props) =>
    props.borderRadiusTop === true ? "4px" : "0px"};
  border-bottom-left-radius: ${(props: Props) =>
    props.borderRadiusBottom === true ? "4px" : "0px"};
  border-bottom-right-radius: ${(props: Props) =>
    props.borderRadiusBottom === true ? "4px" : "0px"};
  padding-bottom: 10pt;
  padding-right: 10pt;
  padding-left: 10pt;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  h2 {
    font-size: 14pt;
    color: rgba(0, 0, 0, 0.77);
  }

  span {
    font-size: 12pt;
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const Address = styled(DetailsCard)`
  flex-direction: row;
  padding-top: 12pt;
  padding-bottom: 12pt;
  justify-content: space-between;

  span {
    font-size: 13pt;
    color: rgba(0, 0, 0, 0.75);
    max-width: 70%;
  }
`;

export const LocationCard = styled(Address)`
  span {
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 200pt;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
