import styled from "styled-components"

interface Props {
  height?: number
}

export const Container = styled.div`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
`

export const Alert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  height: 30%;
  background-color: white;
  border-radius: 10px;
  padding: 12px;
  @media(max-width: 550px){
    width: 80%;
    height: 35%;
  } 
`

export const Title = styled.span`
  font-size: 25px;
  font-weight: bold;
`

export const Message = styled.span`
  font-size: 20px;
  text-align: center;
`

export const Button = styled.button`
  font-size: 20px;
  background-color: white;
  border: 0px none;
  cursor: pointer;
`
