import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background-color: rgb(247, 247, 247);
  margin-top: 83px;
  padding-left: 10px;
  padding-right: 10px;

  @media(max-width: 450px) {
    display: none;
  }
`;

export const VenueSide = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 10px;
`;

export const VenueContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1026px;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: white;
  height: max-content;

  img {
    width: 100%;
    height: 318px;
    border-top-left-radius: 4pt;
    border-top-right-radius: 4pt;
  }

  @media(max-width: 330px) {
    max-width: 260px;
  }
  @media(max-width: 1100px) and (min-width: 1000px) {
    max-width: 750px;
  }
  @media(max-width: 1000px) and (min-width: 760px) {
    max-width: 650px;
  }
  @media(max-width: 760px) and (min-width: 600px)  {
    max-width: 500px;
  }
  @media(max-width: 600px) and (min-width: 500px)  {
    max-width: 400px;
  }
  @media(max-width: 500px) and (min-width: 330px)  {
    max-width: 300px;
  }
`;

export const MapSide = styled.div`
  display: flex;
  width: 50%;

  @media(max-width: 600px), (max-height: 600px) and (orientation: landscape) {
    display: none;
  }
`;

export const MapContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 89vh;
`;

export const VenueCertificationBadge = styled.div`
  display: flex;
  background-color: ${props => props.color ? props.color: 'rgb(21, 111, 179)'};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: white;
  padding: 0px;
  padding-left: 25px;
  padding-right: 10px;
  font-size: 12pt;
  font-weight: bold;
  margin-left: -5px;
  margin-top: 205px;
  border-radius: 3px;
  position: absolute;
  width: max-content;
  height: 30px;

  #certification-badge-image {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  @media(max-width: 450px) {
    margin-top: 230px;
    margin-left: 0px;
    padding-left: 12px;

  #certification-badge-image {
    width: 30px;
    height: 30px;
  }
  }
`;


export const VenueBadge = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
  padding: 0px;
  padding-right: 10px;
  padding-left: 20px;
  width: max-content;
  height: 30px;
  font-size: 14pt;
  font-weight: bold;
  margin-left: -5px;
  margin-top: 245px;
  border-radius: 3px;
  position: absolute;

  @media(max-width: 450px) {
    margin-top: 270px;
    font-size: 14pt;
    margin-left: 0px;
  }
`;

export const VenueInfos = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20pt;
  width: 100%;
`;

export const RateButton = styled.button`
  padding: 10pt;
  padding-left: 20pt;
  padding-right: 20pt;
  color: white;
  border: 0px none;
  background-color: #d90368;
  font-size: 14pt;
  border-radius: 4pt;
  cursor: pointer;
`;

export const AccessibilityInfos = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 20px;
  margin-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(240, 240, 240);
  border-top: 1px solid rgb(240, 240, 240);

  @media(max-width: 450px) {
    padding-top: 0px;
  }
`;

export const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;

  span {
    font-size: 13pt;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
  }

  @media(max-width: 760px) and (min-width: 600px)  {
    max-width: 500px;
  }
  @media(max-width: 600px) and (min-width: 500px)  {
    max-width: 380px;
  }
  @media(max-width: 500px) and (min-width: 450px)  {
    max-width: 280px;
  }
  @media(max-width: 450px) and (min-width: 300px)  {
    width: 85%;
  }
`;

export const CommentCard = styled.div`
  display: flex;
  width: 88%;
  flex-direction: row;
  padding: 10px;
  margin-top: 5px;
  background-color: rgb(247, 247, 247);
  border-radius: 4px;

  #comment-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #d90368;
  }

  @media(max-width: 450px) {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    #comment-avatar {
      width: 50px;
      height: 50px; 
    }
  }
`;


export const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
  position: absolute;
  z-index: 2;

  span, a {
    color: black;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;

    :hover {
      background-color: rgb(225, 225, 225);
    }
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid rgb(200, 200, 200);
    padding-left: 10px;

    :hover {
      background-color: rgb(225, 225, 225);
    }
  }
`;



export const ShareContainer = styled.div`
  width: 100%;
  position: absolute;
  margin-top: 240px;
  text-align: right;

  #share-icon {
    margin-right: 60px;
  }

  @media(max-width: 1100px) { 
    #share-icon {
      margin-right: 30px;
    }
  
  }
`;
