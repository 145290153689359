import styled from "styled-components";

export const MobileHeaderContainer = styled.div`
  display: none;
  position: absolute;
  @media(max-width: 450px){
    display: block;
    margin-top: -5px;
  } 
`

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: fixed;
  z-index: 2;
`;

export const Header = styled.div`
  height: 79px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20pt;
  padding-right: 20pt;
  padding-top: 5pt;
  padding-bottom: 5pt;
  z-index: 2;

  border-bottom: 2px solid rgb(0, 0, 0, 0.05);
`;

export const Menu = styled.div`
  @media(max-width: 370px) and (min-width: 300px) {
    margin-right: 10px
  }
  @media(max-width: 390px) and (min-width: 370px) {
    margin-right: -15px
  }
  @media(max-width: 450px) and (min-width: 390px) {
    margin-right: -35px
  }
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img {
    width: 120px;
    height: 36px;
    margin-left:8px;
  }
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  width: 45%;
  justify-content: flex-end;
  align-items: center;

  span {
    color: rgb(51, 51, 51);
    font-weight: 800;
    cursor: pointer;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 14px;
    padding-left: 14px;
    border-radius: 4px;

    :hover {
      background-color: rgba(0, 187, 55, 0.23);
    }
  }
`;

export const UserIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  color: black;
  background-color: rgb(200, 200, 200);
  text-align: center;
  border-radius: 50%;
  margin-right: 8px;

  span {
    font-size: 22px;
    color: rgb(51, 51, 51);
    :hover {
      background-color: transparent;
    }
  }
`;

export const ServicesModal = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(0, 187, 55);
  position: fixed;
  z-index: 2;
  border-radius: 4pt;
  right: 31%;
  top: 5%;
  border: 1px solid rgba(0, 0, 0, 0.1);

  span {
    color: white;
    font-weight: 800;
    cursor: pointer;
    padding: 10pt;
    border-radius: 4pt;
    font-size: 10pt;

    :hover {
      background-color: rgba(255, 255, 255, 0.25);
    }
  }
`;
