import React, { Component } from "react"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import { ApplicationState } from "../../store"

import { logOut } from "../../store/ducks/user/actions"
import { UserInfos } from "../../store/ducks/user/types"
import { Container, EditButton, ProfileModal, UserInfo, UserInfoBox } from "./styles"
import MobileProfile from './mobile/MobileProfile'

interface OwnProps {
  user: UserInfos | null
  changeModal(renderEditProfile: boolean, renderFeedback: boolean, renderProfile: boolean): void
}

interface DispatchProps {
  logOut(): void
}

type Props = OwnProps & DispatchProps

class Profile extends Component<Props> {
  render() {
    const { user, logOut } = this.props

    return (
      <Container>
        <ProfileModal>
          <span
            style={{
              fontSize: "18pt",
              fontWeight: "bold",
              margin: "20pt",
              marginBottom: "0pt",
            }}
          >
            {user?.first_name}
          </span>
          <span style={{ fontSize: "13pt", margin: "20pt", marginTop: "0pt" }}>
            {user?.email}
          </span>
          <UserInfo>
            <UserInfoBox style={{ borderRight: "1px solid rgb(240,240,240)" }}>
              <span
                style={{
                  fontSize: "9pt",
                  color: "rgb(0,0,0,0.8)",
                  fontWeight: "bold",
                }}
              >
                SEXO
            </span>
              <span style={{ marginTop: "5pt", color: "rgba(0,0,0,0.6)" }}>
                {this.renderGender(user?.gender)}
              </span>
            </UserInfoBox>
            <UserInfoBox
              style={{ borderRight: "1px solid rgb(240,240,240)", width: "70px" }}
            >
              <span
                style={{
                  fontSize: "9pt",
                  color: "rgb(0,0,0,0.8)",
                  fontWeight: "bold",
                }}
              >
                IDADE
            </span>
              <span style={{ marginTop: "5pt", color: "rgba(0,0,0,0.6)" }}>
                {this.calculateAge(user?.birthday)}
              </span>
            </UserInfoBox>
            <UserInfoBox>
              <span
                style={{
                  fontSize: "9pt",
                  color: "rgb(0,0,0,0.8)",
                  fontWeight: "bold",
                }}
              >
                AVALIAÇÕES
            </span>
              <span style={{ marginTop: "5pt", color: "rgba(0,0,0,0.6)" }}>
                {user?.rating_qty}
              </span>
            </UserInfoBox>
          </UserInfo>

          <div
            style={{ margin: "20px", marginTop: 5, marginBottom: 5, paddingTop: 10, paddingBottom: 10 }}
          > <EditButton
            onClick={() => {
              this.props.changeModal(true, false, false)
            }}>
              Editar Perfil
            </EditButton>
          </div>
          <div
            style={{
              padding: "20pt",
              borderTop: "1px solid rgb(240,240,240)",
              borderBottom: "1px solid rgb(240,240,240)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                fontSize: "9pt",
                color: "rgb(0,0,0,0.8)",
                fontWeight: "bold",
              }}
            >
              USUÁRIO DE CADEIRA DE RODAS
          </span>
            <span style={{ marginTop: "5pt", color: "rgb(0,0,0,0.6)" }}>
              {user?.wheelchair ? "Sim" : "Não"}
            </span>

            <span
              style={{
                fontSize: "9pt",
                color: "rgb(0,0,0,0.8)",
                fontWeight: "bold",
                marginTop: "10pt",
              }}
            >
              TEM OUTRA DEFICIÊNCIA
          </span>
            <span style={{ marginTop: "5pt", color: "rgb(0,0,0,0.6)" }}>
              {this.formatDisability(user?.other_disability)}
            </span>

            <span
              style={{
                fontSize: "9pt",
                color: "rgb(0,0,0,0.8)",
                fontWeight: "bold",
                marginTop: "10pt",
              }}
            >
              TEM AMIGO(S) COM DEFICIÊNCIA
          </span>
            <span style={{ marginTop: "5pt", color: "rgb(0,0,0,0.6)" }}>
              {user?.has_friend ? "Sim" : "Não"}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "20pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                color: "rgb(0,0,0,0.7)",
                fontWeight: "bold",
              }}
            >
              Dúvidas ou sugestões?
          </span>
            <span
              onClick={() => this.props.changeModal(false, true, true)}
              style={{
                fontSize: "12pt",
                color: "#D90368",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Envie-nos seu feedback!
          </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20pt",
              borderTop: "1px solid rgb(240,240,240)",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                color: "#D90368",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => {
                logOut()
                this.props.changeModal(false, false, false)
              }}
            >
              LOGOUT
          </span>
          </div>
        </ProfileModal>
        <MobileProfile
          user={user}
          renderGender={this.renderGender(user?.gender)}
          calculateAge={this.calculateAge(user?.birthday)}
          formatDisability={this.formatDisability(user?.other_disability)}
          handleEditClicked={() => {
            this.props.changeModal(true, false, false)
          }}
          handleFeedbackClicked={() => this.props.changeModal(false, true, true)}
          handleLogoutClicked={() => {
            logOut()
            this.props.changeModal(false, false, false)
          }}
          handleCloseClicked={() => {
            this.props.changeModal(false, false, false)
          }}
        />
      </Container>)
  }

  renderGender(gender: string | undefined) {
    if (gender !== undefined && gender.trim().length > 0) {
      switch (gender) {
        case "male":
          return "Masculino"
        case "female":
          return "Feminino"
        case "other":
          return "Outros"
      }
    } else {
      return "-"
    }
  }

  calculateAge(birthday: string | undefined) {
    const today = new Date()
    const birthDate = birthday !== undefined ? this.toDate(birthday) : null
    if (!birthDate) return "-"

    var yearsDifference: number = today.getFullYear() - birthDate.getFullYear()
    if (
      new Date(today.getFullYear(), today.getMonth(), today.getDate()) <
      new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate())
    )
      yearsDifference--

    if (!yearsDifference || yearsDifference < 0) {
      return ""
    }
    return yearsDifference
  }

  formatDisability(disability: string | undefined) {
    switch (disability) {
      case "":
        return "Não"
      case "physical":
        return "Sim - Física"
      case "visual":
        return "Sim - Visual"
      case "hearing":
        return "Sim - Auditiva"
      case "intellectual":
        return "Sim - Intelectual"
      case "multiple":
        return "Sim - Múltiplas"
    }
  }

  toDate(dateStr: string) {
    if (!dateStr) return null
    const [day, month, year] = dateStr.split("/")
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
  }
}

const mapStateToProps = (state: ApplicationState) => ({})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    logOut: () => dispatch(logOut()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
