import { AiFillFacebook } from "react-icons/ai";
import React, { Component } from "react";
import ModuleFacebookLogin, {
  ReactFacebookLoginInfo,
} from "react-facebook-login";

import { User } from "../../store/ducks/user/types";
import { Container } from "./styles";

interface OwnProps {
  loginRequest(
    grant_type: string,
    user: User | null,
    access_token: string
  ): void;
}

type Props = OwnProps;

export default class FacebookButton extends Component<Props> {
  
  render() {
      return (
        <Container>
          <ModuleFacebookLogin
            textButton="Entrar com Facebook"
            cssClass="facebook"
            appId={process.env.REACT_APP_FACEBOOK_KEY || ""}
            fields="name, email, picture"
            callback={(response: ReactFacebookLoginInfo) => {
                this.props.loginRequest("facebook", null, response.accessToken)
            }}
            icon={
              <AiFillFacebook
                color="white"
                size={30}
                style={{ marginRight: 12 }}
              />
            }
          />
        </Container>
      );
  }
}
