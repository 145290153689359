export enum FeedbackTypes {
    FEEDBACK_REQUEST = "@feedback/FEEDBACK_REQUEST",
    FEEDBACK_SUCCESS = "@feedback/FEEDBACK_SUCCESS",
    FEEDBACK_FAILURE = "@feedback/FEEDBACK_FAILURE",
}

export interface FeedbackModel {
    user_id: string;
    user_email: string;
    user_name: string;
    subject: string;
    content: string;
    rating: number;
    ratingText: string;
}

export interface FeedbackState {
    readonly loading: boolean;
    readonly error: boolean
}
