import React, { Component } from "react"
import { FaLessThan } from "react-icons/fa"

import bgImage from "../../resources/img/venue_placeholder.png"
import { Question } from "../../store/ducks/review/types"
import { Container, HelpContainer, QuestionContainer } from "./styles"
import MobileHelp from './mobile/MobileHelp'

interface OwnProps {
  questions: Array<Question[]>
  currentQuestion: number
  hideHelp(): void
}

type Props = OwnProps

export default class Help extends Component<Props> {
  state = {
    showDoubt: `${this.props.currentQuestion}0`,
  };

  render() {
    const { showDoubt } = this.state
    const { questions, currentQuestion } = this.props

    return (
      <Container>
        {window.innerWidth > 450 ? (<div
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            borderRadius: "4px",
          }}
        >
          {this.renderBackButton()}
          <HelpContainer
            style={{
              backgroundColor: "rgba(255,255,255,0.8)",
            }}
          >
            <span
              style={{
                fontSize: "18pt",
                fontWeight: "bold",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              Ajuda
            </span>
            {questions.map((subQuestions, subQuestionsIndex) => {
              return subQuestions.map((question, questionIndex) => {
                return (
                  <QuestionContainer
                    style={{
                      marginTop: subQuestionsIndex === 0 ? "20pt" : "0pt",
                    }}
                    onClick={() =>
                      this.setState({
                        showDoubt: `${subQuestionsIndex}${questionIndex}`,
                      })
                    }
                  >
                    <h3
                      style={{
                        fontSize: "13pt",
                        fontWeight:
                          subQuestionsIndex === currentQuestion
                            ? "bold"
                            : "normal",
                      }}
                    >
                      {question.text}
                    </h3>
                    {(subQuestionsIndex === currentQuestion ||
                      `${subQuestionsIndex}${questionIndex}` === showDoubt) &&
                      this.formatHelp(question.help, subQuestionsIndex)}
                  </QuestionContainer>
                )
              })
            })}
          </HelpContainer>
        </div>) : null}
        <MobileHelp {...this.props} />
      </Container>
    )
  }

  formatHelp(questionHelp: string, index: number) {
    switch (index) {
      case 0:
        return (
          <span
            style={{
              marginBottom: "15pt",
              fontSize: "12pt",
            }}
          >
            {questionHelp}
          </span>
        )
      case 1:
        const newStrings = questionHelp.split("-")
        const length = newStrings.length
        return newStrings.map((string, index) => {
          return (
            <span
              style={{
                marginBottom: length - 1 === index ? "15pt" : "5pt",
                fontSize: "12pt",
              }}
            >
              {index > 0 && "- "}
              {string}
            </span>
          )
        })
      case 2:
        const newStrings1 = questionHelp.split("-")
        const length1 = newStrings1.length
        return newStrings1.map((string, index) => {
          return (
            <span
              style={{
                marginBottom: length1 - 1 === index ? "15pt" : "5pt",
                fontSize: "12pt",
              }}
            >
              {index > 0 && "- "}
              {string}
            </span>
          )
        })
      case 3:
        const splited = questionHelp.split('"')
        const size = splited.length
        return splited.map((string, index) => {
          return (
            <span
              style={{
                marginBottom: size - 1 === index ? "15pt" : "5pt",
                fontSize: "12pt",
              }}
            >
              {(index > 0 && index % 2 !== 0) && "- "}
              {(index === 0 || index % 2 !== 0) && this.formatString(string, index)}
            </span>
          )
        })
    }
  }

  formatString(string: string, index: number) {
    if (index > 0) {
      return `"${string}"`
    } else {
      return string
    }
  }

  renderBackButton() {
    const { hideHelp } = this.props

    return (
      <div
        onClick={() => hideHelp()}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          left: "26%",
          top: "6.5%",
          borderRadius: "50%",
          cursor: "pointer",
        }}
      >
        <FaLessThan size={15} color={"rgba(0,0,0,0.6)"} />
        <span
          style={{
            color: "rgba(0,0,0,0.6)",
            marginLeft: "5pt",
            fontSize: "14pt",
            fontWeight: "bold",
          }}
        >
          Voltar
        </span>
      </div>
    )
  }
}
