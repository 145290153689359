import { action } from "typesafe-actions";
import { VenuesTypes, Venue, SearchTypes } from "./types";

export const singleVenueRequest = (
  venueId: string,
  lat: number | null,
  lng: number | null
) => ({
  type: VenuesTypes.SINGLE_VENUE_REQUEST,
  venueId,
  lat,
  lng,
});

export const singleVenueSuccess = (data: Venue) =>
  action(VenuesTypes.SINGLE_VENUE_SUCCESS, { data });

export const singleVenueFailure = () =>
  action(VenuesTypes.SINGLE_VENUE_FAILURE);

export const venuesRequest = (lat: number | null, lng: number | null) => ({
  type: VenuesTypes.VENUES_REQUEST,
  lat,
  lng,
});

export const venuesSuccess = (data: Venue[]) =>
  action(VenuesTypes.VENUES_SUCCESS, { data });

export const venuesFailure = () => action(VenuesTypes.VENUES_FAILURE);

export const searchVenuesRequest = (
  query: string | null,
  category_id: number | null,
  accessibility_filter: number | null,
  lat: number | null,
  lng: number | null,
  stateUF: string | null,
  city: string | null,
) => ({
  type: SearchTypes.SEARCH_REQUEST,
  query,
  category_id,
  accessibility_filter,
  lat,
  lng,
  stateUF,
  city,
});

export const searchVenuesSuccess = (data: Venue[]) =>
  action(SearchTypes.SEARCH_SUCCESS, { data });

export const searchVenuesFailure = () => action(SearchTypes.SEARCH_FAILURE);

export const startLoader = () => action(SearchTypes.START_LOADER);