import { BsThreeDotsVertical } from "react-icons/bs";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import CatalogFilter from "../../../featureComponents/catalog/catalogFilter/CatalogFilter";
import { fetchUserRequest } from "../../../store/ducks/user/actions";
import { companiesRequest } from "../../../store/ducks/companies/actions";
import Loading from "../../../featureComponents/loading/LoadingScreen";
import { Company } from "../../../store/ducks/companies/types";
import Footer from "../../../featureComponents/footer/Footer";
import { ApplicationState } from "../../../store";
import {
  Container,
  HeaderContainer,
  Header,
  Body,
  Card,
  Name,
  State,
  City,
  Category,
  MenuContainer,
  Menu,
} from "./styles";

interface StateProps {
  companies: Company[];
  loading: boolean;
}

interface DispatchProps {
  companiesRequest(): void;
  fetchUserRequest(access_token: string): void;
}

type Props = StateProps & DispatchProps;

class Catalog extends Component<Props> {
  componentDidMount() {
    const { companiesRequest } = this.props;

    window.scrollTo(0, 0);
    companiesRequest();
    this.fetchUser();
  }

  state = {
    shouldRenderMenu: false,
    newCompanies: [],
    state: null,
    city: null,
    category: null,
  };

  render() {
    const { companies, loading } = this.props;
    const { shouldRenderMenu } = this.state;

    return (
      <Container>
        <HeaderContainer>
          <Header>
            <p>Catálogo</p>
            <div
              style={{
                marginRight: "12pt",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => this.shouldRenderMenu()}
            >
              <BsThreeDotsVertical color="white" size={24} />
            </div>
          </Header>
          <CatalogFilter
            companies={companies}
            searchState={(state: String) => this.searchState(state)}
            searchCity={(city: String) => this.searchCity(city)}
            searchCategory={(category: String) => this.searchCategory(category)}
          />
          {shouldRenderMenu && this.renderMenu()}
        </HeaderContainer>
        <Body>{this.renderCompanies(companies)}</Body>
        <Footer screen={"catalog"} />
        {loading && (
          <Loading
            height={window.innerHeight}
            title="Carregando"
            subtitle="Lugares"
          />
        )}
      </Container>
    );
  }

  renderCompanies(companies: Company[]): JSX.Element[] {
    const { newCompanies } = this.state;

    if (newCompanies.length > 0) {
      return newCompanies.map((company: Company, index) => {
        return (
          <Link
            to={{
              pathname: "/details",
              state: company,
            }}
            style={{ textDecoration: "none" }}
            key={index}
          >
            <Card>
              <div>
                <Name>{company.name}</Name>
                <State>{company.state}</State>
              </div>
              <Category>{company.category_name}</Category>
              <City>{company.city}</City>
            </Card>
          </Link>
        );
      });
    } else {
      return companies.map((company, index) => {
        return (
          <Link
            to={{
              pathname: "/details",
              state: company,
            }}
            style={{ textDecoration: "none" }}
            key={index}
          >
            <Card>
              <div>
                <Name>{company.name}</Name>
                <State>{company.state}</State>
              </div>
              <Category>{company.category_name}</Category>
              <City>{company.city}</City>
            </Card>
          </Link>
        );
      });
    }
  }

  shouldRenderMenu() {
    const { shouldRenderMenu } = this.state;

    this.setState({ shouldRenderMenu: !shouldRenderMenu });
  }

  renderMenu(): JSX.Element {
    return (
      <MenuContainer>
        <Link
          to={{ pathname: "/service-register", state: { service: null } }}
          style={{ textDecoration: "none" }}
        >
          <Menu>
            <span>Registrar Serviço</span>
          </Menu>
        </Link>
        <Link
          to={{ pathname: "/service-request", state: { service: null } }}
          style={{ textDecoration: "none" }}
        >
          <Menu>
            <span>Requisitar Serviço</span>
          </Menu>
        </Link>
      </MenuContainer>
    );
  }

  fetchUser() {
    const token = localStorage.getItem("AUTH_DATA");

    if (token) {
      const { fetchUserRequest } = this.props;

      fetchUserRequest(token);
    }
  };

  searchState(state: String) {
    const { companies } = this.props;
    const newCompanies: Company[] = [];

    if (state === "todos") {
      companies.forEach((company: Company) => {
        newCompanies.push(company);
      });
    } else {
      companies.forEach((company: Company) => {
        if (company.state === state) {
          newCompanies.push(company);
        }
      });
    }
    this.setState({ newCompanies, state, city: null, category: null });
  }

  searchCity(city: String) {
    const { companies } = this.props;
    const { state, category } = this.state;
    const newCompanies: Company[] = [];

    if (category && category !== "todos") {
      if (city === "todos") {
        companies.forEach((company: Company) => {
          if (company.state === state && company.category_name === category) {
            newCompanies.push(company);
          }
        });
      } else {
        companies.forEach((company: Company) => {
          if (
            company.city === city &&
            company.state === state &&
            company.category_name === category
          ) {
            newCompanies.push(company);
          }
        });
      }
    } else {
      if (city === "todos") {
        companies.forEach((company: Company) => {
          if (company.state === state) {
            newCompanies.push(company);
          }
        });
      } else {
        companies.forEach((company: Company) => {
          if (company.city === city && company.state === state) {
            newCompanies.push(company);
          }
        });
      }
    }
    this.setState({ newCompanies, city });
  }

  searchCategory(category: String) {
    const { companies } = this.props;
    const { state, city } = this.state;
    const newCompanies: Company[] = [];

    if (city && city !== "todos") {
      if (category === "todos") {
        companies.forEach((company: Company) => {
          if (company.state === state && company.city === city) {
            newCompanies.push(company);
          }
        });
      } else {
        companies.forEach((company: Company) => {
          if (
            company.category_name === category &&
            company.state === state &&
            company.city === city
          ) {
            newCompanies.push(company);
          }
        });
      }
    } else {
      if (category === "todos") {
        companies.forEach((company: Company) => {
          if (company.state === state) {
            newCompanies.push(company);
          }
        });
      } else {
        companies.forEach((company: Company) => {
          if (company.category_name === category && company.state === state) {
            newCompanies.push(company);
          }
        });
      }
    }
    this.setState({ newCompanies, category });
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  companies: state.companies.data,
  loading: state.companies.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    companiesRequest: () => dispatch(companiesRequest()),
    fetchUserRequest: (access_token: string) => dispatch(fetchUserRequest(access_token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Catalog);
