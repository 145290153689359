import React from "react"
import { components } from 'react-select'

const { Option, SingleValue } = components
const CustomSelectOption = (props:any) => {
  const icon = props.data.icon;
  return(
  <Option {...props}>
   { icon && (<img
                style={{ height: 30, width: 20,marginRight:8,marginLeft:4 }}
                src={icon}
                alt="destaquePin"
              />)}
    {props.data.label}
  </Option>
)}

const CustomSelectValue = (props:any) => {
  const icon = props.data.icon;
  return(
<>
    { props.data.icon && (<img
                style={{ height: 30, width: 20,marginRight:16 }}
                src={icon}
                alt="destaquePin"
              />)}
    {  !props.data.icon && <SingleValue {...props}/>}
  </>
)}

export {CustomSelectOption,CustomSelectValue}