import { action } from "typesafe-actions";
import { FeedbackTypes, FeedbackModel } from "./types";

export const sendFeedbackRequest = (feedback: FeedbackModel) => ({
    type: FeedbackTypes.FEEDBACK_REQUEST,
    feedback,
});

export const sendFeedbackSuccess = () => action(FeedbackTypes.FEEDBACK_SUCCESS);

export const sendFeedbackFailure = () => action(FeedbackTypes.FEEDBACK_FAILURE);
