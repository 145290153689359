import React, { Component } from "react"
import { FiMail } from "react-icons/fi"
import { connect } from "react-redux"
import { Dispatch } from "redux"

import Loading from "../../../featureComponents/loading/LoadingScreen"
import { recoverPasswordRequest } from "../../../store/ducks/user/actions"
import Alert from "../../../featureComponents/alert/Alert"
import Image from "../../../resources/img/bg_city.png"
import { ApplicationState } from "../../../store"
import { FaArrowLeft } from "react-icons/fa"
import { Link } from "react-router-dom"
import {
  Container,
  Header,
  Body,
  InputsContainer,
  Input,
  ButtonsContainer,
  Button,
  ErrorMessage,
} from "./styles"

import MobileRecoverScreen from './mobile/MobileRecoverScreen'

interface StateProps {
  sent: boolean
  loading: boolean
  error: boolean
}

interface DispatchProps {
  recoverPasswordRequest(email: string): void
}

interface OwnProps {
  history: any
}

type Props = StateProps & DispatchProps & OwnProps

class Recover extends Component<Props> {
  state = {
    email: "",
    showAlert: false,
  };

  render() {
    const { loading, sent, error } = this.props
    const { showAlert } = this.state

    return (
      <div style={{ backgroundColor: 'rgba(242, 242, 242)'}}>

        <Container>
          <Header>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                display: "flex",
              }}
            >
              <FaArrowLeft color="white" size={20} />
            </Link>
            <p>Recuperar Senha</p>
          </Header>
          <Body
            style={{ backgroundImage: `url(${Image})`, backgroundSize: "cover" }}
          >
            <span>
              Digite seu email e clique em 'Recuperar' para receber as instruções.
          </span>
            <InputsContainer>
              {error && (
                <ErrorMessage>
                  <span>Email não cadastrado.</span>
                </ErrorMessage>
              )}
              <Input>
                <FiMail size={30} color="gray" />
                <input
                  type="email"
                  placeholder="Email"
                  onChange={this.saveEmail.bind(this)}
                />
              </Input>
            </InputsContainer>
            <ButtonsContainer>
              <Button
                disabled={this.buttonDisabled()}
                onClick={() => this.recoverPassword()}
              >
                Recuperar
            </Button>
            </ButtonsContainer>
          </Body>

        </Container>

        <MobileRecoverScreen
          handleCloseClicked={() => {
            this.props.history.push("/")
          }}
          showError={error}
          onChangeEmail={this.saveEmail.bind(this)}
          buttonDisabled={this.buttonDisabled()}
          recoverPasswordClicked={() => this.recoverPassword()}
        />

        {loading && (
          <Loading
            height={window.innerHeight}
            title="Enviando"
            subtitle="Email"
          />
        )}
        {sent && showAlert && (
          <Alert
            height={window.innerHeight}
            title="Recuperar Senha!"
            message="Email enviado com sucesso."
            textButton="OK"
            onOKClicked={() => this.confirmAlert()}
          />
        )}
      </div>
    )
  }

  confirmAlert() {
    const { history } = this.props

    this.setState({ showAlert: false })
    history.goBack()
  }

  recoverPassword() {
    const { recoverPasswordRequest } = this.props
    const { email } = this.state

    this.setState({ showAlert: true })

    recoverPasswordRequest(email)
  }

  buttonDisabled() {
    const { email } = this.state

    if (email.trim().length > 0) {
      return false
    }

    return true
  }

  saveEmail(event: any) {
    this.setState({ email: event.target.value })
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  sent: state.user.sent,
  loading: state.user.loading,
  error: state.user.error,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    recoverPasswordRequest: (email: string) =>
      dispatch(recoverPasswordRequest(email)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recover)
