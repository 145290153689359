import { call, put } from "redux-saga/effects";

import api from "../../../services/api";
import { companiesSuccess, companiesFailure } from "./actions";

export function* loadCompanies() {
  try {
    const response = yield call(api.get, "svc/items");

    yield put(companiesSuccess(response.data));
  } catch (err) {
    yield put(companiesFailure());
  }
}
