import { LocationsState, LocationsTypes } from "./types";
import { Reducer } from "redux";

const INITIAL_STATE: LocationsState = {
  states: [],
  cities: [],
  loadingCities: false,
  loadingStates:false,
  error: false,
};

const reducer: Reducer<LocationsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LocationsTypes.STATES_REQUEST:
      return { ...state,loadingStates:true };
    case LocationsTypes.STATES_SUCCESS:
      return {
        ...state,
        error: false,
        states: action.payload.data,
        loadingStates:false,
      };
    case LocationsTypes.STATES_FAILURE:
      return { ...state, error: true, states: [],loadingStates:false };
    case LocationsTypes.CITIES_REQUEST:
      return { ...state,loadingCities:true };
    case LocationsTypes.CITIES_SUCCESS:
      return {
        ...state,
        error: false,
        cities: action.payload.data,
        loadingCities:false,
      };
    case LocationsTypes.CITIES_FAILURE:
      return { ...state, error: true, cities: [], loadingCities:false };
    default:
      return state;
  }
};

export default reducer;
