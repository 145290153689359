import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: fixed;
  z-index: 5;

  @media(max-width: 450px) {
    display: none;
  }
`;

export const Header = styled.div`
  height: 79px;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 20pt;
  padding-right: 20pt;
  padding-top: 5pt;
  padding-bottom: 5pt;
  z-index: 2;

  border-bottom: 2px solid rgb(0, 0, 0, 0.05);
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  p {
    font-size: 18pt;
    font-weight: bold;
    color: white;
    margin-left: 5pt;
  }

  @media(max-width: 868px) and (min-width: 450px){
    img {
     width: 120px;
    }
  } 
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    cursor: pointer;
    padding: 8px 14px;
    margin-left: 5px;
    text-align: center;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    color:#333;
    font-family:Roboto-Bold;
    &:hover {
      color: rgb(37,145,77);
    }
  }

  @media(max-width: 1040px){ 
    font-size: 9pt;
  } 

  @media(max-width: 898px){ 
    font-size: 8pt;
  } 
  @media(max-width: 768px){ 
    flex-direction: column;
    align-items: flex-end;
  } 
`;


export const NavigationFirstBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media(max-width: 768px){ 
    margin-bottom: 5px;
  } 
`;

export const NavigationSecondBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const UserIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  color: black;
  background-color: rgb(200, 200, 200);
  text-align: center;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 5px;
  padding-right: 5px;

  span {
    text-align: center;
    font-size: 22px;
    color: rgb(51, 51, 51);
    :hover {
      background-color: transparent;
    }
  }
`;

export const ServicesModal = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(0, 187, 55);
  position: fixed;
  z-index: 2;
  border-radius: 4pt;
  right: 31%;
  top: 5%;
  border: 1px solid rgba(0, 0, 0, 0.1);

  span {
    color: white;
    font-weight: 800;
    cursor: pointer;
    padding: 10pt;
    border-radius: 4pt;
    font-size: 10pt;

    :hover {
      background-color: rgba(255, 255, 255, 0.25);
    }
  }
`;