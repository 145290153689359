import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #ccc;
  box-shadow: -5px 0 15px -10px #333;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(247, 247, 247);
`

export const Body = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
`

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface VenuesSideProps {
  preventClicks?: boolean
}

export const VenuesSide = styled.div<VenuesSideProps>` 
  display: flex;
  max-width: 1026px;
  margin-top: 210px;
  pointer-events: ${props => props.preventClicks? 'none': 'auto'};
  
  @media(max-width: 450px){
     margin-top: 80px;
     width:100%;
  } 
`

export const VenuesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  column-gap: 30px;
  padding: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  
  @media(max-width: 490px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(1, 300px); 
  }
  @media(max-width: 1000px) and (min-width: 760px) {
    grid-template-columns: repeat(3, 1fr); 
  }
  @media(max-width: 760px) and (min-width: 490px)  {
    grid-template-columns: repeat(2, 1fr); 
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 260px;
  background-color: transparent;
  margin-top: 20px;
  cursor: pointer;

  #photo {
    height: 150px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 0px 16px rgba(0,0,0,0.15)
  }

  @media(max-width: 490px) {
    width: 90%;
  }
`

export const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
`

export const VenueName = styled.span`
  max-width: 320;
  height: 28px;
  font-size: 14pt;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(0,0,0,0.77);
`
export const VenuePin = styled.img`
  width: 28px; 
  height: 38px;
`

export const AccessInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
`

export const Address = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  padding: 10pt;
  border-top: 2px solid rgb(247, 247, 247);
  align-items: center;
  justify-content: left;
  margin-left: 10px;
  margin-right: 10px;

  span {
    color: #56b4f6;
    margin-left: 10pt;
  }
`


export const AddressLocation = styled.span`  
    height: 40px;
    color: #56b4f6;
    margin-left: 10pt; 
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; 
    font-size: 12pt;
`

export const VenueBadge = styled.div`
  min-width: 135px; 
  height: 10pt; 
  display: flex;
  justify-content: flex-end;
  color: white;
  padding: 4pt;
  font-size: 10pt;
  font-weight: bold;
  margin-left: -3pt;
  margin-top: 115pt;
  border-radius: 3pt;
  position: absolute;
`

export const VenueCertificationPin = styled.img`
  width: 42px; 
  height: 42px;
  margin-left: 10px;
  position: absolute;
`

export const VenueDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 0;
    font-size: 16pt;
  }

  h2 {
    margin: 0;
    margin-top: 2pt;
    font-size: 10pt;
    color: rgba(0, 0, 0, 0.4);
    font-weight: normal;
  }

  p {
    margin: 0;
    margin-top: 2pt;
    font-size: 10pt;
    color: rgba(0, 0, 0, 0.5);
    font-weight: normal;
  }
`

export const SearchContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 128px;
  margin-top: 90px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  @media(max-width: 450px) {
    display: none;
  }

`

export const SearchContainerItems = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 35px;
  width: 1020px;
  justify-content: space-between;

  @media(max-width: 630px) {
    width: 400px;
  }
  @media(max-width: 960px) and (min-width: 760px) {
    width: 700px;
  }
  @media(max-width: 760px) and (min-width: 630px) {
    width: 580px;
  }

`
export const SearchLabel = styled.span`
  display: flex;
  align-items: center;
  line-height: 1.2;

  /*&::before {
    content: '◀';
    margin: 0 10px;
  }*/

  color:#303030;
  font-size:14px;
`;

export const SearchLocale = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 560px;
  flex:1;
  span {
    font-weight: 300;
  }
  @media(max-width: 700px) {
    height: 85px;
    font-size: 10pt;
  }
`

export const LocaleInput = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 8pt;
  padding-right: 8pt;
  padding-top: 5pt;
  padding-bottom: 5pt;
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4pt;
  margin-top: 5pt;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 44px;
  box-sizing:border-box;

  input {
    background-color: transparent;
    border: 0px none;
    margin-left: 5pt;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    font-size: 12pt;
  }

  @media(max-width: 700px) {
    input {
      font-size: 10pt;
    }
  }

  @media(max-width: 630px) and (min-width: 500px) {
    input {
      font-size: 7pt;
    }
  }
`

export const SearchTypeSeparator  = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:flex-end;
  width:55px;
  margin-bottom: 12px;
`

export const CitySelector = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 180px;
  margin-left: 0;
  margin-right: 16px;
  span {
    font-weight: 300;
  }
  .typesDropdown {
    margin-left: 80px;
  }
  @media(max-width: 630px) {
    height: 85px;
    font-size: 10pt;
    .typesDropdown {
      margin-left: -35px;
    }
  }

  @media(max-width: 700px) and (min-width: 630px) {
    height: 70px;
    font-size: 10pt;
    .typesDropdown {
      margin-left: 5px;
    }
  }
 
  @media(max-width: 760px) and (min-width: 630px) {
    .typesDropdown {
      margin-left: 5px;
    }
  }
  @media(max-width: 960px) and (min-width: 760px) {
    .typesDropdown {
    margin-left: 15px;
  }
  }
`

export const StateSelector = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 88px;
  margin-left: 0;
  margin-right: 16px;
  box-sizing:border-box;

  span {
    font-weight: 300;
  }
  .typesDropdown {
    margin-left: 80px;
  }
  @media(max-width: 630px) {
    height: 85px;
    font-size: 10pt;
    .typesDropdown {
      margin-left: -35px;
    }
  }

  @media(max-width: 700px) and (min-width: 630px) {
    height: 70px;
    font-size: 10pt;
    .typesDropdown {
      margin-left: 5px;
    }
  }
 
  @media(max-width: 760px) and (min-width: 630px) {
    .typesDropdown {
      margin-left: 5px;
    }
  }
  @media(max-width: 960px) and (min-width: 760px) {
    .typesDropdown {
    margin-left: 15px;
  }
  }
`


export const LocaleTypes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 168px;
  margin-left: 0;
  margin-right: 16px;
  span {
    font-weight: 300;
  }
  .typesDropdown {
    margin-left: 80px;
  }
  @media(max-width: 630px) {
    height: 85px;
    font-size: 10pt;
    .typesDropdown {
      margin-left: -35px;
    }
  }

  @media(max-width: 700px) and (min-width: 630px) {
    height: 70px;
    font-size: 10pt;
    .typesDropdown {
      margin-left: 5px;
    }
  }
 
  @media(max-width: 760px) and (min-width: 630px) {
    .typesDropdown {
      margin-left: 5px;
    }
  }
  @media(max-width: 960px) and (min-width: 760px) {
    .typesDropdown {
    margin-left: 15px;
  }
  }

`

export const AccessibilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 96px;
  cursor: pointer;

  span {
    font-weight: 300;
  }

  .accessibilityDropdown {
    margin-left: 0px;
  }

  @media(max-width: 630px) {
    height: 85px;
    font-size: 10pt;
    #label-acessibilidade {
      font-size: 10pt;
      margin-bottom: 15px;
    }
    .accessibilityDropdown {
      margin-left: -35px;
    }
  }
  @media(max-width: 700px) and (min-width: 630px) {
    height: 70px;
    font-size: 10pt;
    .accessibilityDropdown {
      margin-left: -30px;
    }
  } 

  @media(max-width: 1000px) and (min-width: 700px){
    .accessibilityDropdown {
      margin-left: -30px;
    }
  } 
`

export const DropdownButton = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  padding: 8pt;
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4pt;
  margin-top: 5pt;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 44px;
  box-sizing:border-box;
  
  span {
    color: rgba(0, 0, 0, 0.5);
  }
`
export const DropDownStyle = {
  option: (provided:any, state:any) => {
    const { isDisabled, isFocused, isSelected } = state;
    return {
      ...provided,
      borderBottom: '1px dotted #C8C8C8',
      color: '#303030',
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? 'rgb(200, 200, 200)'
        : isFocused
        ? 'rgb(200, 200, 200)'
        : 'rgb(245, 245, 245)',
      padding: 8,
      height:40,
      display:'flex',
      alignItems:'center',
      ':active': {
        backgroundColor:
        !isDisabled && (isSelected ? 'rgb(200,200,200)' : 'rgb(225,225,225)'),
    }
  }
  },
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'flex',
    width: '100%',
    backgroundColor: 'rgb(247, 247, 247)',
    border: '1px solid rgb(200, 200, 200)',
    borderRadius: '4pt',
    marginTop: '5pt',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    height: '42px',
  }),
  menu: (provided:any, state:any) => ({
    ...provided,
    width: state.selectProps.width || '100%',
  }),
  singleValue: (provided:any, state:any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition};
  }
}

export const DropDownContainer = styled.div`
  display: flex;
  max-width: 180px;
  flex-direction: column;
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4pt;
  position: fixed;
  z-index: 2;
  margin-top: 10px;

  span {
    padding: 10pt;
    cursor: pointer;

    :hover {
      background-color: rgb(225, 225, 225);
    }
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(200, 200, 200);
    padding-left: 10pt;

    :hover {
      background-color: rgb(225, 225, 225);
    }
  }
  .todos-dropdown,
  .todos-dropdown {
    background-color: rgb(225, 225, 225);
  }
`

export const RateButton = styled.button`
  width: 150px;
  height: 50px;
  padding: 10pt;
  padding-left: 20pt;
  padding-right: 20pt;
  color: white;
  border: 0px none;
  background-color: rgb(217, 3, 104);
  font-size: 11pt;
  border-radius: 4pt;
  cursor: pointer;
  margin-right: 10px;
`


export const DetailsButton = styled.button`
  width: 150px;
  height: 50px;
  padding: 10pt;
  padding-left: 20pt;
  padding-right: 20pt;
  color: white;
  border: 0px none;
  background-color: rgb(74, 144, 226);
  font-size: 11pt; 
  border-radius: 4pt;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const BookCircleButton = styled.div`
  position: absolute;
  width: 54px;
  height: 54px;
  color: white;
  background-color: rgb(0, 53, 128);
  border-radius: 50%;
  margin-left: 17%;
  margin-top: 171px;
  padding: 2px;

  font-size: 9pt;
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
`

interface DrawerMenuProps {
  showDrawerMenu?: boolean
}

export const DrawerMenuContainer = styled.div<DrawerMenuProps>`
  transition: opacity 1s;
  opacity: ${props => props.showDrawerMenu ? 1 : 0};
  width: ${props => props.showDrawerMenu ? '300px' : 0};
  background-color: rgb(255, 255, 255);

  @media(max-width: 430px) and (min-width: 380px) {
    width: ${props => props.showDrawerMenu ? '270px' : 0};
  }
`

export const MainContainer = styled.div<DrawerMenuProps>`
  transition: left 1s;
  position: ${props => props.showDrawerMenu ? 'fixed' : 'relative'};
  width: ${props => props.showDrawerMenu ? '300px' : '100%'};
  left: ${props => props.showDrawerMenu ? '300px' : '0px'};

  @media(max-width: 380px) and (min-width: 320px) {
    left: ${props => props.showDrawerMenu ? '230px' : '0px'};
  }
  @media(max-width: 430px) and (min-width: 380px) {
    left: ${props => props.showDrawerMenu ? '270px' : '0px'};
  }
`
