import { GoTriangleDown } from "react-icons/go";
import { FaArrowLeft } from "react-icons/fa";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { sendServiceRequest } from "../../../store/ducks/service/actions";
import Loading from "../../../featureComponents/loading/LoadingScreen";
import { Service } from "../../../store/ducks/service/types";
import Alert from "../../../featureComponents/alert/Alert";
import { ApplicationState } from "../../../store";
import { Link } from "react-router-dom";
import {
  Container,
  HeaderContainer,
  Header,
  Body,
  InputContainer,
  NameOnBorder,
  InputDropdown,
  Dropdown,
  DropdownContent,
  Button,
} from "./styles";

const categories: Array<string> = [
  "Adaptação Veicular",
  "Cadeira Anfíbia",
  "Centro de Reabilitação",
  "Cuidador",
  "Loja de Cadeira de Rodas",
  "Manutenção",
  "Produtos Médicos Hospitalares",
  "Táxi/Van Acessível",
];

const states: Array<string> = [
  "AC",
  "AL",
  "AM",
  "AP",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MG",
  "MS",
  "MT",
  "PA",
  "PB",
  "PE",
  "PI",
  "PR",
  "RJ",
  "RN",
  "RO",
  "RR",
  "RS",
  "SC",
  "SE",
  "SP",
  "TO",
];

interface StateProps {
  loading: boolean;
  serviceSent: boolean;
}

interface DispatchProps {
  sendServiceRequest(service: Service, token: string | null): void;
}

interface OwnProps {
  history: any;
  location: any;
}

type Props = StateProps & DispatchProps & OwnProps;

class ServiceRegister extends Component<Props> {
  state = {
    showDropdownCategory: false,
    showDropdownState: false,
    name: "",
    description: "",
    area: "",
    category: "",
    state: "",
    city: "",
    address: "",
    successAlert: false,
  };

  render() {
    const { category, state, successAlert } = this.state;
    const { loading, location, serviceSent } = this.props;
    const height = document.getElementById("Service")?.offsetHeight;

    if (!loading && location.state.service) {
      this.sendService();
    }

    return (
      <Container id="Service">
        <HeaderContainer>
          <Header>
            <Link
              to="/catalog"
              style={{
                textDecoration: "none",
                display: "flex",
              }}
            >
              <FaArrowLeft color="white" size={20} />
            </Link>
            <p>Registro de Serviços</p>
          </Header>
        </HeaderContainer>
        <Body>
          <InputContainer width={40}>
            <NameOnBorder>
              <span>Nome da empresa</span>
            </NameOnBorder>
            <input type="text" onChange={this.saveName.bind(this)} />
          </InputContainer>
          <InputContainer width={138}>
            <textarea
              placeholder="Descrição"
              onChange={this.saveDescription.bind(this)}
            />
          </InputContainer>
          <InputContainer width={40}>
            <input
              type="text"
              placeholder="Área de atuação"
              onChange={this.saveArea.bind(this)}
            />
          </InputContainer>
          <InputContainer
            width={40}
            cursor="pointer"
            onClick={() => this.showDropdownCategory()}
          >
            <InputDropdown
              color={
                category.trim().length > 0 ? "black" : "rgb(155, 155, 155)"
              }
            >
              <span>{category.trim().length > 0 ? category : "Categoria"}</span>
              <GoTriangleDown color={"rgb(147,147,147)"} size={20} />
            </InputDropdown>
          </InputContainer>
          {this.renderDropdown("category")}
          <InputContainer
            width={40}
            cursor="pointer"
            onClick={() => this.showDropdownState()}
          >
            <InputDropdown
              color={state.trim().length > 0 ? "black" : "rgb(155, 155, 155)"}
            >
              <span>{state.trim().length > 0 ? state : "Estado"}</span>
              <GoTriangleDown color={"rgb(147,147,147)"} size={20} />
            </InputDropdown>
          </InputContainer>
          {this.renderDropdown("state")}
          <InputContainer width={40}>
            <input
              type="text"
              placeholder="Cidade"
              onChange={this.saveCity.bind(this)}
            />
          </InputContainer>
          <InputContainer width={40}>
            <input
              type="text"
              placeholder="Endereço"
              onChange={this.saveAddress.bind(this)}
            />
          </InputContainer>
          <Button
            disabled={this.buttonDisabled()}
            onClick={() => this.sendService()}
          >
            Enviar
          </Button>
        </Body>
        {loading && (
          <Loading height={height} title="Enviando" subtitle="Serviço" />
        )}
        {serviceSent && successAlert && (
          <Alert
            onOKClicked={() => this.alertConfirm()}
            height={height}
            title="Sucesso!"
            message="Seu serviço foi registrado com sucesso."
            textButton="OK"
          />
        )}
      </Container>
    );
  }

  alertConfirm() {
    const { history } = this.props;

    this.setState({ successAlert: false });
    history.push("/catalog");
  }
  
  sendService() {
    const token = localStorage.getItem("AUTH_DATA");
    const { sendServiceRequest, history, location } = this.props;

    if (location.state.service) {
      this.setState({ successAlert: true });
      sendServiceRequest(location.state.service, token);
    } else {
      const {
        name,
        description,
        area,
        category,
        state,
        city,
        address,
      } = this.state;

      const service: Service = {
        name,
        state,
        city,
      };

      if (description.trim().length > 0) {
        service.description = description;
      }

      if (area.trim().length > 0) {
        service.area = area;
      }

      if (category.trim().length > 0) {
        service.category = category;
      }

      if (address.trim().length > 0) {
        service.address = address;
      }

      if (token) {
        this.setState({ successAlert: true });
        sendServiceRequest(service, token);
      } else {
        history.push("/login", { screen: "service-register", service });
      }
    }
  }

  buttonDisabled(): boolean {
    const { name, state, city } = this.state;

    if (
      name.trim().length > 0 &&
      state.trim().length > 0 &&
      city.trim().length > 0
    ) {
      return false;
    }

    return true;
  }

  saveName(event: any) {
    this.setState({ name: event.target.value });
  }

  saveDescription(event: any) {
    this.setState({ description: event.target.value });
  }

  saveArea(event: any) {
    this.setState({ area: event.target.value });
  }

  saveCategory(category: string) {
    this.setState({ category, showDropdownCategory: false });
  }

  saveState(state: string) {
    this.setState({ state, showDropdownState: false });
  }

  saveCity(event: any) {
    this.setState({ city: event.target.value });
  }

  saveAddress(event: any) {
    this.setState({ address: event.target.value });
  }

  showDropdownCategory() {
    const { showDropdownCategory } = this.state;

    this.setState({ showDropdownCategory: !showDropdownCategory });
  }

  showDropdownState() {
    const { showDropdownState } = this.state;

    this.setState({ showDropdownState: !showDropdownState });
  }

  renderDropdown(whichDropdown: string): JSX.Element | undefined {
    const { showDropdownCategory, showDropdownState } = this.state;

    if (whichDropdown === "category" && showDropdownCategory) {
      return (
        <Dropdown>
          <DropdownContent>
            {categories.map((category: string, index: number) => {
              return (
                <li onClick={() => this.saveCategory(category)} key={index}>
                  {category}
                </li>
              );
            })}
          </DropdownContent>
        </Dropdown>
      );
    }

    if (whichDropdown === "state" && showDropdownState) {
      return (
        <Dropdown>
          <DropdownContent>
            {states.map((state: string, index: number) => {
              return (
                <li onClick={() => this.saveState(state)} key={index}>
                  {state}
                </li>
              );
            })}
          </DropdownContent>
        </Dropdown>
      );
    }
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  serviceSent: state.service.serviceSent,
  loading: state.service.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    sendServiceRequest: (service: Service, token: string | null) =>
      dispatch(sendServiceRequest(service, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRegister);
