import { call, put } from "redux-saga/effects";

import api from "../../../services/api";
import {
  questionsSuccess,
  questionsFailure,
  reviewSuccess,
  reviewFailure,
} from "./actions";

export function* questionsRequest(action: any) {
  try {
    const params =
      "?locale=pt-br&category_id=" +
      action.categoryId +
      "&venue_id=" +
      action.venueId;

    const token = localStorage.getItem("AUTH_DATA");
    const header = {
      Authorization: "",
    };

    if (token) {
      header.Authorization = "Bearer " + token;
    }

    const response = yield call(api.get, "questions" + params, {
      headers: header,
    });

    yield put(questionsSuccess(response.data));
  } catch (err) {
    yield put(questionsFailure());
  }
}

export function* reviewRequest(action: any) {
  try {
    const params = "?locale=pt-br";
    const payload = {
      reviews: action.answers,
      venue_id: action.venueId,
    };

    const token = localStorage.getItem("AUTH_DATA");
    const header = {
      Authorization: "",
    };

    if (token) {
      header.Authorization = "Bearer " + token;
    }

    yield call(api.post, "reviews" + params, payload, {
      headers: header,
    });

    yield put(reviewSuccess());
  } catch (err) {
    yield put(reviewFailure());
  }
}
