import styled from "styled-components";

interface Props {
  width?: number;
  cursor?: string;
  color?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: fixed;
`;

export const Header = styled.div`
  flex: 1;
  height: 20%;
  background-color: rgb(0, 187, 55);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 12pt;
  padding-right: 12pt;

  p {
    margin-left: 20pt;
    font-size: 18pt;
    font-weight: bold;
    color: white;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60pt;
  padding-left: 12pt;
  padding-right: 12pt;
  padding-bottom: 12pt;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${(props: Props) => `${props.width}pt`};
  border: 1px solid rgb(190, 190, 190);
  border-radius: 5pt;
  padding-left: 16pt;
  padding-right: 16pt;
  margin-top: 21pt;
  justify-content: center;
  cursor: ${(props: Props) => (props.cursor ? props.cursor : "normal")};

  span {
    margin-bottom: 8px;
    margin-top: 8px;
    font-size: 15pt;
    color: rgb(155, 155, 155);
    font-family: Helvetica;
  }

  input {
    border: 0px none;
    margin-bottom: 8px;
    margin-top: 8px;
    font-size: 15pt;

    ::placeholder {
      color: rgb(155, 155, 155);
      font-size: 15pt;
      font-family: Helvetica;
    }
  }

  textarea {
    border: 0px none;
    margin-top: 8px;
    font-size: 15pt;
    height: 100%;
    resize: none;

    ::placeholder {
      color: rgb(155, 155, 155);
      font-size: 15pt;
      font-family: Helvetica;
    }
  }
`;

export const NameOnBorder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
  width: 100pt;
  background-color: white;

  span {
    font-size: 11pt;
    color: rgb(155, 155, 155);
  }
`;

export const InputDropdown = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    color: ${(props: Props) => props.color};
  }
`;

export const Dropdown = styled.div`
  display: flex;
  border: 1px solid rgb(190, 190, 190);
  border-bottom-left-radius: 5pt;
  border-bottom-right-radius: 5pt;
  margin-top: -5px;
  background-color: white;
`;

export const DropdownContent = styled.ul`
  list-style-type: none;
  flex: 1;
  max-height: 200pt;
  overflow: auto;

  li {
    color: rgba(0, 0, 0, 0.35);
    margin-bottom: 7pt;
    cursor: pointer;
  }
`;

export const Button = styled.button`
  height: 40pt;
  background-color: rgb(214, 8, 107);
  margin-top: 21pt;
  border-radius: 5pt;
  border: 0px none;
  font-size: 15pt;
  color: white;
  font-family: Helvetica;
  cursor: pointer;

  :disabled {
    background-color: rgba(214, 8, 107, 0.4);
  }
`;
