import React from "react"
import { FiX, FiArrowLeft } from "react-icons/fi"
import { GoTriangleDown } from "react-icons/go"
import {
  Container,
  HeaderContainer,
  SendButton,
  DateGenderContainer,
  Input,
  EditProfileContainer,
  EditProfileModal,
  DropdownButton,
  ContainerAlert,
  Alert,
  Title,
  Message,
  Button
} from "./styles"

interface AlertContainerOwnProps {
  title: String
  message: String
  handleOK(): void
}

const AlertContainer = (props: AlertContainerOwnProps) => {
  const { message, title } = props
  return (
    <ContainerAlert >
      <Alert>
        <Title>{title}</Title>
        <Message>{message}</Message>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={() => props.handleOK()}>OK</Button>
        </div>
      </Alert>
    </ContainerAlert>)
}

export default ({
  name,
  lastName,
  birthday,
  gender,
  wheelchair,
  otherDisability,
  hasFriend,
  birthdayError,
  showSuccessAlert,
  showGenericError,
  renderGenderDropDown,
  renderHasFriendDropDown,
  renderWheelChairDropDown,
  renderOtherDisabilityDropDown,
  handleNameChanged,
  handleLastNameChanged,
  handleBirthdayChanged,
  handleBackClicked,
  handleCloseClicked,
  handleSaveClicked,
  handleGenderDropdownClicked,
  handleWheelChairDropDownClicked,
  handleOtherDisabilityDropDownClicked,
  handleHasFriendDropDownClicked,
  handleCloseGenericError
}: any) => {

  return (
    <Container>
      <HeaderContainer>
        <div
          style={{
            marginLeft: 10,
            display: "flex", flexDirection: "row",
            alignItems: 'center', justifyContent: 'center'
          }}
          onClick={handleBackClicked}>
          <FiArrowLeft
            style={{ cursor: "pointer", marginRight: 10 }}
            size={25}
            color={"rgb(48,48,48)"} />
          <span>Editar Perfil</span>
        </div>

        <FiX
          style={{ cursor: "pointer", position: 'fixed', right: 15, top: 15 }}
          size={25}
          color={"rgb(48,48,48)"}
          onClick={handleCloseClicked} />
      </HeaderContainer>
      <EditProfileModal>
        <EditProfileContainer>
          <span>Nome</span>
          <Input
            type="text"
            placeholder={name}
            value={name}
            onChange={handleNameChanged}
          />
          <span style={{ marginTop: 17 }}>Sobrenome</span>
          <Input
            type="text"
            placeholder={lastName}
            value={lastName}
            onChange={handleLastNameChanged}
          />

          {birthdayError && <span
            style={{ fontSize: 16, color: 'red', marginTop: 5, marginBottom: -10 }}>
            Data inválida!</span>}
          <DateGenderContainer>
            <div  id="input-date" style={{ flex: 1, marginRight: 5 }}>
              <span style={{ marginTop: 17 }}>Data de Nascimento</span>
              <Input
                type="date"
                style={{ height: 25, fontSize: 12 }}
                value={birthday}
                onChange={handleBirthdayChanged}
              />
            </div>
            <div style={{ flex: 1, marginLeft: 5 }}>
              <span style={{ marginTop: 17 }} >Gênero</span>
              <DropdownButton
                style={{ paddingTop: "11pt", paddingBottom: "11pt" }}
                onClick={handleGenderDropdownClicked} >
                <span>{gender}</span>
                <GoTriangleDown color={"rgb(147,147,147)"} />
              </DropdownButton>
              {renderGenderDropDown}
            </div>
          </DateGenderContainer>
          <span style={{ marginTop: 17 }} >Usa cadeira de rodas?</span>
          <DropdownButton
            style={{ paddingTop: "11pt", paddingBottom: "11pt" }}
            onClick={handleWheelChairDropDownClicked}
          >
            <span>{wheelchair}</span>
            <GoTriangleDown color={"rgb(147,147,147)"} />
          </DropdownButton>
          {renderWheelChairDropDown}

          <span style={{ marginTop: 17 }} >Tem outra deficiência?</span>
          <DropdownButton
            style={{ paddingTop: "11pt", paddingBottom: "11pt" }}
            onClick={handleOtherDisabilityDropDownClicked}
          >
            <span>{otherDisability}</span>
            <GoTriangleDown color={"rgb(147,147,147)"} />
          </DropdownButton>
          {renderOtherDisabilityDropDown}
          <span style={{ marginTop: 17 }} >Tem amigo(s) com deficiência?</span>
          <DropdownButton
            style={{ paddingTop: "11pt", paddingBottom: "11pt" }}
            onClick={handleHasFriendDropDownClicked}
          >
            <span>{hasFriend}</span>
            <GoTriangleDown color={"rgb(147,147,147)"} />
          </DropdownButton>
          {renderHasFriendDropDown}

          <SendButton onClick={handleSaveClicked}>
            <span>Salvar</span>
          </SendButton>

          {showGenericError && (
            <span
              style={{ fontSize: 16, color: 'red', marginTop: 10 }}>
              Algo deu errado. Tente novamente.</span>
          )}
        </EditProfileContainer>
      </EditProfileModal >
      {showSuccessAlert && (
        <AlertContainer
          title="Sucesso!"
          message="Alterações editadas com sucesso."
          handleOK={handleBackClicked}
        />
      )}

      {showGenericError && (
        <AlertContainer
          title="Oppps!"
          message="Algo deu errado. Tente novamente."
          handleOK={handleCloseGenericError}
        />
      )}
    </Container>)
}
