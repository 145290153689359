import { GoTriangleDown, GoSearch } from "react-icons/go";
import React, { Component } from "react";
import { GrClose } from "react-icons/gr";

import { Company } from "../../../store/ducks/companies/types";
import {
  Container,
  State,
  City,
  Category,
  FilterDiv,
  Modal,
  HeaderContainer,
  Header,
  Search,
  List,
  ModalContainer,
} from "./styles";

interface OwnProps {
  companies: Company[];
  searchState(state: String): void;
  searchCity(city: String): void;
  searchCategory(category: String): void;
}

type Props = OwnProps;

export default class CatalogFilter extends Component<Props> {
  componentDidMount() {
    window.addEventListener("resize", () => this.setHeight());
  }

  state = {
    shouldShowModal: false,
    modalHeader: "",
    state: "UF",
    city: "CIDADE",
    category: "CATEGORIA",
    text: "",
    height: (window.innerHeight / 100) * 30,
  };

  setHeight() {
    let height = 0;

    if (window.innerHeight > 510) {
      height = (window.innerHeight / 100) * 30;
    } else {
      height = (window.innerHeight / 100) * 20;
    }
    
    this.setState({ height })
  }

  render() {
    const { state, city, category } = this.state;

    return (
      <Container>
        <FilterDiv>
          <State
            state={state}
            onClick={() =>
              this.setState({ shouldShowModal: true, modalHeader: "ESTADO" })
            }
          >
            <span>{state}</span>
            <GoTriangleDown
              color={state === "UF" ? "rgb(147,147,147)" : "white"}
            />
          </State>
          <City
            city={city}
            onClick={() => {
              if (state !== "UF") {
                this.setState({
                  shouldShowModal: true,
                  modalHeader: "CIDADE",
                });
              }
            }}
          >
            <span>{city}</span>
            <GoTriangleDown
              color={city === "CIDADE" ? "rgb(147,147,147)" : "white"}
            />
          </City>
        </FilterDiv>
        <Category
          category={category}
          onClick={() => {
            if (state !== "UF") {
              this.setState({
                shouldShowModal: true,
                modalHeader: "CATEGORIA",
              });
            }
          }}
        >
          <span>{category}</span>
          <GoTriangleDown
            color={category === "CATEGORIA" ? "rgb(147,147,147)" : "white"}
          />
        </Category>
        {this.renderModal()}
      </Container>
    );
  }

  renderModal(): JSX.Element {
    const { shouldShowModal, modalHeader, height } = this.state;

    if (shouldShowModal) {
      return (
        <ModalContainer>
          <Modal>
            <HeaderContainer>
              <Header>
                <span>{modalHeader}</span>
                <GrClose
                  size={24}
                  onClick={() =>
                    this.setState({ shouldShowModal: !shouldShowModal })
                  }
                />
              </Header>
              <Search>
                <GoSearch size={"17pt"} color={"rgba(0,0,0,0.35)"} />
                <input
                  id="search"
                  type="text"
                  placeholder={"Procurar"}
                  color={"rgba(0,0,0,0.35)"}
                  onChange={this.handleChange.bind(this)}
                />
              </Search>
            </HeaderContainer>
            <List height={height}>{this.renderContentModal()}</List>
          </Modal>
        </ModalContainer>
      );
    } else {
      return <div />;
    }
  }

  handleChange(event: any) {
    this.setState({ text: event.target.value });
  }

  renderContentModal(): JSX.Element | undefined {
    const { companies } = this.props;
    const { modalHeader, state, city, category, text } = this.state;

    switch (modalHeader) {
      case "ESTADO":
        const states: String[] = [];
        companies.forEach((company) => {
          if (!states.includes(company.state)) {
            states.push(company.state);
          }
        });
        states.sort();

        if (text.trim().length > 0) {
          return (
            <div>
              <span
                key={-1}
                onClick={() => {
                  this.props.searchState("todos");
                  this.setState({
                    state: "UF",
                    city: "CIDADE",
                    category: "CATEGORIA",
                    shouldShowModal: false,
                    text: "",
                  });
                }}
              >
                TODOS
              </span>
              {states.map((state, index) => {
                if (state.includes(text.toUpperCase())) {
                  return (
                    <span
                      key={index}
                      onClick={() => {
                        this.props.searchState(state);
                        this.setState({
                          state,
                          city: "CIDADE",
                          category: "CATEGORIA",
                          shouldShowModal: false,
                          text: "",
                        });
                      }}
                    >
                      {state}
                    </span>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          );
        } else {
          return (
            <div>
              <span
                key={-1}
                onClick={() => {
                  this.props.searchState("todos");
                  this.setState({
                    state: "UF",
                    city: "CIDADE",
                    category: "CATEGORIA",
                    shouldShowModal: false,
                    text: "",
                  });
                }}
              >
                TODOS
              </span>
              {states.map((state, index) => {
                return (
                  <span
                    key={index}
                    onClick={() => {
                      this.props.searchState(state);
                      this.setState({
                        state,
                        city: "CIDADE",
                        category: "CATEGORIA",
                        shouldShowModal: false,
                        text: "",
                      });
                    }}
                  >
                    {state}
                  </span>
                );
              })}
            </div>
          );
        }
      case "CIDADE":
        const cities: String[] = [];
        companies.forEach((company) => {
          if (category !== "CATEGORIA") {
            if (
              !cities.includes(company.city) &&
              company.state === state &&
              company.category_name === category
            ) {
              cities.push(company.city);
            }
          } else {
            if (!cities.includes(company.city) && company.state === state) {
              cities.push(company.city);
            }
          }
        });
        cities.sort();

        if (text.trim().length > 0) {
          return (
            <div>
              <span
                key={-1}
                onClick={() => {
                  this.props.searchCity("todos");
                  this.setState({
                    city: "CIDADE",
                    shouldShowModal: false,
                    text: "",
                  });
                }}
              >
                TODOS
              </span>
              {cities.map((city, index) => {
                if (city.toLowerCase().includes(text.toLowerCase())) {
                  return (
                    <span
                      key={index}
                      onClick={() => {
                        this.props.searchCity(city);
                        this.setState({
                          city,
                          shouldShowModal: false,
                          text: "",
                        });
                      }}
                    >
                      {city}
                    </span>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          );
        } else {
          return (
            <div>
              <span
                key={-1}
                onClick={() => {
                  this.props.searchCity("todos");
                  this.setState({
                    city: "CIDADE",
                    shouldShowModal: false,
                    text: "",
                  });
                }}
              >
                TODOS
              </span>
              {cities.map((city, index) => {
                return (
                  <span
                    key={index}
                    onClick={() => {
                      this.props.searchCity(city);
                      this.setState({ city, shouldShowModal: false, text: "" });
                    }}
                  >
                    {city}
                  </span>
                );
              })}
            </div>
          );
        }
      case "CATEGORIA":
        const categories: String[] = [];
        companies.forEach((company) => {
          if (city !== "CIDADE") {
            if (
              !categories.includes(company.category_name) &&
              company.state === state &&
              company.city === city
            ) {
              categories.push(company.category_name);
            }
          } else {
            if (
              !categories.includes(company.category_name) &&
              company.state === state
            ) {
              categories.push(company.category_name);
            }
          }
        });
        categories.sort();

        if (text.trim().length > 0) {
          return (
            <div>
              <span
                key={-1}
                onClick={() => {
                  this.props.searchCategory("todos");
                  this.setState({
                    category: "CATEGORIA",
                    shouldShowModal: false,
                    text: "",
                  });
                }}
              >
                TODOS
              </span>
              {categories.map((category_name, index) => {
                if (category_name.toLowerCase().includes(text.toLowerCase())) {
                  return (
                    <span
                      key={index}
                      onClick={() => {
                        this.props.searchCategory(category_name);
                        this.setState({
                          category: category_name,
                          shouldShowModal: false,
                          text: "",
                        });
                      }}
                    >
                      {category_name}
                    </span>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          );
        } else {
          return (
            <div>
              <span
                key={-1}
                onClick={() => {
                  this.props.searchCategory("todos");
                  this.setState({
                    category: "CATEGORIA",
                    shouldShowModal: false,
                    text: "",
                  });
                }}
              >
                TODOS
              </span>
              {categories.map((category_name, index) => {
                return (
                  <span
                    key={index}
                    onClick={() => {
                      this.props.searchCategory(category_name);
                      this.setState({
                        category: category_name,
                        shouldShowModal: false,
                        text: "",
                      });
                    }}
                  >
                    {category_name}
                  </span>
                );
              })}
            </div>
          );
        }
    }
  }
}
