import React from "react"
import { FiX } from "react-icons/fi"
import bgImage from "../../../resources/img/venue_placeholder.png"
import { FiMail, FiUnlock, FiUser } from "react-icons/fi"
import {
  Container,
  InputsContainer,
  Input,
  ButtonsContainer,
  Button,
  SignUpContainer,
  HeaderContainer
} from "./styles"

interface OwnProps {
  buttonDisabled: boolean
  handleCloseClicked(): void
  handleSignUpClicked(): void
  onNameChanged(event: any): void
  onLastNameChanged(event: any): void
  onEmailChanged(event: any): void
  onPasswordChanged(event: any): void
  onTermsAndPrivacyChanged(event: any): void
}

export default ({
  buttonDisabled,
  handleCloseClicked,
  handleSignUpClicked,
  onNameChanged,
  onLastNameChanged,
  onEmailChanged,
  onPasswordChanged,
  onTermsAndPrivacyChanged
}: OwnProps) => {

  return (
    <Container>
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          borderRadius: "4px",
        }}
      >
        <SignUpContainer>
          <HeaderContainer>
            <FiX
              style={{ cursor: "pointer", position: 'fixed', right: 10, top: 10 }}
              size={25}
              color={"rgb(48,48,48)"}
              onClick={handleCloseClicked} />
          </HeaderContainer>
          <span style={{
            width: '80%',
            padding: 10,
            fontSize: 20,
            fontWeight: 500,
          }}>Preencha suas informações abaixo.</span>
          <InputsContainer>
            <Input>
              <FiUser size={30} color="gray" />
              <input
                type="text"
                placeholder="Nome"
                onChange={onNameChanged}
              />
            </Input>
            <Input>
              <FiUser size={30} color="gray" />
              <input
                type="text"
                placeholder="Sobrenome"
                onChange={onLastNameChanged}
              />
            </Input>
            <Input>
              <FiMail size={30} color="gray" />
              <input
                type="email"
                placeholder="Email"
                onChange={onEmailChanged}
              />
            </Input>
            <Input>
              <FiUnlock size={30} color="gray" />
              <input
                type="password"
                placeholder="Senha"
                onChange={onPasswordChanged}
              />
            </Input>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 30
            }}>
              <input
                type="checkbox"
                name="terms"
                onChange={onTermsAndPrivacyChanged}></input>
              <span style={{
                marginLeft: 10,
                fontSize: 18,
                fontWeight: 800,
              }}>Li e concordo com os termos da politica de&nbsp;</span>
              <a href="#/"
                onClick={() => {
                  window.open('https://guiaderodas.com/politica-de-privacidade')
                }} >Privacidade Guiaderodas</a><br />
            </div>
          </InputsContainer>
          <ButtonsContainer>
            <Button
              disabled={buttonDisabled}
              onClick={handleSignUpClicked}
            >
              Cadastrar-se
            </Button>
          </ButtonsContainer>
        </SignUpContainer>
      </div>
    </Container>
  )
}
