import { FiMail, FiUnlock, FiUser } from "react-icons/fi"
import { FaArrowLeft } from "react-icons/fa"
import React, { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { Dispatch } from "redux"

import Alert from "../../../featureComponents/alert/Alert"
import Loading from "../../../featureComponents/loading/LoadingScreen"
import { signUpRequest } from "../../../store/ducks/user/actions"
import { User } from "../../../store/ducks/user/types"
import Image from "../../../resources/img/bg_city.png"
import { ApplicationState } from "../../../store"
import {
  Container,
  Header,
  Body,
  InputsContainer,
  Input,
  ButtonsContainer,
  Button,
  ErrorMessage
} from "./styles"

interface StateProps {
  registered: boolean
  loading: boolean
  error: boolean
}

interface OwnProps {
  history: any
  location: any
}

interface DispatchProps {
  signUpRequest(user: User): void
}

type Props = StateProps & DispatchProps & OwnProps

class Register extends Component<Props> {
  state = {
    name: "",
    lastName: "",
    email: "",
    password: "",
    acceptedTermsAndPrivacy: false
  };

  render() {
    const { loading, error, registered, history } = this.props
    return (
      <Container>
        <Header>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              display: "flex",
            }}
          >
            <FaArrowLeft color="white" size={20} />
          </Link>
          <p>Cadastro</p>
        </Header>
        <Body
          style={{ backgroundImage: `url(${Image})`, backgroundSize: "cover" }}
        >{error && (
          <ErrorMessage>
            <span>Erro ao realizar o cadastro.</span>
          </ErrorMessage>
        )}
          <span>Preencha suas informações abaixo.</span>
          <InputsContainer>
            <Input>
              <FiUser size={30} color="gray" />
              <input
                type="text"
                placeholder="Nome"
                onChange={this.saveName.bind(this)}
              />
            </Input>
            <Input>
              <FiUser size={30} color="gray" />
              <input
                type="text"
                placeholder="Sobrenome"
                onChange={this.saveLastName.bind(this)}
              />
            </Input>
            <Input>
              <FiMail size={30} color="gray" />
              <input
                type="email"
                placeholder="Email"
                onChange={this.saveEmail.bind(this)}
              />
            </Input>
            <Input>
              <FiUnlock size={30} color="gray" />
              <input
                type="password"
                placeholder="Senha"
                onChange={this.savePassword.bind(this)}
              />
            </Input>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 30
            }}>
              <input
                type="checkbox"
                name="terms"
                onChange={this.updateTermsAndPrivacy.bind(this)}></input>
              <span style={{
                fontSize: 16
              }}>Li e concordo com os termos da politica de&nbsp;</span>
              <a href="#/"
                onClick={() => {
                  window.open('https://guiaderodas.com/politica-de-privacidade')
                }} >Privacidade Guiaderodas</a><br />
            </div>
          </InputsContainer>
          <ButtonsContainer>
            <Button
              disabled={this.buttonDisabled()}
              onClick={() => this.signUp()}
            >
              Cadastrar-se
            </Button>
          </ButtonsContainer>
        </Body>
        {loading && (
          <Loading
            height={window.innerHeight}
            title="Cadastrando"
            subtitle="Usuário"
          />
        )}
        {registered && <Alert
          height={window.innerHeight}
          title="Sucesso!"
          message="Seu cadastro foi realizado com sucesso! Agora você pode fazer o login com sua nova conta."
          textButton="OK"
          onOKClicked={() => history.push("/")}
        />}
      </Container>
    )
  }

  buttonDisabled(): boolean {
    const { name, lastName, email, password, acceptedTermsAndPrivacy } = this.state

    if (
      name.trim().length > 0 &&
      lastName.trim().length > 0 &&
      email.trim().length > 0 &&
      password.trim().length > 0 &&
      acceptedTermsAndPrivacy === true
    ) {
      return false
    }

    return true
  }

  signUp() {
    const { name, lastName, email, password, acceptedTermsAndPrivacy } = this.state
    const { signUpRequest } = this.props

    const user: User = {
      first_name: name,
      last_name: lastName,
      privacy_policy_accepted: acceptedTermsAndPrivacy,
      email,
      password,
    }

    signUpRequest(user)
  }

  saveName(event: any) {
    this.setState({ name: event.target.value })
  }

  saveLastName(event: any) {
    this.setState({ lastName: event.target.value })
  }

  saveEmail(event: any) {
    this.setState({ email: event.target.value })
  }

  savePassword(event: any) {
    this.setState({ password: event.target.value })
  }
  
  updateTermsAndPrivacy(event: any) {
    this.setState({ acceptedTermsAndPrivacy: event.target.checked })
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  registered: state.user.registered,
  loading: state.user.loading,
  error: state.user.error,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    signUpRequest: (user: User) => dispatch(signUpRequest(user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
