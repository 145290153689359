import React from "react"
import { 
  Container, 
  HeaderContainer, 
  Body,
  InputsContainer,
  Input,
  ButtonsContainer,
  Button,
  ErrorMessage, 
} from "./styles"
import { FiX, FiMail } from "react-icons/fi"
import Image from "../../../../resources/img/bg_city.png"

export default ({
  handleCloseClicked,
  showError,
  onChangeEmail,
  buttonDisabled,
  recoverPasswordClicked
}: any) => {
  return (
    <Container>
      <HeaderContainer>
        <div
          style={{
            marginLeft: 10,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        > 
          <span style={{ marginLeft: 20 }}>Recuperar Senha</span>
        </div>
        <FiX
          style={{ cursor: "pointer", position: "fixed", right: 15, top: 15 }}
          size={25}
          color={"rgb(48,48,48)"}
          onClick={handleCloseClicked}
        />
      </HeaderContainer>
        <Body
          style={{ backgroundImage: `url(${Image})`, backgroundSize: "cover" }}
        >
          <span>
            Digite seu email e clique em 'Recuperar' para receber as instruções.
          </span>
          <InputsContainer>
            {showError && (
              <ErrorMessage>
                <span>Email não cadastrado.</span>
              </ErrorMessage>
            )}
            <Input>
              <FiMail size={20} color="gray" />
              <input
                type="email"
                placeholder="Email"
                onChange={onChangeEmail}
              />
            </Input>
          </InputsContainer>
          <ButtonsContainer>
            <Button
              disabled={buttonDisabled}
              onClick={recoverPasswordClicked}
            >
              Recuperar
            </Button>
          </ButtonsContainer>
        </Body>
    </Container>
  )
}
