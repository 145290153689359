import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 6;
  position: fixed;
  justify-content: center;
  align-items: center;
`


export const CloseContainer = styled.div`
  position: absolute;
  height: 35px;
  right: 26%;
  top: 7%;
  background-color: rgba(0,0,0,0.4);
  border-radius: 50%;
  cursor: pointer;

  @media(max-width: 1440px) and (min-width: 1024px){
    right: 25%;
  } 

  @media(max-width: 1024px) and (min-width: 768px){
    right: 22%;
  } 

  @media(max-width: 768px) and (min-width: 540px){
    right: 12%;
  } 
  @media(max-width: 540px) and (min-width: 450px){
    right: 15%;
  }
`

export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  width: 50vw;
  min-width: 576.5px;
  height: 85vh;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;

  img {
    width: 50px;
    height: 70px;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26vw;
    min-width: 304.41px;
    height: 6vh;
    border: 0px none;
    border-radius: 4pt;
    cursor: pointer;
    margin-bottom: 10pt;
  }

  @media(max-width: 450px) {
    display: none;
  }

  @media(max-width: 650px) and (min-width: 450px){
    min-width: 380px;
  }
`


export const TermsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

  @media(max-width: 650px) and (min-width: 450px){
    width: 80%;
  }
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12pt;
  margin-top: 20px;
  input[type=checkbox] {
    transform: scale(1.5);
    margin-right: 10px
  }
`

export const Input = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 35%;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5pt;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.4);
  padding: 5pt;
  background-color: white;
  margin-top: 20px;

  input {
    border: 0px none;
    font-size: 14pt;
    margin-left: 4pt;
    width: 100%;
  }
  @media(max-width: 650px) and (min-width: 450px){
    width: 80%;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px;
  margin-top: 30px;
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37%;
  height: 40px;
  border-radius: 5px;
  background-color: #d90368;
  font-size: 14pt;
  color: white;
  border: 0px none;
  cursor: pointer;

  :disabled {
    background-color: rgba(214, 8, 107, 0.4);
  }

  @media(max-width: 450px) {
    height: 35px;
    font-size: 12pt;
  }
`

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 35%;

  span {
    color: red;
    font-size: 20pt;
  }
`
