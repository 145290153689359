import React, { Component } from "react"
import { FaLessThan } from "react-icons/fa"
import { connect } from "react-redux"
import { Dispatch } from "redux"

import { ApplicationState } from "../../store"
import { sendFeedbackRequest } from "../../store/ducks/feedback/actions"
import { FeedbackModel } from "../../store/ducks/feedback/types"
import { UserInfos } from "../../store/ducks/user/types"
import {
  ContentInput,
  FeedbackContainer,
  FeedbackModal,
  RatingContainer,
  SendButton,
  StarsContainer,
  SubjectInput,
  EmailInput,
  StarIcon,
  Container
} from "./styles"
import MobileFeedback from './mobile/MobileFeedback'

import StarGrey from "../../resources/icons/star@3x.png"
import StarGreen from "../../resources/icons/star_green@3x.png"

interface OwnProps {
  user: UserInfos | null
  changeModal(renderEditProfile: boolean, renderProfile: boolean, renderFeedback: boolean): void
  showAlert(): void
}

interface DispatchProps {
  sendFeedbackRequest(feedback: FeedbackModel): void
}

type Props = OwnProps & DispatchProps

class Feedback extends Component<Props> {
  state = {
    reviewStarsIcon: [StarGrey, StarGrey, StarGrey, StarGrey, StarGrey],
    reviewText: ["Horrível", "Ruim", "Mais ou Menos", "Bom", "Muito Bom!"],
    subject: "",
    content: "",
    rating: 0,
    ratingText: "Muito Bom!",
    email: "",
  };

  render() {
    const { reviewStarsIcon } = this.state

    return (<Container>
      <FeedbackModal>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            padding: "10pt",
          }}
        >
          <FaLessThan color="rgb(0,0,0,0.6)" size={13} />
          <span
            onClick={() => this.props.changeModal(false, true, false)}
            style={{
              marginLeft: "5pt",
              fontSize: "13pt",
              color: "rgb(0,0,0,0.6)",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Voltar
          </span>
        </div>
        <RatingContainer>
          <span>Que nota você dá para o Guiaderodas?</span>
          <StarsContainer>
            <StarIcon
              src={reviewStarsIcon[0]}
              onClick={() => this.changeRating(0)}
            />
            <StarIcon
              src={reviewStarsIcon[1]}
              onClick={() => this.changeRating(1)}
            />
            <StarIcon
              src={reviewStarsIcon[2]}
              onClick={() => this.changeRating(2)}
            />
            <StarIcon
              src={reviewStarsIcon[3]}
              onClick={() => this.changeRating(3)}
            />
            <StarIcon
              src={reviewStarsIcon[4]}
              onClick={() => this.changeRating(4)}
            />
          </StarsContainer>
        </RatingContainer>
        <FeedbackContainer>
          <span>Envie-nos seu Feedback</span>
          <SubjectInput
            type="text"
            placeholder="Assunto"
            onChange={this.saveSubject.bind(this)}
          />
          {!this.props.user?.email ? <EmailInput
            type="email"
            placeholder="email@email.com"
            onChange={this.saveEmail.bind(this)}
            style={{ marginBottom: "10pt" }}
          /> : null}
          <ContentInput
            placeholder="Mensagem"
            onChange={this.saveContent.bind(this)}
          />
        </FeedbackContainer>
        <SendButton
          disabled={this.shouldDisableButton()}
          onClick={() => {
            this.sendFeedback()
            this.props.changeModal(false, false, false)
            this.props.showAlert()
          }}
        >
          Enviar
        </SendButton>
      </FeedbackModal>
      <MobileFeedback
        user={this.props.user}
        reviewStarsIcon={reviewStarsIcon}
        shouldDisableButton={this.shouldDisableButton()}
        handleContentChanged={this.saveContent.bind(this)}
        handleEmailChanged={this.saveEmail.bind(this)}
        handleSubjectChanged={this.saveSubject.bind(this)}
        handleRatingChanged={(position: number) => this.changeRating(position)}
        handleSendClicked={() => {
          this.sendFeedback()
          this.props.changeModal(false, false, false)
          this.props.showAlert()
        }}
        handleBackClicked={() => this.props.changeModal(false, true, false)}
        handleCloseClicked={() => this.props.changeModal(false, false, false)}
      />
    </Container>)
  }

  sendFeedback() {
    const { subject, content, rating, ratingText } = this.state
    const { sendFeedbackRequest, user } = this.props

    if (user) {
      const feedback = {
        user_id: user.id,
        user_email: user.email,
        user_name: user.first_name,
        subject: subject,
        content: content,
        rating: rating,
        ratingText: ratingText,
        email_for_feedback: this.state.email,
      }

      this.setState({ showAlert: true })

      sendFeedbackRequest(feedback)
    }
  }

  shouldDisableButton() {
    const { subject, content, email } = this.state
    const { user } = this.props

    if (user && !user.email) {
      if (
        subject.trim().length > 0 &&
        content.trim().length > 0 &&
        email.trim().length > 0
      ) {
        return false
      } else {
        return true
      }
    } else {
      if (subject.trim().length > 0 && content.trim().length > 0) {
        return false
      } else {
        return true
      }
    }
  }

  saveSubject(event: any) {
    this.setState({ subject: event.target.value })
  }

  saveContent(event: any) {
    this.setState({ content: event.target.value })
  }

  saveEmail(event: any) {
    if (event.target.value.includes("@")) {
      this.setState({ email: event.target.value })
    } else {
      this.setState({ email: "" })
    }
  }

  changeRating(position: number) {
    const { reviewStarsIcon, reviewText } = this.state

    const ratingText = reviewText[position]
    let newReviews = reviewStarsIcon

    newReviews.forEach((review, index) => {
      if (index <= position) {
        newReviews[index] = StarGreen
      } else {
        newReviews[index] = StarGrey
      }
    })

    this.setState({
      reviewStarsIcon: newReviews,
      rating: position + 1,
      ratingText,
    })
  }
}

const mapStateToProps = (state: ApplicationState) => ({})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    sendFeedbackRequest: (feedback: FeedbackModel) =>
      dispatch(sendFeedbackRequest(feedback)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
