import React from "react"
import { FiX } from "react-icons/fi"

import FacebookButton from "../../facebook/FacebookButton"
import bgImage from "../../../resources/img/venue_placeholder.png"
import pin from "../../../resources/icons/PIN-Verde.png"
import { Link } from "react-router-dom"
import { Container, LoginContainer, SignUp, HeaderContainer } from "./styles"

interface OwnProps {
  showLogin: (show: boolean) => void
  showSignUp: (show: boolean) => void
  onEmailChanged: (event: any) => void
  onPasswordChanged: (event: any) => void
  handleLogin: () => void
  handleFacebookLogin: (grant_type: string, user: any, access_token: string | null) => void
}

export default ({
  showLogin,
  showSignUp,
  onEmailChanged,
  onPasswordChanged,
  handleLogin,
  handleFacebookLogin }: OwnProps) => {
  return (
    <Container>
      <div
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
        }}
      >
        <LoginContainer>
          <HeaderContainer>
            <FiX
              style={{ cursor: "pointer", position: 'fixed', right: 10, top: 10 }}
              size={25}
              color={"rgb(48,48,48)"}
              onClick={() => showLogin(false)} />
          </HeaderContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: window.innerHeight < 600 ? 10 : 30
            }}
          >
            <img src={pin} alt="pin" />
            <span
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "rgba(0,0,0,0.8)",
                marginLeft: 10,
              }}
            >
              guiaderodas
          </span>
          </div>

          <div
            style={{
              textAlign: "center",
              width: 300,
              marginBottom: window.innerHeight < 600 ? 10 : 30
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontWeight: "bold",
                color: "rgba(0,0,0,0.8)",
              }}
            >
              Faça o login ou crie uma conta para aproveitar melhor o
              guiaderodas.
          </span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: 13 }} >Email</span>
            <input
              type="email"
              onChange={onEmailChanged}
              style={{ marginBottom: 10, marginTop: 5 }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: 13 }} >Senha</span>
            <input
              type="password"
              onChange={onPasswordChanged}
              style={{ marginBottom: 13, marginTop: 5 }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: 290,
              marginBottom: window.innerHeight < 600 ? 20 : 40
            }}
          >
            <Link
              to={{
                pathname: "/recover"
              }}
              style={{
                display: "flex",
                width: '100%',
                justifyContent: "flex-end",
                textDecoration: "none"
              }}
            >
              <span
                style={{
                  fontSize: 13,
                  color: "#D90368",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Esqueci minha senha
          </span>
            </Link>
          </div>

          <button
            style={{ backgroundColor: "#D90368" }}
            onClick={handleLogin}
          >
            <span style={{ color: "white" }}>Login</span>
          </button>
          <FacebookButton loginRequest={handleFacebookLogin} />
          <SignUp style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <span>
              Ainda não tem conta?
          </span>

            <span
              style={{ marginLeft: 5, }}
              onClick={() => {
                showLogin(false)
                showSignUp(true)
              }}
            >
              <span style={{
                color: "white",
                marginLeft: 5,
                fontWeight: "bold",
                cursor: "pointer",
                textDecoration: "none"
              }}>Cadastre-se.</span>
            </span>
          </SignUp>
        </LoginContainer>

      </div>
    </Container>
  )
}
