import styled from "styled-components"

interface Props {
  visible?: boolean
}

export const MobileSearchContainer = styled.div<Props>`
  display: none;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
  @media(max-width: 450px) and (min-width: 300px) {
    display: block;
    opacity: ${props => props.visible ? 1 : 0};
  } 
`

export const SearchContainer = styled.div<Props>`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px 0px;
  margin-top: 90px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  @media(max-width: 450px) and (min-width: 300px) {
    box-shadow: 0px 20px 24px rgb(0,0,0,0.3);
  } 

`

export const MainSearchContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
`

export const SearchContainerItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
`

export const SearchLocale = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%
  height: 40px;

  #search-locale-header{
    font-size: 10pt;
  }
  span {
    font-weight: 300;
  }
`

export const LocaleInput = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 8pt;
  padding-right: 8pt;
  padding-top: 5pt;
  padding-bottom: 5pt;
  height: 35px;
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4pt;
  margin-top: 5pt;
  justify-content: center;
  text-align: center;
  align-items: center;

  input {
    background-color: transparent;
    border: 0px none;
    margin-left: 5pt;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    font-size: 10pt;
  }
`

export const LocaleTypes = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 48px;
  width: 100%;
  font-size: 10pt;

  #locale-header{
    max-height: 15px;
  }
  span {
    font-weight: 300;
  }
  @media(max-width: 340px) and (min-width: 300px) {
    .typesDropdown {
      margin-left: -10px;
    }
  }
  @media(max-width: 370px) and (min-width: 340px) {
    .typesDropdown {
      margin-left: 30px;
    }
  }
  @media(max-width: 400px) and (min-width: 370px) {
    .typesDropdown {
      margin-left: 45px;
    }
  }
  @media(max-width: 425px) and (min-width: 400px) {
    .typesDropdown {
      margin-left: 75px;
    }
  }
  @media(max-width: 450px) and (min-width: 425px) {
    .typesDropdown {
      margin-left: 100px;
    }
  }
}
`

export const AccessibilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 90px;
  margin-right: 25px;
  cursor: pointer;
  font-size: 10pt;
  #label-acessibilidade {
    font-size: 10pt;
  } 

  span {
    font-weight: 300;
  }

  .accessibilityDropdown {
    margin-left: -20px;
  }
`

export const DropdownButton = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  padding: 8pt;
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4pt;
  margin-top: 5pt;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  span {
    color: rgba(0, 0, 0, 0.5);
  }
`
export const DropDownContainer = styled.div`
  display: flex;
  max-width: 180px;
  flex-direction: column;
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4pt;
  position: fixed;
  z-index: 2;
  margin-top: 10px;

  span {
    padding: 10pt;
    cursor: pointer;

    :hover {
      background-color: rgb(225, 225, 225);
    }
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(200, 200, 200);
    padding-left: 10pt;

    :hover {
      background-color: rgb(225, 225, 225);
    }
  }
  .todos-dropdown,
  .todos-dropdown {
    background-color: rgb(225, 225, 225);
  }
`

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 20px;
  width: 300px;
  height: 200px;
  border-bottom: 1px solid #ccc;

  @media(max-height: 580px){
    height: 175px;
  }
`

export const UserContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  margin-top: 20px;

  #full-name {
    font-weight: 300;
    font-size: 16pt;
    white-space: nowrap;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #email {
    font-size: 10pt;
  }

  #my-profile {
    padding: 15px;
    padding-left: 0px;
    font-size: 14pt;
    color: rgb(217, 3, 104);
  }

  @media(max-width: 380px) and (min-width: 320px) {
    #full-name {
      max-width: 180px;
      font-size: 14pt;
    }

    #email {
      font-size: 8pt;
    }

    #my-profile {
      font-size: 12pt; 
    }
  }
`

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 24px;
  padding-left: 11px;
  width: 200px;
  height: 100%;
  max-height: 300px
  border-bottom: 1px solid #ccc;
  margin-top: 10px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13pt;
    color: rgb(102, 102, 102);
    margin-bottom: 5px;
    padding: 15px;
    vertical-align: middle;
    cursor: pointer;
    :hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  @media(max-height: 650px){
    padding-left: 11px;
    margin-top: 10px;
    span {
      padding: 10px;
    }
  }
`

export const LogInOutContainer = styled.div`
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: flex-start;
  padding-left: 31px; 
  border-top: 1px solid #ccc;
  color: rgb(217, 3, 104);
  cursor: pointer;
  background-color: white;
`
export const DividerContainer = styled.div`
position: relative;
width: 100%;
display: flex;
flex: 1;
align-items: center;
height: 30px;
justify-content: center;
`

export const DividerHR = styled.div<Props>`
    height: 1px;
    background-color: ${props => props.color ? props.color  : 'black'};
    flex: 1;
`

export const DividerText = styled.div`
position: absolute;
display: flex;
align-items: center;
justify-content: center;
background-color: white;
padding: 0px 16px;

span {
  font-size: 12pt;
  color: rgb(102, 102, 102);
}
`