import React from "react"
import {
  SearchContainer,
  SearchLocale,
  LocaleInput,
  LocaleTypes,
  AccessibilityContainer,
  SearchContainerItems,
  MobileSearchContainer,
  MainSearchContainerItems,
  DividerContainer,DividerHR,DividerText
} from "./styles"

import {
  DropDownStyle,
  SearchLabel,
  CitySelector,
  StateSelector,
} from  "../styles"

import Select from 'react-select'
import {CustomSelectOption,CustomSelectValue} from '../../../featureComponents/customSelect/CustomSelect'

interface Props {
  visible: boolean
  isDrawerMenuOpen: boolean
  searchVenue: string
  searchIcon: any
  localeTypesDropdown: any
  categoryOptions: any
  accessibilityOptions:any
  statesOptions:any
  citiesOptions:any
  loadingCities: boolean
  filterContainerStyle: any
  currentStateUF: any
  currentCity:any
  currentCategory:any
  currentAccessibility:any
  onSearchVeneuChanged: (event: any) => void
  onKeyPressSearchVeneu: (event: any) => void
  onChangeVenueType: (value: any) => void
  onChangeAccessibility: (value: any) => void
  onChangeStateUF: (value: any) => void
  onChangeCity: (value: any) => void
}

export default ({
  visible,
  isDrawerMenuOpen,
  searchIcon,
  searchVenue,
  onSearchVeneuChanged,
  onKeyPressSearchVeneu,
  onChangeVenueType,
  onChangeAccessibility,
  onChangeStateUF,
  onChangeCity,
  categoryOptions,
  accessibilityOptions,
  statesOptions,
  citiesOptions,
  loadingCities,
  filterContainerStyle,
  currentStateUF,
  currentCity,
  currentCategory,
  currentAccessibility,
}: Props) => {

  if (isDrawerMenuOpen) {
    return null
  }

  const filterContainerStyled = {...filterContainerStyle,display:'block'}

  return (<MobileSearchContainer visible={visible}>
    <SearchContainer visible={visible} >
      <MainSearchContainerItems>
        <SearchLocale>
          <span id="search-locale-header">Buscar Locais com Acessibilidade</span>
          <LocaleInput>
            {searchIcon}
            <input
              id="searchMobile"
              type="text"
              autoFocus={false}
              placeholder="Restaurante em Copacabana"
              value={searchVenue || ''}
              onChange={onSearchVeneuChanged}
              onKeyPress={onKeyPressSearchVeneu}
            />
          </LocaleInput>
        </SearchLocale>
        <SearchContainerItems >
          <DividerContainer>
            <DividerHR color='lightGray'/>
            <DividerText><span>OU</span></DividerText>
          </DividerContainer>
        </SearchContainerItems>
        <div style={filterContainerStyled}>
        <SearchContainerItems >
            <LocaleTypes style={{marginRight:24}}>
              <SearchLabel>Categorias</SearchLabel>
              <Select options={categoryOptions} styles={DropDownStyle} placeholder={null} isLoading={false} loadingMessage={()=>"Carregando..."} defaultValue={currentCategory}  onChange={onChangeVenueType} isSearchable={false}/>
            </LocaleTypes>
            <AccessibilityContainer style={{marginRight:2}}>
                    <SearchLabel id="label-acessibilidade">Acessibilidade</SearchLabel>
                    <Select options={accessibilityOptions} styles={DropDownStyle} placeholder={null} isLoading={false} loadingMessage={()=>"Carregando..."} defaultValue={currentAccessibility}  onChange={onChangeAccessibility}
                     components={{ Option: CustomSelectOption, SingleValue: CustomSelectValue }} width="140px" isSearchable={false}/>
            </AccessibilityContainer>
            </SearchContainerItems>
            <SearchContainerItems>
            <StateSelector style={{marginRight:24}}>
                    <SearchLabel>Estado</SearchLabel>
                    <Select options={statesOptions} styles={DropDownStyle} placeholder={null}  onChange={onChangeStateUF} loadingMessage={()=>"Carregando..."} defaultValue={currentStateUF}/>
                  </StateSelector>
                 <CitySelector style={{marginRight:2,flex:1}}>
                  <SearchLabel>Cidade</SearchLabel>
                     <Select options={citiesOptions} styles={DropDownStyle} placeholder={null} noOptionsMessage={()=>"Selecione o Estado"}  defaultValue={currentCity}
                     onChange={onChangeCity} isLoading={loadingCities} loadingMessage={()=>"Carregando..."}/>
                  </CitySelector>
               
        </SearchContainerItems>
        </div>
      </MainSearchContainerItems>
    </SearchContainer>
  </MobileSearchContainer>)
}
