import { createStore, applyMiddleware, Store } from "redux";
import createSagaMiddleware from "redux-saga";

import { CategoriesState } from "./ducks/categories/types";
import { LocationsState } from "./ducks/locations/types";
import { CompaniesState } from "./ducks/companies/types";
import { FeedbackState } from "./ducks/feedback/types";
import { ServiceState } from "./ducks/service/types";
import { VenuesState } from "./ducks/venues/types";
import { ReviewState } from "./ducks/review/types";
import { UserState } from "./ducks/user/types";
import rootReducer from "./ducks/rootReducer";
import rootSaga from "./ducks/rootSaga";

export interface ApplicationState {
  locations: LocationsState;
  categories: CategoriesState;
  companies: CompaniesState;
  feedback: FeedbackState;
  service: ServiceState;
  venues: VenuesState;
  review: ReviewState;
  user: UserState;
}

const sagaMiddleware = createSagaMiddleware();

const store: Store<ApplicationState> = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(rootSaga);

export default store;
