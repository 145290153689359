import { FiBook, FiUser, FiHome } from "react-icons/fi";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import { FooterContainer, NavigationIcons } from "./styles";

interface OwnProps {
    screen: String;
}

type Props = OwnProps;

export default class Footer extends Component<Props> {
  render() {
    const token = localStorage.getItem("AUTH_DATA");

    return (
      <FooterContainer>
        <Link to="/" style={{ textDecoration: "none" }}>
          <NavigationIcons>
            <FiHome color={"rgb(0, 187, 55)"} size={30} />
            {this.props.screen === "home" ? <span>Principal</span> : null}
          </NavigationIcons>
        </Link>
        <Link to="/catalog" style={{ textDecoration: "none" }}>
          <NavigationIcons>
            <FiBook color={"rgb(0, 187, 55)"} size={30} />
            {this.props.screen === "catalog" ? <span>Catálogo</span> : null}
          </NavigationIcons>
        </Link>
        <Link to={token != null ? "/profile" : "/login"} style={{ textDecoration: "none" }}>
          <NavigationIcons>
            <FiUser color={"rgb(0, 187, 55)"} size={30} />
            {this.props.screen === "profile" ? <span>Perfil</span> : null}
          </NavigationIcons>
        </Link>
      </FooterContainer>
    );
  }
}
