import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: fixed;
  z-index: 2;
`;

export const Header = styled.div`
  flex: 1;
  height: 20%;
  background-color: rgb(0, 187, 55);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 12pt;
  padding-right: 12pt;

  p {
    margin-left: 20pt;
    font-size: 18pt;
    font-weight: bold;
    color: white;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60pt;
  padding-left: 12pt;
  padding-right: 12pt;
  padding-bottom: 12pt;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;

  span {
    margin-top: 29pt;
    font-size: 18pt;
    color: rgb(80, 80, 80);
    margin-bottom: 37pt;
  }
`;

export const BorderText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-bottom: -7pt;
  z-index: 1;
  width: 80px;
  margin-right: 40%;
  color: rgb(155, 155, 155);
`;

export const MessageInput = styled.textarea`
  width: 50%;
  height: 300pt;
  resize: none;
  border-radius: 5pt;
  border-color: rgb(190, 190, 190);
  padding: 10px;
  font-size: 18px;
`;

export const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: -15pt;
  margin-bottom: 10pt;
  margin-top: -10pt;

  span {
    font-size: 12pt;
    font-weight: normal;
    margin: 0;
  }
`;

export const EmailInput = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5pt;
  border: 1px solid rgb(190, 190, 190);
  font-size: 18px;
`;

export const Button = styled.button`
  height: 40pt;
  width: 51.5%;
  background-color: rgb(214, 8, 107);
  margin-top: 21pt;
  border-radius: 5pt;
  border: 0px none;
  font-size: 15pt;
  color: white;
  font-family: Helvetica;
  cursor: pointer;

  :disabled {
    background-color: rgba(214, 8, 107, 0.4);
  }
`;
