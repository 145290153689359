import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100vw;
  top: 0;
  left: 0;
`;

export const Header = styled.div`
  flex: 1;
  height: 20%;
  background-color: rgb(0, 187, 55);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 12pt;
  padding-right: 12pt;

  p {
    margin-left: 20pt;
    font-size: 18pt;
    font-weight: bold;
    color: white;
  }
`;

export const Body = styled.div`
  height: 79vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 60pt;
  padding-left: 12pt;
  padding-right: 12pt;
  padding-bottom: 12pt;

  span {
    color: rgba(0, 0, 0, 0.7);
    font-size: 20pt;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12pt;
  margin-top: 20px;
`;

export const Input = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 35%;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5pt;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.4);
  padding: 5pt;
  background-color: white;
  margin-top: 20px;

  input {
    border: 0px none;
    font-size: 14pt;
    margin-left: 4pt;
    width: 100%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12pt;
  margin-top: 30px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37%;
  height: 40pt;
  border-radius: 5pt;
  background-color: #d90368;
  font-size: 14pt;
  color: white;
  border: 0px none;
  cursor: pointer;

  :disabled {
    background-color: rgba(214, 8, 107, 0.4);
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 35%;

  span {
    color: red;
    font-size: 20px;
  }
`;
