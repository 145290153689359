import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  z-index: 6;
  position: fixed;
  justify-content: center;
  align-items: center;

  @media(max-width: 450px){
    width: 100vw;
    height: 100vh;
  }
`

export const FeedbackModal = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  position: fixed;
  z-index: 6;
  background-color: white;
  right: 1.9%;
  top: 75px;
  width: 376px;
  height: 630px;
  border: 2px solid rgb(0, 0, 0, 0.05);
 
  @media(max-width: 450px){
    display: none;
  }
  
  @media(max-height: 500px){
    height: 350px;
    overflow: scroll;
  }
  @media(max-height: 600px) and (min-height: 500px){
    height: 430px;
    overflow: scroll;
  }
  @media(max-height: 700px) and (min-height: 600px){
    height: 530px;
    overflow: scroll;
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;

  span {
    font-size: 15px;
    font-weight: bold;
    color: rgb(0, 0, 0, 0.7);
  }
`;

export const StarsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  width: 68%;
`;

export const FeedbackContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  border-top: 1px solid rgb(240, 240, 240);
  border-bottom: 1px solid rgb(240, 240, 240);

  span {
    color: rgb(0, 0, 0, 0.7);
  }
`;

export const SubjectInput = styled.input`
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;
`;

export const EmailInput = styled.input`
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;
`;

export const ContentInput = styled.textarea`
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;
  resize: none;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const SendButton = styled.button`
  margin: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #d90368;
  border: 0px none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;

  :disabled {
    background-color: #d9036855;
  }
`;


export const StarIcon = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;
