import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  z-index: 6;
  position: fixed;
  justify-content: center;
  align-items: center;

  @media(max-width: 450px){
    width: 100vw;
    height: 100vh;
  }
`

export const ProfileModal = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  position: fixed;
  z-index: 4;
  background-color: white;
  right: 1.9%;
  top: 75px;
  width: 376px;
  height: 630px;
  border: 2px solid rgb(0, 0, 0, 0.05);

  @media(max-width: 450px){
    display: none;
  }
  @media(max-height: 500px){
    height: 350px;
    overflow: scroll;
  }
  @media(max-height: 600px) and (min-height: 500px){
    height: 430px;
    overflow: scroll;
  }
  @media(max-height: 700px) and (min-height: 600px){
    height: 530px;
    overflow: scroll;
  }
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(240, 240, 240);
  border-bottom: 1px solid rgb(240, 240, 240);
`

export const UserInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const EditButton = styled.button`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9036855;
  background-color: white;
  color: #d90368;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
`
