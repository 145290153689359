import React, { Component } from "react"
import { FiX, FiArrowLeft } from "react-icons/fi"

import bgImage from "../../../resources/img/venue_placeholder.png"
import { Question } from "../../../store/ducks/review/types"
import { Container, HeaderContainer, HelpContainer, QuestionContainer } from "./styles"

interface OwnProps {
  questions: Array<Question[]>
  currentQuestion: number
  hideHelp(): void
}

type Props = OwnProps

export default class Help extends Component<Props> {
  state = {
    showDoubt: `${this.props.currentQuestion}0`,
  };

  render() {
    const { showDoubt } = this.state
    const { questions, currentQuestion, hideHelp } = this.props

    return (
      <Container>
        <HeaderContainer>
          <FiX
            style={{ cursor: "pointer", position: "fixed", right: 15, top: 15 }}
            size={25}
            color={"rgb(48,48,48)"}
            onClick={hideHelp}
          />
        </HeaderContainer>
        <div
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            padding: 0,
            margin: 0,
            paddingTop: 60,
            height: '75%',
            overflow: "auto",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <HelpContainer >
            <span
              style={{
                fontSize: 18,
                fontWeight: "bold",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              Ajuda
            </span>
            {questions.map((subQuestions, subQuestionsIndex) => {
              return subQuestions.map((question, questionIndex) => {
                return (
                  <QuestionContainer
                    style={{
                      marginTop: subQuestionsIndex === 0 ? 15 : 0,
                      marginBottom: 15
                    }}
                    onClick={() =>
                      this.setState({
                        showDoubt: `${subQuestionsIndex}${questionIndex}`,
                      })
                    }
                  >
                    <h3
                      style={{
                        fontSize: 13,
                        fontWeight:
                          subQuestionsIndex === currentQuestion
                            ? "bold"
                            : "normal",
                      }}
                    >
                      {question.text}
                    </h3>
                    {(`${subQuestionsIndex}${questionIndex}` === showDoubt) &&
                      this.formatHelp(question.help, subQuestionsIndex)}
                  </QuestionContainer>
                )
              })
            })}
          </HelpContainer>
        </div>
      </Container>
    )
  }

  formatHelp(questionHelp: string, index: number) {
    switch (index) {
      case 0:
        return (
          <span
            style={{
              marginBottom: 15,
              fontSize: 12,
            }}
          >
            {questionHelp}
          </span>
        )
      case 1:
        const newStrings = questionHelp.split("-")
        const length = newStrings.length
        return newStrings.map((string, index) => {
          return (
            <span
              style={{
                marginBottom: length - 1 === index ? 10 : 5,
                paddingBottom: length - 1 === index ? 20 : 0,
                fontSize: 12,
              }}
            >
              {index > 0 && "- "}
              {string}
            </span>
          )
        })
      case 2:
        const newStrings1 = questionHelp.split("-")
        const length1 = newStrings1.length
        return newStrings1.map((string, index) => {
          return (
            <span
              style={{
                marginBottom: length1 - 1 === index ? 10 : 5,
                paddingBottom: length1 - 1 === index ? 20 : 0,
                fontSize: 12,
              }}
            >
              {index > 0 && "- "}
              {string}
            </span>
          )
        })
      case 3:
        const splited = questionHelp.split('"')
        const size = splited.length
        return splited.map((string, index) => {
          return (
            <span
              style={{
                marginBottom: size - 1 === index ?  10 : 5,
                paddingBottom: size - 1 === index ? 20 : 0,
                fontSize: 12,
              }}
            >
              {(index > 0 && index % 2 !== 0) && "- "}
              {(index === 0 || index % 2 !== 0) && this.formatString(string, index)}
            </span>
          )
        })
    }
  }

  formatString(string: string, index: number) {
    if (index > 0) {
      return `"${string}"`
    } else {
      return string
    }
  }

  renderBackButton() {
    const { hideHelp } = this.props

    return (
      <div
        onClick={() => hideHelp()}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          left: "26%",
          top: "6.5%",
          borderRadius: "50%",
          cursor: "pointer",
        }}
      >
        <FiArrowLeft
          style={{ cursor: "pointer", marginRight: 10 }}
          size={25}
          color={"rgb(48,48,48)"}
        />
      </div>
    )
  }
}
