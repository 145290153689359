import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  .facebook {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #37549a;
    color: white;
    cursor: pointer;
    width: 26vw;
    height: 44px;
    border-radius: 4pt;
    border: 0px none;
  }
`;
