import { ReviewState, ReviewTypes } from "./types"
import { Reducer } from "redux"

const INITIAL_STATE = {
  data: [],
  error: false,
  loading: false,
  sent: false,
}

const reducer: Reducer<ReviewState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReviewTypes.QUESTIONS_REQUEST:
      return { ...state, loading: true }
    case ReviewTypes.QUESTIONS_RESET_STATE:
      return INITIAL_STATE
    case ReviewTypes.QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      }
    case ReviewTypes.QUESTIONS_FAILURE:
      return { ...state, loading: false, error: true, data: [] }
    case ReviewTypes.REVIEW_REQUEST:
      return { ...state, loading: true, error: false }
    case ReviewTypes.REVIEW_SUCCESS:
      return { ...state, loading: false, error: false, sent: true }
    case ReviewTypes.REVIEW_FAILURE:
      return { ...state, loading: false, error: true }
    default:
      return state
  }
}

export default reducer
