import React from "react"
import { FiX } from "react-icons/fi"
import { UserInfos } from "../../../store/ducks/user/types"
import { HeaderContainer, Container, EditButton, ProfileModal, UserInfo, UserInfoBox } from "./styles"

interface OwnProps {
  user: UserInfos | null
  renderGender: any
  calculateAge: any
  formatDisability: any
  handleEditClicked(): void
  handleFeedbackClicked(): void
  handleLogoutClicked(): void
  handleCloseClicked(): void
}

export default ({
  user,
  renderGender,
  calculateAge,
  formatDisability,
  handleEditClicked,
  handleFeedbackClicked,
  handleLogoutClicked,
  handleCloseClicked
}: OwnProps) => {
  return (
    <Container>
      <HeaderContainer>
        <div 
          style={{ marginLeft: 10 }}>
          <span>Meu Perfil</span>
        </div>
        <FiX
          style={{ cursor: "pointer", position: 'fixed', right: 15, top: 15 }}
          size={25}
          color={"rgb(48,48,48)"}
          onClick={handleCloseClicked} />
      </HeaderContainer>
      <ProfileModal>
        <span
          style={{
            fontSize: "18pt",
            fontWeight: "bold",
            margin: "20px",
            marginBottom: "0px",
          }}
        >
          {user?.first_name}
        </span>
        <span style={{ fontSize: "13pt", margin: "20px", marginTop: "0px" }}>
          {user?.email}
        </span>
        <UserInfo>
          <UserInfoBox style={{ borderRight: "1px solid rgb(240,240,240)" }}>
            <span
              style={{
                fontSize: "9pt",
                color: "rgb(0,0,0,0.8)",
                fontWeight: "bold",
              }}
            >
              SEXO
      </span>
            <span style={{ marginTop: "5px", color: "rgba(0,0,0,0.6)" }}>
              {renderGender}
            </span>
          </UserInfoBox>
          <UserInfoBox
            style={{ borderRight: "1px solid rgb(240,240,240)", width: "70px" }}
          >
            <span
              style={{
                fontSize: "9pt",
                color: "rgb(0,0,0,0.8)",
                fontWeight: "bold",
              }}
            >
              IDADE
      </span>
            <span style={{ marginTop: "5px", color: "rgba(0,0,0,0.6)" }}>
              {calculateAge}
            </span>
          </UserInfoBox>
          <UserInfoBox>
            <span
              style={{
                fontSize: "9pt",
                color: "rgb(0,0,0,0.8)",
                fontWeight: "bold",
              }}
            >
              AVALIAÇÕES
      </span>
            <span style={{ marginTop: "5px", color: "rgba(0,0,0,0.6)" }}>
              {user?.rating_qty}
            </span>
          </UserInfoBox>
        </UserInfo>
        <div
          style={{ margin: "20px",marginTop: "5px",marginBottom: "5px", }}
        > <EditButton
          onClick={handleEditClicked}>
            Editar Perfil
            </EditButton>
        </div>
        <div
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            borderTop: "1px solid rgb(240,240,240)",
            borderBottom: "1px solid rgb(240,240,240)",
            display: "flex",
            margin: "20px",
            marginBottom: 10,
            flexDirection: "column",
          }}
        >
          <span
            style={{
              fontSize: "9pt",
              color: "rgb(0,0,0,0.8)",
              fontWeight: "bold",
            }}
          >
            USUÁRIO DE CADEIRA DE RODAS
    </span>
          <span style={{ marginTop: "5px", color: "rgb(0,0,0,0.6)" }}>
            {user?.wheelchair ? "Sim" : "Não"}
          </span>

          <span
            style={{
              fontSize: "9pt",
              color: "rgb(0,0,0,0.8)",
              fontWeight: "bold",
              marginTop: "10px",
            }}
          >
            TEM OUTRA DEFICIÊNCIA
    </span>
          <span style={{ marginTop: "5px", color: "rgb(0,0,0,0.6)" }}>
            {formatDisability}
          </span>

          <span
            style={{
              fontSize: "9pt",
              color: "rgb(0,0,0,0.8)",
              fontWeight: "bold",
              marginTop: "10px",
            }}
          >
            TEM AMIGO(S) COM DEFICIÊNCIA
         </span>
          <span style={{ marginTop: "5px", color: "rgb(0,0,0,0.6)" }}>
            {user?.has_friend ? "Sim" : "Não"}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              color: "rgb(0,0,0,0.7)",
              fontWeight: "bold",
            }}
          >
            Dúvidas ou sugestões?
    </span>
          <span
            onClick={handleFeedbackClicked}
            style={{
              fontSize: "12pt",
              color: "#D90368",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Envie-nos seu feedback!
    </span>
        </div>
      </ProfileModal>
    </Container>
  )
}
