import styled from "styled-components"


export const HeaderContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 55px;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  border-bottom: 1px solid rgb(240, 240, 240);
`

export const Container = styled.div`
  display: none;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  justify-content: center;
  background-color: rgba(242, 242, 242);
  padding: 10px;
  @media(max-width: 450px){
    display: flex;
  }
`

export const RateContainer = styled.div`
  display: flex;
  width: 100%;
  height: 75%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  margin-bottom: 15px;
  padding-top: 60px;
  overflow-y: auto;
`
