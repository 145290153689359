import React, { Component, useState } from "react"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import { BsCircleFill } from "react-icons/bs"
import { MdShare } from "react-icons/md"
import { RouteComponentProps } from "react-router-dom"

import Loading from "../../featureComponents/loading/LoadingScreen"
import { ApplicationState } from "../../store"
import { singleVenueRequest } from "../../store/ducks/venues/actions"
import {
  Accessibility,
  Venue,
  Comment,
} from "../../store/ducks/venues/types"
import venueImg from "../../resources/img/venue_placeholder.png"
import { Categories } from "../../store/ducks/categories/types"
import {
  AccessibilityInfos,
  CommentCard,
  CommentsContainer,
  Container,
  RateButton,
  VenueBadge,
  VenueContainer,
  VenueInfos,
  VenueSide,
  VenueCertificationBadge,
  DropDownContainer,
  ShareContainer
} from "./styles"
import MobileVenueDetail from './mobile/MobileVenueDetail'
import OutsideClickListener from '../../helpers/OutsideClickListener'

interface StateProps {
  venue: Venue
  user: any
  loading: boolean
}

interface DispatchProps {
  singleVenueRequest(
    venueId: string,
    lat: number | null,
    lng: number | null
  ): void
}

interface OwnProps {
  urlSearch: string | undefined
  categories: Categories[]
  lat: number | null
  lng: number | null
  hideDetails(): void
  showRate(showRate: boolean): void
}

interface MatchParams {
  venueId: string
}

type Props = OwnProps & DispatchProps & StateProps & RouteComponentProps<MatchParams>

const ShareDropDown = (props: any) => {
  const mailTo = `${props.user?.email}`
  const subject = `Confira ${props.venue?.name}, ${props.category} no Guiaderodas`
  const body = `
  Dá uma olhada nesse lugar que achei no Guiaderodas:\n
  ${props.venue?.name}, ${props.category}
  ${document.location.href}
  ${props.venue?.formatted_address}
  ${document.location.href} \n\n
  Baixe o aplicativo e confira mais locais. (link para https://bit.ly/appguiaderodas)
  `
  const [copied, setCopied] = useState(false)
  return (
    <OutsideClickListener
      onOutsideClick={props.onOutsideClick}
    >
      <DropDownContainer style={{
        right: 20,
        marginTop: 0,
        width: 120,
        textAlign: 'left',
      }}>
        <a
          style={{ borderBottom: "1px solid rgb(200, 200, 200)" }}
          href={`mailto:${mailTo}?subject=${subject}&body=${encodeURIComponent(body)}`}
        >E-mail</a>
        <span
          onClick={() => {
            navigator.clipboard.writeText(document.location.href).then(function () {
              console.log('Async: Copying to clipboard was successful!')
              setCopied(true)
            }, function (err) {
              console.error('Async: Could not copy text: ', err)
            })
          }}
          style={{
            borderBottom: "1px solid rgb(200, 200, 200)"
          }}
        >
          {!copied ? 'Copiar link' : 'Link copiado'}
        </span>
      </DropDownContainer>
    </OutsideClickListener>)
}

class VenueDetail extends Component<Props> {
  componentDidMount() {
    const { match: { params: { venueId } } } = this.props
    const { singleVenueRequest, lat, lng } = this.props

    if (venueId) {
      singleVenueRequest(venueId, lat, lng)
    }
  }

  state = {
    openShareModal: false
  }

  render() {
    const { venue, showRate, loading, user, urlSearch } = this.props

    return (<>
      <Container>
        <VenueSide>
          {!loading && (
            <VenueContainer>
              <ShareContainer>
                <span id='share-icon'>
                  <MdShare
                    style={{ cursor: "pointer" }}
                    size={42}
                    color={"white"}
                    onClick={() => this.setState({ openShareModal: !this.state.openShareModal })} />
                </span>
                {this.state.openShareModal && <ShareDropDown
                  category={this.getCategoryByID(venue?.category_id)}
                  venue={venue}
                  user={user}
                  onOutsideClick={() => {
                    this.setState({ openShareModal: false })
                  }}
                />}
              </ShareContainer>
              {this.venueRenderCertificationBadge(venue?.status)}
              {this.venueBadge(venue?.pin_color_code)}
              <img
                style={{ objectFit: 'cover' }}
                src={venue?.photo_url ? venue?.photo_url : venueImg}
                alt="venue"
              />
              <VenueInfos>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                >
                  <span
                    style={{
                      fontSize: "23pt",
                      fontWeight: "bold",
                      color: "rgba(0,0,0,0.8)",
                    }}
                  >
                    {venue?.name}
                  </span>
                  <span
                    style={{
                      fontSize: "15pt",
                      fontWeight: "bold",
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {this.getCategoryByID(venue?.category_id)}
                  </span>
                  <span style={{ marginTop: "10pt", color: "rgba(0,0,0,0.8)" }}>
                    {venue?.formatted_address}
                  </span>
                  <span style={{ marginTop: "10pt", color: "rgba(0,0,0,0.6)" }}>
                    {venue?.open_now ? "Aberto" : "Fechado"}
                  </span>
                  <span style={{ color: "rgba(0,0,0,0.6)" }}>
                    {this.getPrice(venue?.price)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "41%",
                    alignItems: "flex-end",
                  }}
                >
                  <RateButton onClick={() => {
                    var text = "Example text to appear on clipboard"
                    navigator.clipboard.writeText(text).then(function () {
                      console.log('Async: Copying to clipboard was successful!')
                    }, function (err) {
                      console.error('Async: Could not copy text: ', err)
                    })
                    showRate(true)
                  }}>
                    Avaliar
                  </RateButton>
                  <div
                    // href={`tel:+55${venue?.phone}`}
                    style={{ marginTop: 10, textDecoration: "none" }}
                  >
                    <span style={{ color: "rgba(0,0,0,0.6)" }}>
                      {venue?.phone}
                    </span>
                  </div>
                  {venue?.urls?.booking && <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    marginTop: 10,
                    cursor: "pointer"
                  }}
                    onClick={() => {
                      window.open(venue.urls?.booking)
                    }}>
                    <img
                      style={{ width: 24, height: 24, marginRight: 6 }}
                      alt=""
                      src={require("../../resources/icons/icon_reserva.png")}
                    />
                    <span
                      style={{ color: "rgb(74, 144, 226)", fontSize: 15 }}>Reservar
                      </span>
                  </div>}
                </div>
              </VenueInfos>
              {this.renderAccessibility(venue?.accessibility)}
              {this.renderComments(venue?.comments)}
            </VenueContainer>
          )}
        </VenueSide>
      </Container>

      <MobileVenueDetail
        {...this.props}
        urlSearch={urlSearch}
        loading={loading}
        venue={venue}
        renderCertificationBadge={this.venueRenderCertificationBadge(venue?.status)}
        renderBadge={this.venueBadge(venue?.pin_color_code)}
        renderCategory={this.getCategoryByID(venue?.category_id)}
        renderPrice={this.getPrice(venue?.price)}
        renderAccessibility={this.renderAccessibility(venue?.accessibility)}
        renderComments={this.renderComments(venue?.comments)}
        onRateClicked={() => {
          var text = "Example text to appear on clipboard"
          navigator.clipboard.writeText(text).then(function () {
            console.log('Async: Copying to clipboard was successful!')
          }, function (err) {
            console.error('Async: Could not copy text: ', err)
          })
          showRate(true)
        }}
        onBookingClicked={() => {
          window.open(venue.urls?.booking)
        }}
      />
      {loading && (
        <Loading
          height={window.innerHeight}
          title="Carregando"
          subtitle="Lugar"
        />
      )}
    </>
    )
  }

  renderComments(comments: Comment[] | undefined) {
    if (comments) {
      const picture = require("../../resources/img/user_placeholder.png")

      return (
        <CommentsContainer>
          <span>Comentários</span>
          {comments.map((comment) => {
            return (
              <CommentCard key={comment.id}>
                <img id="comment-avatar" src={picture} alt={comment.user_name} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    marginLeft: "10pt",
                  }}
                >
                  <span style={{ fontSize: "11pt" }}>{comment.user_name}</span>
                  <span
                    style={{
                      fontSize: "11pt",
                      color: "rgba(0,0,0,0.6)",
                      fontWeight: "normal",
                    }}
                  >
                    {comment.text}
                  </span>
                </div>
              </CommentCard>
            )
          })}
        </CommentsContainer>
      )
    }
  }

  renderAccessibility(accessibility: Accessibility[]) {
    if (accessibility) {
      const splitedAccessibility: Array<
        Accessibility[]
      > = this.splitAccessibility(accessibility)

      return (
        <AccessibilityInfos>
          {splitedAccessibility.map((items, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: window.innerWidth > 450 ? "row" : "column",
                  width: "100%",
                  justifyContent: "space-between",
                  marginTop: index > 0 && window.innerWidth > 450 ? 15 : 0,
                }}
              >
                {items.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: window.innerWidth <= 450 ? 15 : 0,
                        width: window.innerWidth > 450 ? "45%" : "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "13pt",
                            fontWeight: "bold",
                            color: "rgba(0,0,0,0.8)",
                          }}
                        >
                          {item.name}
                        </span>
                        <BsCircleFill
                          color={this.renderIconColor(item.pin_color_code)}
                          size={10}
                          style={{ marginLeft: 5 }}
                        />
                      </div>
                      <span
                        style={{
                          marginTop: 5,
                          fontSize: 11,
                          color: "rgba(0,0,0,0.6)",
                        }}
                      >
                        {item.description}
                      </span>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </AccessibilityInfos>
      )
    }
  }

  renderIconColor(colorCode: number) {
    switch (colorCode) {
      case -1:
        return "#7F7F7F"
      case 1:
        return "#DD352F"
      case 3:
        return "#E4BD54"
      case 5:
        return "#00A652"
    }
  }

  splitAccessibility(accessibility: Accessibility[]) {
    const splitedAccessibility: Array<Accessibility[]> = []

    let position = 0

    accessibility.forEach((item, index) => {
      if (index !== 0 && index % 2 === 0) {
        position += 1
      }

      if (splitedAccessibility[position]) {
        splitedAccessibility[position].push(item)
      } else {
        splitedAccessibility.push([item])
      }
    })

    return splitedAccessibility
  }

  getPrice(price: number) {
    switch (price) {
      case -1:
        return "Preço não informado"
      case 0:
        return "Grátis"
      case 1:
        return "$"
      case 2:
        return "$$"
      case 3:
        return "$$$"
      case 4:
        return "$$$$"
    }
  }

  getCategoryByID(categoryID: number) {
    const categories = this.props.categories
    if (categories.length) {
      const category = categories.find(
        (category) => category.category_id === categoryID
      )
      return category?.name
    } else return null
  }

  venueBadge(colorCode: number) {
    if (colorCode === -1) {
      return (
        <VenueBadge style={{ backgroundColor: "rgb(200, 200, 200)" }}>
          <span>NÃO AVALIADO</span>
        </VenueBadge>
      )
    }

    if (colorCode === 1) {
      return (
        <VenueBadge style={{ backgroundColor: "#DD352F" }}>
          <span>NÃO ACESSÍVEL</span>
        </VenueBadge>
      )
    }

    if (colorCode === 3) {
      return (
        <VenueBadge style={{ backgroundColor: "#FFDB24" }}>
          <span>PARCIALMENTE ACESSÍVEL</span>
        </VenueBadge>
      )
    }

    if (colorCode === 5) {
      return (
        <VenueBadge style={{ backgroundColor: "#00A652" }}>
          <span>ACESSÍVEL</span>
        </VenueBadge>
      )
    }
  }

  venueRenderCertificationBadge(statusCode: number) {
    const Certificado = require("../../resources/icons/Certificado.png")
    const Destaque = require("../../resources/icons/Destaque.png")
    // const EmCertificaccao = require("../../resources/icons/EmCertificacao.png")
    // if (statusCode === 101) {
    //   return (
    //     <VenueCertificationBadge color='rgb(65,68,72)'>
    //       <img
    //         id="certification-badge-image"
    //         src={EmCertificaccao}
    //         alt={"EmCertificacao"}
    //       />
    //       EM CERTIFICAÇÃO GUIADERODAS
    //     </VenueCertificationBadge>

    //   )
    // } else 
    if (statusCode === 102) {
      return (
        <VenueCertificationBadge color='rgb(217,170,72)'>
          <img
            id="certification-badge-image"
            src={Destaque}
            alt={"Destaque"}
          />
          TOP AVALIADO
        </VenueCertificationBadge>
      )
    } else if (statusCode === 103) {
      return (
        <VenueCertificationBadge>
          <img
            id="certification-badge-image"
            src={Certificado}
            alt={"Certificado"}
          />
          CERTIFICAÇÃO GUIADERODAS
        </VenueCertificationBadge>
      )
    }
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  venue: state.venues.venue,
  loading: state.venues.loading,
  user: state.user.user,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    singleVenueRequest: (
      venueId: string,
      lat: number | null,
      lng: number | null
    ) => dispatch(singleVenueRequest(venueId, lat, lng)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VenueDetail)
