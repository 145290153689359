import React, { Component } from "react"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import OutsideClickHandler from "react-outside-click-handler"

import { ApplicationState } from "../../store"
import { fetchUserRequest, logOut } from "../../store/ducks/user/actions"
import { UserInfos } from "../../store/ducks/user/types"
import Profile from "../profile/Profile"
import Feedback from "../feedback/Feedback"
import EditProfile from "../editProfile/EditProfile"
import OutsideClickListener from '../../helpers/OutsideClickListener'
import MobileHeader from "./mobile/MobileHeader"
import {
  HeaderContainer,
  Header,
  Logo,
  Navigation,
  UserIcon,
  ServicesModal,
  NavigationFirstBlock,
  NavigationSecondBlock,
} from "./styles"

interface StateProps {
  user: UserInfos | null
}

interface OwnProps {
  showAlert(): void
  showLogin(showLogin: boolean): void
  showDetails(): void
  showRate(showRate: boolean): void
  showProfileMenu: boolean
  isSearchBarOpen: boolean
  searchChanged: boolean
  showSearchBar(): void
  showMenu(): void
  handleShowProfileMenu(show: boolean): void
}

interface DispatchProps {
  fetchUserRequest(access_token: string): void
  logOut(): void
}

type Props = StateProps & DispatchProps & OwnProps

class HeaderComponent extends Component<Props> {
  state = {
    servicesModal: false,
    renderFeedback: false,
    renderEditProfile: false
  };

  componentDidMount() {
    this.fetchUser()
  }

  render() {
    const { servicesModal, renderEditProfile } = this.state
    const { showProfileMenu, user, showLogin, showDetails, showRate } = this.props

    const userLetter = user?.first_name[0]
    const token = localStorage.getItem("AUTH_DATA")
    return (
      <div>
        <HeaderContainer>
          <Header>
            <Logo>
              <img
                onClick={() => {
                  window.open('https://guiaderodas.com')
                }}
                src={require("../../resources/img/img_logo_guiaderodas.png")}
                alt="logo"
              />
            </Logo>
            <Navigation>
              <NavigationFirstBlock>
              <span
                  onClick={() => {
                    window.open('https://guiaderodas.com/quem-somos/')
                  }}>QUEM SOMOS</span>

                <span
                  style={{
                    color: window.location.pathname === '/' ? '#25914d' : '#333'
                  }}
                  onClick={() => {
                    showLogin(false)
                    showDetails()
                    showRate(false)
                  }}
                >ONDE IR</span>

                <span
                  onClick={() => {
                    window.open('https://guiaderodas.com/seja-destaque-no-portal-guiaderodas-com/')
                  }}>SEJA DESTAQUE</span>


                <span
                  onClick={() => {
                    window.open('https://guiaderodas.com/certificacao-guiaderodas/')
                  }}>CERTIFICAÇÃO</span>

                <span
                  onClick={() => {
                    window.open('https://guiaderodas.com/treinamento/')
                  }}>TREINAMENTO</span>

              </NavigationFirstBlock>
              <NavigationSecondBlock>

                <span
                  onClick={() => {
                    window.open('https://guiaderodas.com/ideiaboaparatodos/')
                  }}>BLOG IDEIA BOA</span>

                <span
                  onClick={() => {
                    window.open('https://guiaderodas.com/contato/')
                  }}>CONTATO</span>


                {token ? (
                  <UserIcon
                    onClick={() => {
                      this.setState({ renderFeedback: false })
                      this.props.handleShowProfileMenu(!showProfileMenu)
                    }
                    }
                  >
                    <span>{userLetter ? userLetter : "D"}</span>
                  </UserIcon>
                ) : (
                  <span onClick={() => showLogin(true)}>LOGIN</span>
                )}
              </NavigationSecondBlock>
            </Navigation>
          </Header>
        </HeaderContainer>
        <MobileHeader
          searchChanged={this.props.searchChanged}
          isSearchBarOpen={this.props.isSearchBarOpen}
          showSearchBar={() => this.props.showSearchBar()}
          showMenu={() => this.props.showMenu()}
        />
        { showProfileMenu && this.profileModal()}
        { servicesModal && this.renderServicesModal()}
        { renderEditProfile && this.renderEditProfile()}
      </div >
    )
  }

  changeModal(renderEditProfile: boolean, renderProfile: boolean, renderFeedback: boolean) {
    this.props.handleShowProfileMenu(renderProfile)
    this.setState({ renderEditProfile, renderFeedback })
  }

  profileModal() {
    const { renderEditProfile, renderFeedback } = this.state
    if (renderFeedback) {
      return this.renderFeedback()
    } else if (this.props.showProfileMenu) {
      return this.renderProfile()
    } else if (renderEditProfile) {
      return this.renderEditProfile()
    }
  }

  renderEditProfile() {
    const { user } = this.props

    return (
      <OutsideClickHandler
        onOutsideClick={() => this.setState({ renderEditProfile: false })}
      >
        <EditProfile
          user={user}
          changeModal={(renderEditProfile: boolean, renderProfile: boolean, renderFeedback: boolean) =>
            this.changeModal(renderEditProfile, renderProfile, renderFeedback)
          }
        />
      </OutsideClickHandler>
    )
  }

  renderFeedback() {
    const { user } = this.props

    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          this.setState({ renderFeedback: false })
          this.props.handleShowProfileMenu(false)
        }
        }
      >
        <Feedback
          user={user}
          changeModal={(renderEditProfile: boolean, renderProfile: boolean, renderFeedback: boolean) =>
            this.changeModal(renderEditProfile, renderProfile, renderFeedback)
          }
          showAlert={() => this.props.showAlert()}
        />
      </OutsideClickHandler >
    )
  }

  renderProfile() {
    const { user } = this.props

    return (
      <OutsideClickListener
        onOutsideClick={() => {
          this.props.handleShowProfileMenu(false)
        }}
      >
        <Profile
          user={user}
          changeModal={(renderEditProfile: boolean, renderProfile: boolean, renderFeedback: boolean) =>
            this.changeModal(renderEditProfile, renderFeedback, renderProfile)
          }
        />
      </OutsideClickListener>
    )
  }


  renderServicesModal() {
    return (
      <OutsideClickHandler
        onOutsideClick={() => this.setState({ servicesModal: false })}
      >
        <ServicesModal>
          <span style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
            LISTA
          </span>
          <span style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
            CADASTRO
          </span>
          <span>SUGESTÃO</span>
        </ServicesModal>
      </OutsideClickHandler>
    )
  }

  fetchUser() {
    const token = localStorage.getItem("AUTH_DATA")

    if (token) {
      const { fetchUserRequest } = this.props

      fetchUserRequest(token)
    }
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.user,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchUserRequest: (access_token: string) =>
      dispatch(fetchUserRequest(access_token)),
    logOut: () => dispatch(logOut()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)
