import styled from "styled-components";


export const MobileContainer = styled.div`
  display: none; 
  @media(max-width: 450px){ 
    display: flex; 
    width: 100%;
    height: 100%;
  }
`

export const Container = styled.div`
  display: flex;
  z-index: 6;
  position: fixed;
  justify-content: center;
  align-items: center;

  @media(max-width: 450px){
    width: 100vw;
    height: 100vh;
  }
`

export const DateGenderContainer = styled.div`
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: space-between;
  margin-top: 17px;
`;

export const EditProfileModal = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  position: fixed;
  z-index: 6;
  background-color: white;
  right: 1.9%;
  top: 75px;
  width: 376px;
  height: 680px;
  border: 2px solid rgb(0, 0, 0, 0.05);
 
  @media(max-width: 450px){
    display: none;
  }
  @media(max-height: 500px){
    height: 350px;
    overflow: scroll;
  }
  @media(max-height: 600px) and (min-height: 500px){
    height: 430px;
    overflow: scroll;
  }
  @media(max-height: 700px) and (min-height: 600px){
    height: 530px;
    overflow: scroll;
  }
`;

export const Input = styled.input`
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;
  background-color: rgb(249, 249, 249);
  color: rgb(0, 0, 0);
  font-size: 16px;
`;


export const EditProfileContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  border-top: 1px solid rgb(240, 240, 240);
  border-bottom: 1px solid rgb(240, 240, 240);

  span {
    color: rgb(0, 0, 0);
    font-size: 12px;
  }
`;

export const Header = styled.div`
  width: 100px;
  height: 10px;
  background-color: rgb(0, 187, 55);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  position: fixed;

  p {
    margin-left: 20px;
    font-size: 18px;
    font-weight: bold;
    color: white;
  }
`;

export const Body = styled.div`
  display: flex;
  height: 90px;
  flex-direction: column;
  padding-top: 50px;
  padding-right: 100px;
  padding-left: 100px;
  padding-bottom: 5px;
  overflow-y: scroll;
  justify-content: center;
  align-items: center;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const SendButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 470px;
  height: 40px;
  padding: 7px;
  background-color: #d90368;
  border-radius: 5px;
  margin-top: 30px;
  cursor: pointer;

  span {
    color: white;
    font-size: 16px;
  }
`;

export const ErrorMessage = styled.span`
  color: red;
  margin-top: 5px;
`;


export const DropdownButton = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
  margin-top: 5px;
  justify-content: space-between;
  align-items: center;

  span {
    color: rgb(0, 0, 0);
    font-size: 16px;
  }
`;

export const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
  position: absolute;
  z-index: 2;

  span {
    padding: 10px;
    cursor: pointer;

    :hover {
      background-color: rgb(225, 225, 225);
    }
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid rgb(200, 200, 200);
    padding-left: 10px;

    :hover {
      background-color: rgb(225, 225, 225);
    }
  }
`;

export const ContainerAlert = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  overflow: hidden;
  z-index: 3;
  width: 376px;
  height: 680px;

  @media(max-height: 500px){
    height: 350px;
    overflow: scroll;
  }
  @media(max-height: 600px) and (min-height: 500px){
    height: 430px;
    overflow: scroll;
  }
  @media(max-height: 700px) and (min-height: 600px){
    height: 530px;
    overflow: scroll;
  }
`;

export const Alert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  height: 20%;
  background-color: white;
  border-radius: 10px;
  padding: 12px;
`;

export const Title = styled.span`
  font-size: 25px;
  font-weight: bold;
`;

export const Message = styled.span`
  font-size: 20px;
`;

export const Button = styled.button`
  font-size: 20px;
  background-color: white;
  border: 0px none;
  cursor: pointer;
`;

