export enum LoginTypes {
  LOGIN_REQUEST = "@login/LOGIN_REQUEST",
  LOGIN_SUCCESS = "@login/LOGIN_SUCCESS",
  LOGIN_FAILURE = "@login/LOGIN_FAILURE",
  LOGIN_RESET_STATE = "@login/LOGIN_RESET_STATE",
}

export enum LogoutTypes {
  LOGOUT_REQUEST = "@logout/LOGOUT_REQUEST",
}

export enum SignUpTypes {
  SIGNUP_REQUEST = "@signup/SIGNUP_REQUEST",
  SIGNUP_SUCCESS = "@signup/SIGNUP_SUCCESS",
  SIGNUP_FAILURE = "@signup/SIGNUP_FAILURE",
  SIGNUP_RESET_STATE = "@signup/SIGNUP_RESET_STATE",
}

export enum RecoverTypes {
  RECOVER_REQUEST = "@recover/RECOVER_REQUEST",
  RECOVER_SUCCESS = "@recover/RECOVER_SUCCESS",
  RECOVER_FAILURE = "@recover/RECOVER_FAILURE",
}

export enum FetchUserTypes {
  FETCH_USER_REQUEST = "@fetch/FETCH_USER_REQUEST",
  FETCH_USER_SUCCESS = "@fetch/FETCH_USER_SUCCESS",
  FETCH_USER_FAILURE = "@fetch/FETCH_USER_FAILURE",
}

export enum UpdateTypes {
  USER_UPDATE_REQUEST = "@update/USER_UPDATE_REQUEST",
  USER_UPDATE_SUCCESS = "@update/USER_UPDATE_SUCCESS",
  USER_UPDATE_FAILURE = "@update/USER_UPDATE_FAILURE",
  USER_ACCEPT_PRIVACY_POLICY_REQUEST = "@update/USER_ACCEPT_PRIVACY_POLICY_REQUEST",
  USER_ACCEPT_PRIVACY_POLICY_SUCCESS = "@update/USER_ACCEPT_PRIVACY_POLICY_SUCCESS",
  USER_ACCEPT_PRIVACY_POLICY_FAILURE = "@update/USER_ACCEPT_PRIVACY_POLICY_FAILURE",
}

export interface User {
  first_name?: string
  last_name?: string
  privacy_policy_accepted?: boolean
  email: string
  password: string
}

export interface Token {
  access_token: string
  refresh_token: string
  token_type?: string
  expires_in?: number
}

export interface UserInfos {
  birthday: string
  created_at: string
  email: string
  first_name: string
  gender: string
  has_friend: boolean
  id: string
  last_name: string
  locale: string
  other_disability: string
  rating_qty: number
  token: Token
  wheelchair: boolean
  privacy_policy_accepted: string
}

export interface UserState {
  readonly logged: boolean
  readonly registered: boolean
  readonly sent: boolean
  readonly loading: boolean
  readonly error: boolean
  readonly errorMessage: string
  readonly tokens: Token | null
  readonly user: UserInfos | null
}
