import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
`

export const Container = styled.div`
  display: none;
  background-color: white;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  @media(max-width: 450px) {
    display: flex;
  }
`;

export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  img {
    width: 50px;
    height: 70px;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26vw;
    min-width: 304.41px;
    height: 6vh;
    border: 0px none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
  }
`;
   
export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding: 12px;
  margin-top: 20px;
  input[type=checkbox] {
    transform: scale(1.5);
    margin-right: 10px
  }
`;

export const Input = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.4);
  padding: 5px;
  background-color: white;
  margin-top: 20px;

  input {
    border: 0px none;
    font-size: 14pt;
    margin-left: 4px;
    width: 100%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px;
  margin-top: 30px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37%;
  height: 40px;
  border-radius: 5px;
  background-color: #d90368;
  font-size: 14pt;
  color: white;
  border: 0px none;
  cursor: pointer;

  :disabled {
    background-color: rgba(214, 8, 107, 0.4);
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 35%;

  span {
    color: red;
    font-size: 20pt;
  }
`;
