import React, { Component } from "react"
import {
  AiOutlineCloseCircle,
  // AiFillApple,
} from "react-icons/ai"

import Loading from "../loading/LoadingScreen"
import Alert from "../../featureComponents/alert/Alert"
import bgImage from "../../resources/img/venue_placeholder.png"
import pin from "../../resources/icons/PIN-Verde.png"
import { ApplicationState } from "../../store"
import { Dispatch } from "redux"
import { recoverPasswordRequest } from "../../store/ducks/user/actions"

import { connect } from "react-redux"
import { LoginContainer, MainContainer, CloseContainer, ButtonsContainer, Button, ErrorMessage } from "./styles"


interface OwnProps {
  showLogin(show: boolean): void
  showRecover(show: boolean): void
}

interface StateProps {
  sent: boolean
  loading: boolean
  error: boolean
}


interface DispatchProps {
  recoverPasswordRequest(email: string): void
}

type Props = OwnProps & StateProps & DispatchProps

class Recover extends Component<Props> {
  state = {
    email: "",
    showAlert: false,
  };

  render() {
    
    const { loading, sent,error } = this.props
    const { showAlert } = this.state

    console.log('sent:',sent)
    console.log('showAlert:',showAlert)

    return (<MainContainer>

      <div
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          borderRadius: "4pt",
        }}
      >
        <LoginContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "30pt",
            }}
          >
            <CloseContainer>
              <AiOutlineCloseCircle
                onClick={() => this.closeRecover()}
                size={35}
                color="white"
              />
            </CloseContainer>

            <img src={pin} alt="pin" />
            <span
              style={{
                fontSize: "20pt",
                fontWeight: "bold",
                color: "rgba(0,0,0,0.8)",
                marginLeft: "10pt",
              }}
            >
              guiaderodas
              </span>
          </div>

          <div
            style={{
              textAlign: "center",
              width: "25vw",
              minWidth: "285.58px",
              marginBottom: "30pt",
            }}
          >
            <span
              style={{
                fontSize: "14pt",
                fontWeight: "bold",
                color: "rgba(0,0,0,0.8)",
              }}
            >
              Digite seu email e clique em 'Recuperar' para receber as instruções.
              </span>
          </div>

          <input
            type="email"
            placeholder="email@email.com"
            onChange={this.saveEmail.bind(this)}
            style={{ marginBottom: "10pt" }}
          />
           {error && (
                <ErrorMessage>
                  <span>Email não cadastrado.</span>
                </ErrorMessage>
              )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "25vw",
              marginBottom: "40pt",
            }}
          >
          </div>

          <ButtonsContainer>
              <Button
                disabled={this.buttonDisabled()}
                onClick={() => this.recoverPassword()}
              >
                Recuperar
            </Button>
            </ButtonsContainer>
         
        </LoginContainer>
      </div>


      {loading && <Loading height={window.innerHeight} title="Enviando" subtitle="Email" />}
      {sent && showAlert && (
          <Alert
            height={window.innerHeight}
            title="Recuperar Senha!"
            message="Email enviado com sucesso."
            textButton="OK"
            onOKClicked={() => this.confirmAlert()}
          />
        )}

    </MainContainer>)
  }

  saveEmail(event: any) {
    this.setState({ email: event.target.value })
  }

  confirmAlert() {

    this.setState({ showAlert: false })
    this.closeRecover()
  }

  closeRecover() {
    const { showLogin, showRecover } = this.props
    showRecover(false); 
    showLogin(true)
  }

  recoverPassword() {
    const { recoverPasswordRequest } = this.props
    const { email } = this.state

    this.setState({ showAlert: true })

    recoverPasswordRequest(email)
  }

  buttonDisabled() {
    const { email } = this.state

    if (email.trim().length > 0) {
      return false
    }

    return true
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  sent: state.user.sent,
  loading: state.user.loading,
  error: state.user.error,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    recoverPasswordRequest: (email: string) =>
      dispatch(recoverPasswordRequest(email)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recover)
