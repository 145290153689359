export enum ServiceTypes {
    SERVICE_REQUEST = "@service/SERVICE_REQUEST",
    SERVICE_SUCCESS = "@service/SERVICE_SUCCESS",
    SERVICE_FAILURE = "@service/SERVICE_FAILURE",
}

export enum RequisitionTypes {
    REQUISITION_REQUEST = "@requisition/REQUISITION_REQUEST",
    REQUISITION_SUCCESS = "@requisition/REQUISITION_SUCCESS",
    REQUISITION_FAILURE = "@requisition/REQUISITION_FAILURE",
}

export interface Service {
    name?: string;
    description?: string;
    area?: string;
    category?: string;
    state?: string;
    city?: string;
    address?: string;
}

export interface Requisition {
    user_id: string;
    user_email: string;
    user_name: string;
    subject: string;
    content: string;
    email_for_feedback: string;
}

export interface ServiceState {
    readonly serviceSent: boolean;
    readonly requisitionSent: boolean;
    readonly loading: boolean;
    readonly error: boolean;
}
