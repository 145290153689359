import { action } from "typesafe-actions";
import { ServiceTypes, RequisitionTypes, Service, Requisition } from "./types";

export const sendServiceRequest = (service: Service, token: string | null) => ({
  type: ServiceTypes.SERVICE_REQUEST,
  service,
  token,
});

export const sendServiceSuccess = (status: number) =>
  action(ServiceTypes.SERVICE_SUCCESS, status);

export const sendServiceFailure = () => action(ServiceTypes.SERVICE_FAILURE);

export const sendRequisitionRequest = (requisition: Requisition, token: string | null) => ({
  type: RequisitionTypes.REQUISITION_REQUEST,
  requisition,
  token,
});

export const sendRequisitionSuccess = (status: number) =>
  action(RequisitionTypes.REQUISITION_SUCCESS, status);

export const sendRequisitionFailure = () => action(RequisitionTypes.REQUISITION_FAILURE);
