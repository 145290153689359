export enum VenuesTypes {
    VENUES_REQUEST = "@venues/VENUES_REQUEST",
    VENUES_SUCCESS = "@venues/VENUES_SUCCESS",
    VENUES_FAILURE = "@venues/VENUES_FAILURE",
    SINGLE_VENUE_REQUEST = "@venues/SINGLE_VENUE_REQUEST",
    SINGLE_VENUE_SUCCESS = "@venues/SINGLE_VENUE_SUCCESS",
    SINGLE_VENUE_FAILURE = "@venues/SINGLE_VENUE_FAILURE",
}

export enum SearchTypes {
    START_LOADER = "@venues/START_LOADER",
    SEARCH_REQUEST = "@venues/SEARCH_REQUEST",
    SEARCH_SUCCESS = "@venues/SEARCH_SUCCESS",
    SEARCH_FAILURE = "@venues/SEARCH_FAILURE",
}

export interface Venue {
    address: string;
    category_id: number;
    distance: number;
    formatted_address: string;
    name: string;
    open_now: boolean;
    photo_url: string;
    pin_color_code: number;
    price: number;
    status: number;
    venue_id: string;
    accessibility: Accessibility[];
    phone?: string;
    coords?: Coords;
    comments?: Comment[];
    urls?: any;
}

export interface Accessibility {
    name: string;
    pin_color_code: number;
    description?: string;
}

export interface Coords {
    lat: string;
    lng: string;
}

export interface Comment {
    id: number;
    user_name: string;
    user_id: string;
    created_at: string;
    text: string;
}

export interface VenuesState {
    readonly venue: any;
    readonly data: any;
    readonly loading: boolean;
    readonly error: boolean;
}
