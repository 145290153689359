export enum LocationsTypes {
    STATES_REQUEST = "@locations/STATES_REQUEST",
    STATES_SUCCESS = "@locations/STATES_SUCCESS",
    STATES_FAILURE = "@locations/STATES_FAILURE",
    CITIES_REQUEST = "@locations/CITIES_REQUEST",
    CITIES_SUCCESS = "@locations/CITIES_SUCCESS",
    CITIES_FAILURE = "@locations/CITIES_FAILURE",
}

export interface State {
    short: string;
    name: string
}

export interface LocationsState {
    readonly states: any;
    readonly cities: any;
    readonly loadingStates: boolean;
    readonly loadingCities: boolean;
    readonly error: boolean;
}