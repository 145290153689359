import { ServiceState, ServiceTypes, RequisitionTypes } from "./types";
import { Reducer } from "redux";

const INITIAL_STATE: ServiceState = {
  serviceSent: false,
  requisitionSent: false,
  error: false,
  loading: false,
};

const reducer: Reducer<ServiceState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ServiceTypes.SERVICE_REQUEST:
      return { ...state, loading: true };
    case ServiceTypes.SERVICE_SUCCESS:
      return { ...state, loading: false, error: false, serviceSent: action.payload };
    case ServiceTypes.SERVICE_FAILURE:
      return { ...state, loading: false, error: true, serviceSent: false };
    case RequisitionTypes.REQUISITION_REQUEST:
      return { ...state, loading: true };
    case RequisitionTypes.REQUISITION_SUCCESS:
      return { ...state, loading: false, error: false, requisitionSent: action.payload };
    case RequisitionTypes.REQUISITION_FAILURE:
      return { ...state, loading: false, error: true, requisitionSent: false };
    default:
      return state;
  }
};

export default reducer;
