import { VenuesState, VenuesTypes, SearchTypes } from "./types";
import { Reducer } from "redux";

const INITIAL_STATE: VenuesState = {
  venue: null,
  data: [],
  error: false,
  loading: false,
};

const reducer: Reducer<VenuesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VenuesTypes.VENUES_REQUEST:
      return { ...state, loading: true };
    case VenuesTypes.VENUES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case VenuesTypes.VENUES_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    case SearchTypes.START_LOADER:
      return { ...state, loading: true };
    case SearchTypes.SEARCH_REQUEST:
      return { ...state, loading: true };
    case SearchTypes.SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case SearchTypes.SEARCH_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    case VenuesTypes.SINGLE_VENUE_REQUEST:
      return { ...state, loading: true };
    case VenuesTypes.SINGLE_VENUE_SUCCESS:
      return {
        ...state,
        loading: false,
        venue: action.payload.data,
        error: false,
      };
    case VenuesTypes.SINGLE_VENUE_FAILURE:
      return { ...state, loading: false, error: true, venue: null };
    default:
      return state;
  }
};

export default reducer;
