import { BeatLoader } from "react-spinners"
import React, { Component } from "react"

import { Container } from "./styles"

interface OwnProps {
  title: string
  subtitle: string
  height?: number
}

type Props = OwnProps

export default class Loading extends Component<Props> {
  render() {
    return (
      <Container height={this.props.height}>
        <BeatLoader color="white" loading={true} size={20} />
        <h1>{this.props.title}</h1>
        <span>{this.props.subtitle}...</span>
      </Container>
    )
  }
}
