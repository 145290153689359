import styled from "styled-components";

interface Props {
  state?: string;
  city?: string;
  category?: string;
  height?: number;
}

export const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  padding-right: 12pt;
  padding-left: 12pt;
  padding-top: 14pt;
  padding-bottom: 14pt;
  background-color: white;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.1);
`;

export const FilterDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const State = styled.div`
  display: flex;
  width: 20%;
  justify-content: space-between;
  align-items: center;
  border: ${(props: Props) =>
    props.state === "UF" ? "1px solid rgb(204, 204, 204)" : "0px none"};
  border-radius: 4pt;
  background-color: ${(props: Props) =>
    props.state === "UF" ? "rgb(247, 247, 247)" : "rgb(77, 208, 115)"};
  padding: 8pt;
  padding-left: 12pt;
  padding-right: 12pt;

  span {
    font-size: 10pt;
    color: ${(props: Props) =>
      props.state === "UF" ? "rgba(0, 0, 0, 0.4)" : "white"};
  }
`;

export const City = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-left: 16pt;
  border: ${(props: Props) =>
    props.city === "CIDADE" ? "1px solid rgb(204, 204, 204)" : "0px none"};
  border-radius: 4pt;
  background-color: ${(props: Props) =>
    props.city === "CIDADE" ? "rgb(247, 247, 247)" : "rgb(77, 208, 115)"};
  padding: 8pt;
  padding-left: 12pt;
  padding-right: 12pt;

  span {
    font-size: 10pt;
    color: ${(props: Props) =>
      props.city === "CIDADE" ? "rgba(0, 0, 0, 0.4)" : "white"};
  }
`;

export const Category = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-top: 12pt;
  border: ${(props: Props) =>
    props.category === "CATEGORIA"
      ? "1px solid rgb(204, 204, 204)"
      : "0px none"};
  border-radius: 4pt;
  background-color: ${(props: Props) =>
    props.category === "CATEGORIA"
      ? "rgb(247, 247, 247)"
      : "rgb(77, 208, 115)"};
  padding: 8pt;
  padding-left: 12pt;
  padding-right: 12pt;

  span {
    font-size: 10pt;
    color: ${(props: Props) =>
      props.category === "CATEGORIA" ? "rgba(0, 0, 0, 0.4)" : "white"};
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex: 1;
  width: auto;
`;

export const Modal = styled.div`
  display: flex;
  flex: 1;
  padding: 10pt;
  flex-direction: column;
  background-color: white;
  border: 1px solid black;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  span {
    font-size: 18pt;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.77);
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: static;
  background-color: white;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  display: flex;
  height: auto;
  justify-content: space-between;
`;

export const Search = styled.div`
  display: flex;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 12pt;
  padding-top: 10pt;
  padding-bottom: 10pt;
  padding-left: 15pt;
  padding-right: 15pt;

  input {
    width: 100%;
    margin-left: 11pt;
    border: 0 none;
    font-size: 14pt;
  }
`;

export const List = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 10pt;

  div {
    display: flex;
    flex: 1;
    max-height: ${(props: Props) => props.height !== undefined ? `${props.height}px` : "200px"};
    flex-direction: column;
    padding-left: 14pt;
    overflow: auto;

    span {
      font-size: 12pt;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.35);
      margin-bottom: 7pt;
      cursor: pointer;
    }
  }
`;
