import styled from "styled-components"


export const HeaderContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 55px;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  border-bottom: 1px solid rgb(240, 240, 240);
`

export const Container = styled.div`
  display: none;
  height: 100vh;
  z-index: 5;
  justify-content: center;
  background-color: rgba(242, 242, 242);
  @media(max-width: 450px){
    display: flex;
  }
`


export const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 10px;
  padding-top: 60px;

  span {
    color: rgba(0, 0, 0, 0.7);
    font-size: 20pt;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`;

export const Input = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid rgb(200,200,200);
  border-radius: 4px;
  padding: 10px;
  background-color: white;
  margin-top: 20px;
  width:66%;

  input {
    border: 0px none;
    font-size: 12pt;
    margin-left: 4px;
    width: 100%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  padding: 12px;
  margin-top: 30px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: #d90368;
  font-size: 14px;
  color: white;
  border: 0px none;
  cursor: pointer;

  :disabled {
    background-color: rgba(214, 8, 107, 0.4);
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 35%;

  span {
    color: red;
    font-size: 20px;
  }
`;
