import { call, put } from "redux-saga/effects"

import api from "../../../services/api"
import {
  loginSuccess,
  loginFailure,
  signUpSuccess,
  signUpFailure,
  fetchUserSuccess,
  fetchUserFailure,
  userUpdateSuccess,
  userUpdateFailure,
  recoverPasswordSuccess,
  recoverPasswordFailure,
  userAcceptPrivacyPolicySuccess,
  userAcceptPrivacyPolicyFailure
} from "./actions"

interface Body {
  username?: string
  password?: string
  access_token?: string
  grant_type?: string
}

export function* login(action: any) {
  try {
    let body: Body = {}
    switch (action.grant_type) {
      case "password":
        body.username = action.user.email
        body.password = action.user.password
        body.grant_type = action.grant_type
        break
      case "refresh_token":
        break
      case "facebook":
        body.access_token = action.access_token
        body.grant_type = action.grant_type
        break
    }

    const response = yield call(
      api.post,
      "/auth/login?locale=pt-br",
      JSON.stringify(body)
    )
    yield put(loginSuccess(response.data))
  } catch (err) {
    yield put(loginFailure())
  }
}

export function* signUp(action: any) {
  try {
    const response = yield call(
      api.post,
      "/users?locale=pt",
      JSON.stringify(action.user)
    )
    yield put(signUpSuccess(response.data))
  } catch (err) {
    yield put(signUpFailure(err?.response?.data?.message))
  }
}

export function* recover(action: any) {
  try {
    const response = yield call(
      api.post,
      "/auth/pwd_reset",
      JSON.stringify({ email: action.email })
    )
    yield put(recoverPasswordSuccess(response.data))
  } catch (err) {
    yield put(recoverPasswordFailure())
  }
}

export function* fetchUser(action: any) {
  try {
    const response = yield call(api.get, "/me", {
      headers: {
        Authorization: "Bearer " + action.access_token,
      },
    })

    yield put(fetchUserSuccess(response.data))
  } catch (err) {
    yield put(fetchUserFailure())
  }
}

export function* updateUser(action: any) {
  try {
    const token = localStorage.getItem("AUTH_DATA")

    const response = yield call(api.put, "/me", JSON.stringify(action.userInfo), {
      headers: {
        Authorization: "Bearer " + token,
      },
    })

    yield put(userUpdateSuccess(response.data))
  } catch (err) {
    yield put(userUpdateFailure())
  }
}

export function* acceptPrivacyPolicy(action: any) {
  try {
    const token = localStorage.getItem("AUTH_DATA")

    const response = yield call(api.post, "/me/privacy_policy", null, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })

    yield put(userAcceptPrivacyPolicySuccess(response.data))
  } catch (err) {
    yield put(userAcceptPrivacyPolicyFailure())
  }
}
