import styled from "styled-components";

interface Props {
  height?: number;
  width?: number;
}

export const Container = styled.div`
  height: ${(props: Props) => props.height? `${props.height}px`: '100%'};
  width: ${(props: Props) => props.width? `${props.width}px`: '100%'};
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  overflow: hidden;
  z-index: 3;
`;

export const Alert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  height: 20%;
  background-color: white;
  border-radius: 10px;
  padding: 12px;

  @media(max-width: 770px){
    width: 70%;
    height: 55%;
    overflow: scroll;
  }
`;

export const Title = styled.span`
  font-size: 25px;
  font-weight: bold;
`;

export const Message = styled.span`
  font-size: 20px;
`;

export const Button = styled.button`
  font-size: 20px;
  background-color: white;
  border: 0px none;
  cursor: pointer;
`;
