import styled from "styled-components";

interface Props {
  height?: number;
}

export const Container = styled.div`
  height: ${(props: Props) => `${props.height}px`};
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  overflow: hidden;
  z-index: 7;

  h1 {
    font-family: Helvetica;
    font-size: 16pt;
    font-weight: bold;
    color: white;
    margin-top: 10pt;
  }

  span {
    font-family: Helvetica;
    font-size: 12pt;
    color: white;
  }
`;
