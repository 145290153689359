import styled from "styled-components";

export const MainContainer = styled.div`
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgb(247, 247, 247);
  justify-content: center;
  align-items: center;

  @media(max-width: 450px) {
    display: flex;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 55px;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  border-bottom: 1px solid rgb(240, 240, 240);
`


export const VenueContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: white;
  height: max-content;

  img {
    width: 100%;
    height: 318px;
    object-fit: cover;
  }

  @media(max-width: 320px) {
    width: 320px;
  }
`;

export const VenueCertificationBadge = styled.div`
  display: flex;
  background-color: ${props => props.color ? props.color: 'rgb(21, 111, 179)'};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: white;
  padding-left: 25px;
  padding-right: 10px;
  font-size: 12pt;
  font-weight: bold;
  margin-left: -5px;
  margin-top: 205px;
  border-radius: 3px;
  position: absolute;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
`;


export const VenueBadge = styled.div`
  display: flex;
  justify-content: flex-end;
  color: white;
  padding: 4px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 250px;
  font-size: 18pt;
  font-weight: bold;
  margin-left: -5px;
  margin-top: 245px;
  border-radius: 3px;
  position: absolute;
`;

export const VenueInfos = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const RateButton = styled.button`
  width: 100%;
  margin-top: 25px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  border: 0px none;
  background-color: #d90368;
  font-size: 14pt;
  border-radius: 4px;
  cursor: pointer;
`;

export const AccessibilityInfos = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 20px;
  margin-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(240, 240, 240);
  border-top: 1px solid rgb(240, 240, 240);
`;

export const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;

  span {
    font-size: 13pt;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
  }

  @media(max-width: 330px) {
    max-width: 200px;
  }
  @media(max-width: 760px) and (min-width: 600px)  {
    max-width: 500px;
  }
  @media(max-width: 600px) and (min-width: 500px)  {
    max-width: 380px;
  }
  @media(max-width: 500px) and (min-width: 400px)  {
    max-width: 280px;
  }
  @media(max-width: 400px) and (min-width: 330px)  {
    max-width: 260px;
  }
`;

export const CommentCard = styled.div`
  display: flex;
  width: 88%;
  flex-direction: row;
  padding: 10pt;
  margin-top: 5pt;
  background-color: rgb(247, 247, 247);
  border-radius: 4pt;

  img {
    width: 50pt;
    height: 50pt;
    border-radius: 50%;
    border: 1px solid #d90368;
  }
`;
