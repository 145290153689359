import { combineReducers } from "redux";

import categories from "./categories";
import companies from "./companies";
import feedback from "./feedback";
import locations from "./locations";
import service from "./service";
import review from "./review";
import venues from "./venues";
import user from "./user";

export default combineReducers({
  categories,
  locations,
  companies,
  feedback,
  service,
  review,
  venues,
  user,
});
