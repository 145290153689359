import React, { Component } from "react"

import { Container, Alert, Title, Message, Button } from "./styles"

interface OwnProps {
  onAcceptTerms(): void
}

type Props = OwnProps

export default class ModalPrivacyPolicies extends Component<Props> {
  state: {
    accepted: boolean
  } = {
      accepted: false
    }

  render() {
    const { onAcceptTerms } = this.props

    return (
      <Container>
        <Alert>
          <Title>Política de Privacidade Guiaderodas</Title>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
            <input
              type="checkbox"
              name="terms"
              onChange={(event: any) => {
                this.setState({ accepted: event.target.checked })
              }} />
            <label style={{
            fontSize: 16
          }}>Li e concordo com os termos da politica de Privacidade Guiaderodas</label><br />
          </div>
          <Message style={{
            fontSize: 16,
            fontStyle: 'italic'
          }}>
            Caso os termos da nossa Política de Privacidade sejam inaceitáveis para você,
            você não terá mais acesso a esse aplicativo.
          </Message>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={() => {
              window.open('https://guiaderodas.com/politica-de-privacidade')
            }}>Ler os Termos</Button>
            <Button disabled={!this.state.accepted} onClick={onAcceptTerms}>Aceitar os Termos</Button>
          </div>
        </Alert>
      </Container>
    )
  }
}
