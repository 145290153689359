import { GoogleMap, LoadScript } from "@react-google-maps/api";
import React, { Component } from "react";
import { MdMail } from "react-icons/md";
import { Link } from "react-router-dom";
import {
  FaLocationArrow,
  FaGlobe,
  FaPhoneAlt,
  FaWhatsapp,
  FaArrowLeft,
} from "react-icons/fa";

import {
  Company,
  Info,
  Contact,
  Coords,
} from "../../../store/ducks/companies/types";
import {
  Container,
  Header,
  Body,
  Card,
  Name,
  State,
  City,
  Category,
  Title,
  DetailsCard,
  Address,
  LocationCard,
  MapContainer,
} from "./styles";

interface OwnProps {
  location: { state: Company };
}

type Props = OwnProps;

export default class CatalogDetails extends Component<Props> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const company = this.props.location.state;
    const { address, email, phone, website, whatsapp } = company.contact;
    const { area, description, other_info } = company.info;

    return (
      <Container>
        <Header>
          <Link
            to="/catalog"
            style={{
              textDecoration: "none",
              display: "flex",
            }}
          >
            <FaArrowLeft color="white" size={20} />
          </Link>
          <p>{company.name}</p>
        </Header>
        <div style={{ backgroundColor: "rgb(0, 187, 55)", height: 60 }} />
        <Body>
          <Card>
            <div>
              <Name>{company.name}</Name>
              <State>{company.state}</State>
            </div>
            <Category>{company.category_name}</Category>
            <City>{company.city}</City>
          </Card>
          {this.renderDetails(company.info)}
          {this.renderDescription(description)}
          {this.renderArea(area)}
          {this.renderOtherInfo(other_info)}
          {this.renderLocation(company.contact)}
          {this.renderMap(company.coords)}
          {this.renderAddress(address, company.name)}
          {this.renderWebsite(website)}
          {this.renderEmail(email)}
          {this.renderPhone(phone)}
          {this.renderWhatsapp(whatsapp)}
        </Body>
      </Container>
    );
  }

  renderDetails(info: Info): JSX.Element | undefined {
    const { area, description, other_info } = info;

    if (
      area.trim().length > 0 ||
      description.trim().length > 0 ||
      other_info.trim().length > 0
    ) {
      return <Title>Detalhes e Informações</Title>;
    }
  }

  renderDescription(description: string): JSX.Element | undefined {
    if (description.trim().length > 0) {
      return (
        <DetailsCard borderRadiusTop={true}>
          <h2>Descrição</h2>
          <span>{description}</span>
        </DetailsCard>
      );
    }
  }

  renderArea(area: string): JSX.Element | undefined {
    if (area.trim().length > 0) {
      return (
        <DetailsCard>
          <h2>Área de atuação</h2>
          <span>{area}</span>
        </DetailsCard>
      );
    }
  }

  renderOtherInfo(other_info: string): JSX.Element | undefined {
    if (other_info.trim().length > 0) {
      return (
        <DetailsCard borderRadiusBottom={true}>
          <h2>Outras informações</h2>
          <span>{other_info}</span>
        </DetailsCard>
      );
    }
  }

  renderLocation(contact: Contact): JSX.Element | undefined {
    const { address, email, website, whatsapp, phone } = contact;

    if (
      address.trim().length > 0 ||
      email.trim().length > 0 ||
      website.trim().length > 0 ||
      whatsapp.trim().length > 0 ||
      phone.trim().length > 0
    ) {
      return <Title titleMargin={30}>Localização e Contato</Title>;
    }
  }

  renderMap(coords?: Coords): JSX.Element | undefined {
    if (coords) {
      return (
        <MapContainer>
          <LoadScript googleMapsApiKey="AIzaSyCQehFbmh-BxyVN46MOlgUkcqMl7BHvM94">
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "100%" }}
              center={coords}
              zoom={20}
            />
          </LoadScript>
        </MapContainer>
      );
    }
  }

  renderAddress(address: string, name: string): JSX.Element | undefined {
    if (address.trim().length > 0) {
      const newAddress: string = name + " - " + address;
      const link: string = `https://www.google.com.br/maps/place/${newAddress}`;

      return (
        <a href={link} target="blank">
          <Address borderRadiusTop={false}>
            <span>{address}</span>
            <FaLocationArrow color={"#4285F4"} size={24} />
          </Address>
        </a>
      );
    }
  }

  renderWebsite(website: string): JSX.Element | undefined {
    if (website.trim().length > 0) {
      return (
        <a href={website} target="blank">
          <LocationCard>
            <span>{website}</span>
            <FaGlobe color={"4285F4"} size={24} />
          </LocationCard>
        </a>
      );
    }
  }

  renderEmail(email: string): JSX.Element | undefined {
    if (email.trim().length > 0) {
      return (
        <a href={"mailto:" + email}>
          <LocationCard>
            <span>{email}</span>
            <MdMail color={"4285F4"} size={24} />
          </LocationCard>
        </a>
      );
    }
  }

  renderPhone(phone: string): JSX.Element | undefined {
    if (phone.trim().length > 0) {
      return (
        <a href={`tel:+55${phone}`}>
          <LocationCard>
            <span>{phone}</span>
            <FaPhoneAlt color={"4285F4"} size={24} />
          </LocationCard>
        </a>
      );
    }
  }

  renderWhatsapp(whatsapp: string): JSX.Element | undefined {
    if (whatsapp.trim().length > 0) {
      return (
        <a href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(whatsapp)}`}>
          <LocationCard borderRadiusBottom={true}>
            <span>{whatsapp}</span>
            <FaWhatsapp color={"4285F4"} size={24} />
          </LocationCard>
        </a>
      );
    }
  }
}
