import { GoSearch } from "react-icons/go"
import React, { Component, CSSProperties } from "react"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import queryString from 'query-string'
import { RouteComponentProps } from "react-router-dom"

import Login from "../../featureComponents/login/Login"
import SignUp from "../../featureComponents/signUp/SignUp"
import Recover from "../../featureComponents/recover/Recover"
import { categoriesRequest } from "../../store/ducks/categories/actions"
import { userUpdateRequest, userAcceptPrivacyPolicyRequest, logOut } from "../../store/ducks/user/actions"
import Loading from "../../featureComponents/loading/LoadingScreen"
import venueImg from "../../resources/img/venue_placeholder.png"
import { Categories } from "../../store/ducks/categories/types"
import { State } from "../../store/ducks/locations/types"
import { statesRequest,citiesRequest } from "../../store/ducks/locations/actions"
import { UserInfos } from "../../store/ducks/user/types"
import { Venue } from "../../store/ducks/venues/types"
import { ApplicationState } from "../../store"
import Header from "../../featureComponents/header/Header"
import Alert from "../../featureComponents/alert/Alert"
import ModalPrivacyPolicies from "../../featureComponents/modalPrivacyPolicies/ModalPrivacyPolicies"
import VenueDetail from "../../featureComponents/venues/VenueDetail"
import {
  venuesRequest,
  searchVenuesRequest,
  singleVenueRequest,
  startLoader,
} from "../../store/ducks/venues/actions"
import Rate from "../../featureComponents/rate/Rate"
import MobileSearchBar from "./mobile/MobileSearchBar"
import OutsideClickListener from "../../helpers/OutsideClickListener"
import MobileMenu from "./mobile/MobileMenu"

import Select from 'react-select'
import {CustomSelectOption,CustomSelectValue} from '../../featureComponents/customSelect/CustomSelect'

import {defaultLocation} from '../../constants'

import {
  Container,
  Body,
  Card,
  SearchContainer,
  SearchLabel,
  SearchLocale,
  SearchTypeSeparator,
  CitySelector,
  StateSelector,
  LocaleInput,
  LocaleTypes,
  AccessibilityContainer,
  DropDownContainer,
  DropDownStyle,
  MainInfo,
  VenueName,
  VenuePin,
  AccessInfo,
  VenuesContainer,
  VenueCertificationPin,
  VenueBadge,
  VenuesSide,
  HomeContainer,
  SearchContainerItems,
  DrawerMenuContainer,
  MainContainer
} from "./styles"

interface StateProps {
  loadingCities: boolean
  loadingStates: boolean
  loadingVenues: boolean
  loadingFeedback: boolean
  venues: Venue[]
  venue: Venue
  categories: Categories[]
  cities:[]
  states:State[]
  user: UserInfos | null
  error: boolean
}

interface DispatchProps {
  userAcceptPrivacyPolicyRequest(): void
  userUpdateRequest(userInfo: any): void
  venuesRequest(lat: number | null, lng: number | null): void
  categoriesRequest(): void
  startLoader(): void
  statesRequest():void
  citiesRequest(stateUF:string):void
  searchVenuesRequest(
    query: string | null,
    category_id: number | null,
    accessibility_filter: number | null,
    lat: number | null,
    lng: number | null,
    stateUF: string | null,
    city: string | null,
  ): void
  logOut(): void,
  singleVenueRequest(
    venueId: string,
    lat: number | null,
    lng: number | null
  ): void
}

interface MakerInfoWindowObj {
  [key: string]: boolean
}

interface MatchParams {
  venueId: string
}

interface MatchProps extends RouteComponentProps<MatchParams> {
}

type Props = StateProps & DispatchProps & MatchProps

const venueTypeAll = "Todos";

const SEARCHMODE = {
  NEUTRAL:"seaechmode_neutral",
  TEXTUAL:"searchmode_text",
  DROPDOWN:"searchmode_dropdown"
}


class Home extends Component<Props> {

  async componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)

    const search = queryString.parse(this.props.history.location.search)
    const { query, lat, lng , stateUF , city} = search;

    if ((window.location.pathname === '/' && (!query && !lat && !lng)) && (window.location.pathname === '/' && (!stateUF && !city)))
    {
       this.props.startLoader();
       await this.getGeolocation();
    }
  
    this.props.categoriesRequest()
    this.props.statesRequest()

    const isViewed = localStorage.getItem("MOBILE_LAYOUT_ALERT_VIEWED")
    if (window.innerWidth <= 768 && isViewed !== "YES") {
      this.setState({ showMobileLayoutAlert: true })
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { history, searchVenuesRequest, singleVenueRequest } = this.props
    const { match: { params: { venueId } } } = this.props
    const { showDetails, shouldLoadVenuesByQueryParams, shouldLoadVenueDetailsByParams } = this.state
    const pathname = window.location.pathname
   
    if (pathname === '/' && this.props.categories.length === 0){
      return
    }

    if (pathname === '/' && showDetails) {
      let { searchVenue, category, accessible, stateUF, city } = this.state
      let { lat, lng } = queryString.parse(history.location.search)
      
      searchVenuesRequest(
        searchVenue,
        category,
        accessible,
        lat ? parseFloat(`${lat}`) : null,
        lng ? parseFloat(`${lng}`) : null,
        stateUF,
        city,
      )
      this.setState({
        lastPath: null,
        showDetails: false,
        shouldLoadVenuesByQueryParams: true
      })
    } else if (pathname.startsWith('/venue-details') && shouldLoadVenueDetailsByParams && venueId) {
      this.setState({
        shouldLoadVenuesByQueryParams: false,
        shouldLoadVenueDetailsByParams: false,
        showDetails: true,
        venueId
      })
      singleVenueRequest(venueId, defaultLocation.lat, defaultLocation.lng)
    } else if (shouldLoadVenuesByQueryParams) {
      this.loadVenuesByQueryParams()
    }
  }
  updateDimensions = () => {
    this.setState({ innerWidth: window.innerWidth })
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  onChangeStateUF = (value:any) => {
    const { accessible, category } = this.state
    if (value?.value) {
      const uf = value.value;
      this.props.citiesRequest(uf);
      this.setState({
        stateUF:uf,
        city:null
      })

      this.pushHistoryQuerySearchVenues(null, category, accessible, null, null ,uf, null)
    }
  }

  onChangeCity = (value:any) => {
    if (value?.value) {
      const city = value.value;
      this.searchVenueByCity(city)
      this.setState({
        city:city
      })
    }
  }

  onChangeVenueType = (value:any) => {
    if (!value) return;
    const categoryID = value.value
    if (categoryID === 0){ //Todos
      this.searchVenueByCategory(null)
      this.setState({
        category: null,
        venueType: venueTypeAll,
      })
    }
    else {
      this.searchVenueByCategory(categoryID)
      this.setState({
        category: categoryID,
        venueType: value.label,
      })
    }
  }

  onChangeAcessibilityType = (value:any) => {
    if (!value) return;
    const acessibilityID = value.value
    if (acessibilityID === 0){ //Todos
      this.searchVenueByAccessibility(null)
    }
    else {
      this.searchVenueByAccessibility(acessibilityID)
    }
  }

  getSearchModeStyle = (object:any) => {
    const {searchMode} = this.state;
    let opacity = 1.0;

    enum PE {
      Auto = "auto",
      None  = "none",
  }
  
    let pointerEventsString: PE = PE.Auto;


    if (searchMode === SEARCHMODE.TEXTUAL){
        if (object === 'filter'){
          opacity = 0.5
          pointerEventsString = PE.None 
        }
    }

    const style: CSSProperties = {
      opacity:opacity,
      pointerEvents: pointerEventsString,
      display:'flex'
    }

    return style;
  }

  loadVenuesByQueryParams() {
    const { categories, history, searchVenuesRequest, venuesRequest } = this.props
    const { query, category, accessible, lat, lng, stateUF, city } = queryString.parse(history.location.search)
    if ((query || category || accessible) && categories?.length > -1) { //
      const searchVenue = query ? `${query}` : null
      const { parsedCategoryId, venueType } = this.setupVenueTypeAndGetParsedCategoryId(categories, category)
      const { pin, parsedAccessible } = this.setupPinAndGetParsedAccessible(accessible)
      const parsedStateUF = (typeof stateUF === "string")?stateUF:null
      const parsedCity = (typeof city === "string")?city:null

      if (!this.props.cities?.length && parsedStateUF){
        console.log('cities request')
        this.props.citiesRequest(parsedStateUF);
      }
      
      searchVenuesRequest(
        searchVenue,
        parsedCategoryId,
        parsedAccessible,
        lat ? parseFloat(`${lat}`) : null,
        lng ? parseFloat(`${lng}`) : null,
        parsedStateUF,
        parsedCity
      )
      this.setState({
        showDetails: false,
        searchQueryParams: history.location.search,
        shouldLoadVenuesByQueryParams: false,
        shouldLoadVenueDetailsByParams: false,
        searchVenue,
        venueType,
        pin,
        category: parsedCategoryId,
        accessible: parsedAccessible,
        stateUF:parsedStateUF,
        city:parsedCity
      })
    } 
    else if (!query && stateUF && city){
      const parsedStateUF = (typeof stateUF === "string")?stateUF:null
      const parsedCity = (typeof city === "string")?city:null

      if (!this.props.cities?.length && parsedStateUF){
        console.log('cities request')
        this.props.citiesRequest(parsedStateUF);
      }

      this.setState({
        showDetails: false,
        shouldLoadVenuesByQueryParams: false,
        stateUF:parsedStateUF,
        city:parsedCity
      })
      
      searchVenuesRequest(
        null,
        null,
        null,
        null,
         null,
        parsedStateUF,
        parsedCity
      )

    }
    else if (lat && lng){
      const latitude = parseFloat(`${lat}`);
      const longitude = parseFloat(`${lng}`);
      this.setState({
        showDetails: false,
        shouldLoadVenuesByQueryParams: false
      })
      venuesRequest(latitude, longitude)
    }
    else{
      this.setState({
        showDetails: false,
        shouldLoadVenuesByQueryParams: false
      })
      venuesRequest(defaultLocation?.lat, defaultLocation?.lng)
    }
  }

  state: {
    searchQueryParams: string
    searchMode: string
    searchVenue: string
    searching: boolean
    category: number | null
    accessible: number | null
    lat: number | null
    lng: number | null,
    stateUF: string | null
    city: string | null
    venue: Venue | null
    defaultPositions: boolean
    showTypesDropdown: boolean
    showAccessibilityDropdown: boolean
    venueType: string
    pin: any
    showAlert: boolean
    showDetails: boolean
    showRate: boolean
    showLogin: boolean,
    showRecover: boolean,
    showSignUp: boolean,
    showInfoWindow: MakerInfoWindowObj,
    showMobileLayoutAlert: boolean,
    shouldLoadVenuesByQueryParams: boolean,
    shouldLoadVenueDetailsByParams: boolean,
    showSearchBar: boolean,
    showDrawerMenu: boolean,
    venueId: string | null,
    showProfileMenu: boolean,
    innerWidth: number
  } = {
      searchQueryParams: "",
      searchMode: SEARCHMODE.NEUTRAL,
      searchVenue: "",
      searching: false,
      category: null,
      accessible: null,
      lat: null,
      lng: null,
      stateUF: null,
      city: null,
      venue: null,
      defaultPositions: true,
      showTypesDropdown: false,
      showAccessibilityDropdown: false,
      venueType: venueTypeAll,
      pin: null,
      showAlert: false,
      showDetails: false,
      showRate: false,
      showLogin: false,
      showRecover: false,
      showSignUp: false,
      showInfoWindow: {},
      showMobileLayoutAlert: false,
      shouldLoadVenuesByQueryParams: true,
      shouldLoadVenueDetailsByParams: true,
      showSearchBar: false,
      showDrawerMenu: false,
      venueId: null,
      showProfileMenu: false,
      innerWidth: window.innerWidth
    };

  render() {
    const {
      loadingCities,
      loadingStates,
      loadingVenues,
      venues,
      loadingFeedback,
      error,
      categories,
      user,
      userAcceptPrivacyPolicyRequest,
      venue
    } = this.props
    const {
      showTypesDropdown,
      lat,
      lng,
      showAlert,
      showDetails,
      showRate,
      showLogin,
      showRecover,
      showSignUp,
      showSearchBar,
      showDrawerMenu,
      showProfileMenu,
      innerWidth
    } = this.state

    if (user && !user.privacy_policy_accepted) {
      return (<div>
        <ModalPrivacyPolicies
          onAcceptTerms={() => {
            userAcceptPrivacyPolicyRequest()
          }}
        />
      </div>)
    }
    return (<div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      height: '100vh'
    }}>
      <DrawerMenuContainer showDrawerMenu={showDrawerMenu}>
        <MobileMenu
          {...this.props}
          urlSearch={this.state.searchQueryParams}
          user={user}
          showMenu={() => this.showMenu()}
          onLoginClicked={() => {
            this.showMenu()
            this.shouldShowLogin(true)
          }}
          onLogoutClicked={() => {
            this.props.logOut()
          }}
          onProfileClicked={() => {
            this.showMenu()
            this.setState({ showProfileMenu: !showProfileMenu })
          }}
        />
      </DrawerMenuContainer>
      <MainContainer
        showDrawerMenu={showDrawerMenu}
        style={{
          overflow: showLogin || showSignUp || showRate || showRecover ? 'hidden' : 'visible'
        }}>
        <Container>
          {!(window.location.pathname.startsWith('/venue-details') && innerWidth <= 450) && <Header
            showProfileMenu={showProfileMenu}
            showAlert={() => this.shouldShowAlert()}
            showLogin={(showLogin: boolean) => this.shouldShowLogin(showLogin)}
            showDetails={() => {
              this.setState({ showDetails: false })
              this.props.history.push({
                pathname: '/',
                search: ''
              })
              window.location.reload() 
            }}
            showRate={(showRate: boolean) => this.shouldShowRate(showRate)}
            searchChanged={this.checkIfSearchHasChanged()}
            isSearchBarOpen={showSearchBar}
            showSearchBar={() => {
              this.setState({ showSearchBar: !showSearchBar })
            }}
            showMenu={() => this.showMenu()}
            handleShowProfileMenu={(show) => {
              this.setState({ showProfileMenu: show })
            }}
          />}
          <Body>
            {!showDetails && !window.location.pathname.startsWith('/venue-details') && <HomeContainer>
              <SearchContainer>
                <SearchContainerItems>
                  <SearchLocale>
                    <SearchLabel>Buscar Locais com Acessibilidade</SearchLabel>
                    <LocaleInput>
                      {this.renderSearchIcon()}
                      <input
                        id="search"
                        type="text"
                        placeholder="Restaurante em Copacabana"
                        value={this.state.searchVenue || ''}
                        onChange={this.handleChange.bind(this)}
                        onKeyPress={this.searchVenueByEnterKey.bind(this)}
                      />
                    </LocaleInput>
                  </SearchLocale>
                  <SearchTypeSeparator > <span style={{ padding: "3pt", color: "rgba(0, 0, 0, 0.5)" }}>OU</span></SearchTypeSeparator>
                  <div style={this.getSearchModeStyle('filter')}>
                  <StateSelector>
                    <SearchLabel>Estado</SearchLabel>
                    <Select options={this.getStateDropdownValues()} styles={DropDownStyle} placeholder={null}  onChange={this.onChangeStateUF} isLoading={loadingStates} 
                    defaultValue={this.getCurrentStateUF()} loadingMessage={()=>"Carregando..."} value={this.getCurrentStateUF()}/>
                  </StateSelector>
                 <CitySelector >
                  <SearchLabel>Cidade</SearchLabel>
                     <Select options={this.getCitiesDropdownValues()} styles={DropDownStyle} placeholder={null} noOptionsMessage={()=>"Selecione o Estado"} 
                     onChange={this.onChangeCity} isLoading={loadingCities} loadingMessage={()=>"Carregando..."}
                     defaultValue={this.getCurrentCity()} value={this.getCurrentCity()} />
                  </CitySelector>
               
                  <LocaleTypes>
                    <SearchLabel>Categorias</SearchLabel>
                    <Select options={this.getTypesDropdownValues()} styles={DropDownStyle} placeholder={null} isLoading={false} loadingMessage={()=>"Carregando..."} defaultValue={this.getTypesDropdownValues()[0]} value={this.getCurrentCategory()}  onChange={this.onChangeVenueType}/>
                  </LocaleTypes>
                  <AccessibilityContainer>
                    <SearchLabel id="label-acessibilidade">Acessibilidade</SearchLabel>
                    <Select options={this.getAcessibilityDropdownValues()} styles={DropDownStyle} placeholder={null} isLoading={false} loadingMessage={()=>"Carregando..."} defaultValue={this.getAcessibilityDropdownValues()[0]} value={this.getCurrentAccessibility()}  onChange={this.onChangeAcessibilityType}
                     components={{ Option: CustomSelectOption, SingleValue: CustomSelectValue }} width="140px" isSearchable={false}/>
                  </AccessibilityContainer>
                  </div>
                </SearchContainerItems>
              </SearchContainer>
              <VenuesSide preventClicks={showDrawerMenu}>
                {this.renderVenues(venues)}
              </VenuesSide>
              {showSearchBar && <MobileSearchBar
                isDrawerMenuOpen={showDrawerMenu}
                visible={showSearchBar}
                searchVenue={this.state.searchVenue}
                searchIcon={this.renderSearchIcon()}
                localeTypesDropdown={showTypesDropdown}
                categoryOptions = {this.getTypesDropdownValues()}
                citiesOptions = {this.getCitiesDropdownValues()}
                statesOptions = {this.getStateDropdownValues()}
                accessibilityOptions = {this.getAcessibilityDropdownValues()}
                onSearchVeneuChanged={this.handleChange.bind(this)}
                onKeyPressSearchVeneu={this.searchVenueByEnterKey.bind(this)}
                onChangeVenueType={this.onChangeVenueType}
                onChangeAccessibility={this.onChangeAcessibilityType}
                onChangeStateUF={this.onChangeStateUF}
                onChangeCity={this.onChangeCity}
                loadingCities={loadingCities}
                filterContainerStyle = {this.getSearchModeStyle('filter')}
                currentStateUF={this.getCurrentStateUF()}
                currentCity={this.getCurrentCity()}
                currentCategory={this.getCurrentCategory()}
                currentAccessibility={this.getCurrentAccessibility()}
              />}
            </HomeContainer>}
            {showDetails && (
              <VenueDetail
                {...this.props}
                urlSearch={this.state.searchQueryParams}
                lat={lat}
                lng={lng}
                hideDetails={() => this.hideDetails()}
                categories={categories}
                showRate={(showRate: boolean) => this.shouldShowRate(showRate)}
              />
            )}
          </Body>
          {showLogin && (
            <Login
              showLogin={(show: boolean) => this.shouldShowLogin(show)}
              showSignUp={(show: boolean) => this.shouldShowSignUp(show)}
              showRecover={(show: boolean) => this.shouldShowRecover(show)}
            />
          )}
          {showSignUp && (
            <SignUp
              showLogin={(show: boolean) => this.shouldShowLogin(show)}
              showSignUp={(show: boolean) => this.shouldShowSignUp(show)}
            />
          )}
          {showRecover && (
            <Recover
              showLogin={(show: boolean) => this.shouldShowLogin(show)}
              showRecover={(show: boolean) => this.shouldShowRecover(show)}
            />
          )}
          {showRate && (
            <Rate
              hideRate={(hide: boolean) => this.shouldShowRate(!hide)}
              venueId={venue?.venue_id}
              categoryId={venue?.category_id}
              showLogin={(showLogin: boolean) => this.shouldShowLogin(showLogin)}
            />
          )}
          {loadingVenues && !showDetails && (
            <Loading
              height={window.innerHeight}
              title="Carregando"
              subtitle="Lugares"
            />
          )}
          {loadingFeedback && (
            <Loading
              height={window.innerHeight}
              title="Enviando"
              subtitle="Feedback"
            />
          )}
          {!loadingFeedback && !error && showAlert && (
            <Alert
              height={window.innerHeight}
              title="Feedback!"
              message="Seu feedback foi enviado com sucesso!"
              textButton="OK"
              onOKClicked={() => this.confirmAlert()}
            />
          )}
          {/* {showMobileLayoutAlert && (
            <Alert
              title="Opps!"
              message="Para uma melhor experiência sugerimos baixar o
            App Guiaderodas no seu celular.
            Estamos trabalhando na versão do Guiaderodas para mobile,
            que estará disponível em breve."
              textButton="Quero baixar o App!"
              onOKClicked={() => {
                localStorage.setItem("MOBILE_LAYOUT_ALERT_VIEWED", "YES")
                window.open("https://bit.ly/appguiaderodas")
              }}
              cancelTextButton="Vou baixar mais tarde"
              onCancelClicked={() => {
                localStorage.setItem("MOBILE_LAYOUT_ALERT_VIEWED", "YES")
                this.setState({ showMobileLayoutAlert: false })
              }}
            />
          )} */}
        </Container>
      </MainContainer>
    </div>)
  }

  checkIfSearchHasChanged(): boolean {
    const { venueType, searchVenue , stateUF, city, accessible} = this.state
  
    const hasChanged:boolean = ((venueType !== venueTypeAll && venueType)
      || (stateUF && city)
      || (searchVenue && searchVenue.length > 3)
      || (accessible && accessible > 0)) as boolean

    return hasChanged

  }

  showMenu() {
    this.setState({
      showDrawerMenu: !this.state.showDrawerMenu,
      showSearchBar: false
    })
  }

  shouldShowLogin(showLogin: boolean) {
    this.setState({ showLogin })
  }

  shouldShowSignUp(show: boolean) {
    this.setState({ showSignUp: show })
  }

  shouldShowRecover(show: boolean) {
    this.setState({ showRecover: show })
  }

  shouldShowRate(showRate: boolean) {
    this.setState({ showRate })
  }

  hideDetails() {
    this.setState({ showDetails: false })
    this.props.history.push({
      pathname: '/',
      search: this.state.searchQueryParams
    })
    window.location.reload()
  }

  shouldShowAlert() {
    const { showAlert } = this.state

    this.setState({ showAlert: !showAlert })
  }

  confirmAlert() {
    this.setState({ showAlert: false })
  }

  renderVenues(venues: Array<Venue>) {
    if (!venues) return (<></>)
    return (
      <VenuesContainer>
        {
          venues.map((venue) => {
            return (
              <Card key={venue.venue_id}
                onClick={() => {
                  this.setState({ showDetails: true, venue, showInfoWindow: {} })
                  this.pushHistoryQueryVenueDetails(venue.venue_id)
                }}>
                {this.venueCertificationPin(venue.status)}
                <img id='photo' style={{ objectFit: 'cover' }}
                  src={venue.photo_url ? venue.photo_url : venueImg}
                  alt={venue.name}
                />
                <MainInfo>
                  <VenueName>
                    {venue.name}
                  </VenueName>
                </MainInfo>
                <AccessInfo>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                  }}>
                    <span
                      style={{
                        fontSize: 13,
                        color: "rgba(0,0,0,0.4)",
                        marginBottom: 10
                      }}
                    >
                      {this.getCategoryByID(venue.category_id)}
                    </span>
                    <span
                      style={{
                        fontSize: 12,
                        color: "rgba(0,0,0,0.6)",
                      }}
                    >
                      {this.venueName(venue.pin_color_code)}
                    </span>
                  </div>
                  {this.renderPin(venue.pin_color_code, venue.status)}
                </AccessInfo>
              </Card>
            )
          })
        }
      </VenuesContainer >
    )
  }

  circleColor(colorCode: number) {
    switch (colorCode) {
      case -1:
        return "#7F7F7F"
      case 1:
        return "#DD352F"
      case 3:
        return "#E4BD54"
      case 5:
        return "#00A652"
    }
  }

  getLongAndLat():any {
    return new Promise((resolve, reject) => {
        if (process.env.NODE_ENV === 'development'){
          resolve({
            coords:{
              latitude:defaultLocation.lat,
              longitude:defaultLocation.lng
            }
          })
        }
        else {
        navigator.geolocation.getCurrentPosition(resolve, reject)
        }
      });
  }

  async getGeolocation() {
    let coords:any = null;
    try {
      const position = await this.getLongAndLat();
      coords = position?.coords;
    } catch (error) {
      
    }
    if (coords){
      const search = queryString.parse(this.props.history.location.search)  
      search.lat = coords.latitude
      search.lng = coords.longitude
      const searchString = "?" + queryString.stringify(search);
      this.props.history.replace({
        pathname: '/',
        search: searchString
      })

      this.setState({
        searchQueryParams:searchString,
        defaultPositions: false,
        lat: coords.latitude,
        lng: coords.longitude,
      })
    }
  }

  getStateDropdownValues = () => {
    const { states } = this.props
    const values = states.map((statesItem, index) => {
      return { value: statesItem.short, label: statesItem.short }
    })
    return values;
  }

  getCitiesDropdownValues = () => {
    const { cities } = this.props
    const values = cities.map((city) => {
      return { value: city, label: city }
    })
    return values;
  }

  getTypesDropdownValues = () => {
    const { categories } = this.props
    const values = categories.map((category, index) => {
      return { value: category.category_id, label: category.name }
    })
    values.unshift({value:0,label:venueTypeAll});
    return values;
  }

  getAcessibilityDropdownValues = () => {
    const values = [
      {
        value:0,
        label:venueTypeAll,
        icon:null
      },
      {
        value:9,
        label:"Certificado",
        icon:require("../../resources/icons/PIN-Verde-Cetificado.png")
      },
      {
        value:7,
        label:"Destaques",
        icon:require("../../resources/icons/PIN-Verde-Top.png")
      },
      {
        value:5,
        label:"Acessível",
        icon:require("../../resources/icons/PIN-Verde.png")
      },
    ]
    return values;
  }
  
  getCurrentStateUF = () => {
    const { stateUF } = this.state
    const uf = this.getStateDropdownValues().find((item:any) => item.value === stateUF);
    return uf?uf:null
  } 

  getCurrentCity = () => {
    const { city } = this.state
    const currentCity = this.getCitiesDropdownValues().find((item:any) => item.value === city);
    return currentCity?currentCity:null
  }

  getCurrentCategory = () => {
    const { category } = this.state;
    const categoryOptions = this.getTypesDropdownValues()
    const currentCategory = categoryOptions.find((item:any) => item.value === category);
    return currentCategory?currentCategory:categoryOptions[0]; 
  }

  getCurrentAccessibility = () => {
    const { accessible } = this.state;
    const accessibilityOptions = this.getAcessibilityDropdownValues()
    const currentAccessibility = accessibilityOptions.find((item:any) => item.value === accessible);
    return currentAccessibility?currentAccessibility:accessibilityOptions[0] 
  }

  renderAccessibilityModal() {
    const greenPin = require("../../resources/icons/PIN-Verde.png")
    const highlightPin = require("../../resources/icons/PIN-Verde-Top.png")
    const pinCertificado = require("../../resources/icons/PIN-Verde-Cetificado.png")

    return (
      <div className="accessibilityDropdown">
        <OutsideClickListener
          onOutsideClick={() => this.setState({ showAccessibilityDropdown: false })}
        >
          <DropDownContainer style={{ marginLeft: -45 }}>
            <div
              className="todos-dropdown"
              onClick={() => {
                this.setState({
                  pin: null,
                  showAccessibilityDropdown: false,
                })
                this.searchVenueByAccessibility(null)
              }}
            >
              <span>TODOS</span>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation()
                this.setState({ pin: pinCertificado, showAccessibilityDropdown: false })
                this.searchVenueByAccessibility(9)
              }}
            >
              <img
                style={{ height: 30, width: 20 }}
                src={pinCertificado}
                alt="pinCertificado"
              />
              <span>CERTIFICADO</span>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation()
                this.setState({ pin: highlightPin, showAccessibilityDropdown: false })
                this.searchVenueByAccessibility(7)
              }}
            >
              <img
                style={{ height: 30, width: 20 }}
                src={highlightPin}
                alt="destaquePin"
              />
              <span>TOP AVALIADO</span>
            </div>

            <div
              onClick={() => {
                this.setState({
                  pin: greenPin,
                  showAccessibilityDropdown: false,
                })
                this.searchVenueByAccessibility(5)
              }}
            >
              <img
                style={{ height: 30, width: 20 }}
                src={greenPin}
                alt="greenPin"
              />
              <span>ACESSÍVEL</span>
            </div>

            {/* <div
              onClick={() => {
                this.setState({
                  pin: yellowPin,
                  showAccessibilityDropdown: false,
                })
                this.searchVenueByAccessibility(3)
              }}
            >
              <img
                style={{ height: "20pt", width: "15pt" }}
                src={yellowPin}
                alt="yellowPin"
              />
              <span>PARCIALMENTE ACESSÍVEL</span>
            </div>

            <div
              style={{ borderBottom: "0px none" }}
              onClick={() => {
                this.setState({ pin: redPin, showAccessibilityDropdown: false })
                this.searchVenueByAccessibility(1)
              }}
            >
              <img
                style={{ height: "20pt", width: "15pt" }}
                src={redPin}
                alt="redPin"
              />
              <span>NÃO ACESSÍVEL</span> 
            </div>*/}
          </DropDownContainer>
        </OutsideClickListener>
      </div>)
  }

  cancelSearch() {
    const { category } = this.state

    this.searchVenueByCategory(category)
    this.setState({ searching: false, searchVenue: "", searchMode:SEARCHMODE.DROPDOWN })
  }

  renderSearchIcon() {
    const { searching } = this.state

    if (searching) {
      const icon = require("../../resources/icons/close_black.png")

      return (
        <img
          src={icon}
          alt={"close_icon"}
          onClick={() => this.cleanSearchVenue()}
          style={{
            height: "13pt",
            width: "13pt",
            color: "rgba(0,0,0,0.35)",
            cursor: "pointer",
          }}
        />
      )
    } else {
      return <GoSearch
        style={{
          cursor: "pointer"
        }}
        size={"13pt"}
        color={"rgba(0,0,0,0.35)"}
        onClick={() => this.searchVenue()} />
    }
  }

  searchVenueByEnterKey(event: any) {
    if (event.key === "Enter") {
      this.searchVenue()
      this.setState({
        searchMode:SEARCHMODE.TEXTUAL
      })
    }
  }

  cleanSearchVenue() {

    const { lat, lng } = this.state
    const { searchVenuesRequest } = this.props

    const position = {
      lat: defaultLocation.lat,
      lng: defaultLocation.lng,
    }

    if (lat && lng) {
      position.lat = lat
      position.lng = lng
    }

    this.pushHistoryQuerySearchVenues(null, null, null, position.lat, position.lng, null,null)
    searchVenuesRequest(
      null,
      null,
      null,
      position.lat,
      position.lng,
      null,
      null,
    )
    this.setState({ searchVenue: "", searching: false, accessible: null, pin: null, category: null, venueType: "Todos", stateUF: null, city: null, searchMode:SEARCHMODE.DROPDOWN })
  }

  searchVenue() {
    const { searchVenue, lat, lng } = this.state
    const { searchVenuesRequest } = this.props

    const position = {
      lat: defaultLocation.lat,
      lng: defaultLocation.lng,
    }

    if (lat && lng) {
      position.lat = lat
      position.lng = lng
    }

    if (searchVenue.trim().length > 0) {
      this.pushHistoryQuerySearchVenues(searchVenue, null, null, position.lat, position.lng,null,null)
      searchVenuesRequest(
        searchVenue,
        null,
        null,
        position.lat,
        position.lng,
        null,
        null,
      )
      this.setState({ searching: true, accessible: null, pin: null, category: null, venueType: "Todos", stateUF:null, city:null })
    }
  }

  searchVenueByCategory(categoryId: number | null) {
    const { lat, lng, accessible, stateUF, city, } = this.state
    const { searchVenuesRequest } = this.props

    const position = {
      lat: defaultLocation.lat,
      lng: defaultLocation.lng,
    }

    if (lat && lng) {
      position.lat = lat
      position.lng = lng
    }

    this.setState({ searching: false, searchVenue: "" })

    this.pushHistoryQuerySearchVenues(null, categoryId, accessible, position.lat, position.lng,stateUF,city)
    searchVenuesRequest(
      null,
      categoryId,
      accessible,
      position.lat,
      position.lng,
      stateUF,
      city
    )
  }

  searchVenueByCity = (cityName:string) => {
    const { category,accessible,stateUF } = this.state
    const { searchVenuesRequest } = this.props

    this.setState({ searching: false, searchVenue: "", accessible: accessible })
    this.pushHistoryQuerySearchVenues(null, category, accessible, null, null,stateUF,cityName)
    searchVenuesRequest(
      null,
      category,
      accessible,
      null,
      null,
      stateUF,
      cityName
    )
  }

  searchVenueByAccessibility(accessible: number | null) {
    const { lat, lng, category, city, stateUF } = this.state
    const { searchVenuesRequest } = this.props

    const position = {
      lat: defaultLocation.lat,
      lng: defaultLocation.lng,
    }

    if (lat && lng) {
      position.lat = lat
      position.lng = lng
    }

    this.setState({ searching: false, searchVenue: "", accessible: accessible })
    this.pushHistoryQuerySearchVenues(null, category, accessible, position.lat, position.lng,stateUF,city)
    searchVenuesRequest(
      null,
      category,
      accessible,
      position.lat,
      position.lng,
      stateUF,
      city
    )
  }

  pushHistoryQuerySearchVenues(query: any, category: any, accessible: any, lat: any, lng: any, stateUF:any, city:any) {
    const search = `?query=${query ? query : ''}&category=${category ? category : ''}` +
      `&accessible=${accessible ? accessible : ''}&lat=${lat ? lat : ''}&lng=${lng ? lng : ''}&stateUF=${stateUF ? stateUF : ''}&city=${city ? city : ''}` 
    this.setState({ searchQueryParams: search })
    this.props.history.push({
      pathname: '/',
      search
    })
  }

  pushHistoryQueryVenueDetails(venueId: string) {
    this.props.history.push({
      pathname: '/venue-details/' + venueId
    })
  }

  setupVenueTypeAndGetParsedCategoryId(categories: any, categoryId: any) {
    const parsedCategoryId = categoryId ? parseInt(`${categoryId}`) : null
    const category = categories.find((c: any) => c.category_id === parsedCategoryId)
    return { parsedCategoryId, venueType: category?.name || "Todos", }
  }

  setupPinAndGetParsedAccessible(accessible: any) {
    const greenPin = require("../../resources/icons/PIN-Verde.png")
    const yellowPin = require("../../resources/icons/PIN-Amarelo.png")
    const redPin = require("../../resources/icons/PIN-Vermelho.png")
    const highlightPin = require("../../resources/icons/PIN-Verde-Top.png")
    const pinCertificado = require("../../resources/icons/PIN-Verde-Cetificado.png")
    const parsedAccessible = accessible ? parseInt(`${accessible}`) : null
    let pin
    switch (parsedAccessible) {
      case 9:
        pin = pinCertificado
        break
      case 7:
        pin = highlightPin
        break
      case 5:
        pin = greenPin
        break
      case 3:
        pin = yellowPin
        break
      case 1:
        pin = redPin
        break
      default:
        pin = null
    }
    return { pin, parsedAccessible }
  }

  handleChange(event: any) {
    this.setState({ searchVenue: event.target.value })
  }

  getCategoryByID = (categoryID: number) => {
    const categories = this.props.categories
    if (categories.length) {
      const category = categories.find(
        (category) => category.category_id === categoryID
      )
      return category?.name
    } else return null
  }

  venueBadge(colorCode: number) {
    if (colorCode === -1) {
      return (
        <VenueBadge style={{ backgroundColor: "rgb(200, 200, 200)" }}>
          <span>{this.venueName(colorCode)}</span>
        </VenueBadge>
      )
    }

    if (colorCode === 1) {
      return (
        <VenueBadge style={{ backgroundColor: "#DD352F" }}>
          <span>{this.venueName(colorCode)}</span>
        </VenueBadge>
      )
    }

    if (colorCode === 3) {
      return (
        <VenueBadge style={{ backgroundColor: "#FFDB24" }}>
          <span>{this.venueName(colorCode)}</span>
        </VenueBadge>
      )
    }

    if (colorCode === 5) {
      return (
        <VenueBadge style={{ backgroundColor: "#00A652" }}>
          <span>{this.venueName(colorCode)}</span>
        </VenueBadge>
      )
    }
  }

  venueCertificationPin(statusCode: number) {
    const Selo_Certificado = require("../../resources/icons/Selo_Certificado.png")
    const Selo_Destaque = require("../../resources/icons/Selo_Destaque.png")
    // const Selo_EmCertificaccao = require("../../resources/icons/Selo_EmCertificaccao.png")
    // if (statusCode === 101) {
    //   return (
    //     <VenueCertificationPin
    //       src={Selo_EmCertificaccao}
    //       alt={"SeloEmCertificacao"}
    //     />
    //   )
    // } else 
    if (statusCode === 102) {
      return (
        <VenueCertificationPin
          src={Selo_Destaque}
          alt={"SeloDestaque"}
        />
      )
    } else if (statusCode === 103) {
      return (
        <VenueCertificationPin
          src={Selo_Certificado}
          alt={"SeloCertificado"}
        />
      )
    }
  }

  venueName(colorCode: number) {
    if (colorCode === -1) {
      return 'NÃO AVALIADO'
    } else if (colorCode === 1) {
      return 'NÃO ACESSÍVEL'
    } else if (colorCode === 3) {
      return 'PARCIALMENTE ACESSÍVEL'
    } else if (colorCode === 5) {
      return 'ACESSÍVEL'
    }
  }
  renderPin(colorCode: number, status: number) {
    const grayPin = require("../../resources/icons/pin_cinza@3x.png")
    const greenPin = require("../../resources/icons/PIN-Verde.png")
    const yellowPin = require("../../resources/icons/PIN-Amarelo.png")
    const redPin = require("../../resources/icons/PIN-Vermelho.png")
    const highlightPin = require("../../resources/icons/PIN-Verde-Top.png")
    const pinCertificado = require("../../resources/icons/PIN-Verde-Cetificado.png")


    let src = ''
 
    if (colorCode === -1) { src = grayPin }
    else if (colorCode === 1) { src = redPin }
    else if (colorCode === 3) { src = yellowPin }
    else if (colorCode === 5 && status === 100) { src = greenPin }
    else if (colorCode === 5 && status === 103) { src = pinCertificado }
    else if (colorCode === 5 && status > 100) { src = highlightPin }

    return (<VenuePin
      src={src}
      alt={"pin"}
    />)
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  states: state.locations.states,
  cities: state.locations.cities,
  loadingCities:state.locations.loadingCities,
  loadingStates:state.locations.loadingStates,
  categories: state.categories.data,
  loadingVenues: state.venues.loading,
  error: state.feedback.error,
  venues: state.venues.data,
  venue: state.venues.venue,
  user: state.user.user,
  loadingFeedback: state.feedback.loading,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    userAcceptPrivacyPolicyRequest: () => dispatch(userAcceptPrivacyPolicyRequest()),
    userUpdateRequest: (userInfo: any) => dispatch(userUpdateRequest(userInfo)),
    startLoader: () => dispatch(startLoader()),
    categoriesRequest: () => dispatch(categoriesRequest()),
    statesRequest: () => dispatch(statesRequest()),
    citiesRequest: (stateUF:string) => dispatch(citiesRequest(stateUF)),
    venuesRequest: (lat: number | null, lng: number | null) =>
      dispatch(venuesRequest(lat, lng)),
    searchVenuesRequest: (
      query: string | null,
      category_id: number | null,
      accessibility_filter: number | null,
      lat: number | null,
      lng: number | null,
      stateUF: string | null,
      city: string | null
    ) =>
      dispatch(
        searchVenuesRequest(query, category_id, accessibility_filter, lat, lng, stateUF, city)
      ),
    logOut: () => dispatch(logOut()),
    singleVenueRequest: (
      venueId: string,
      lat: number | null,
      lng: number | null
    ) => dispatch(singleVenueRequest(venueId, lat, lng)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
