import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 10%;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  background-color: white;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  justify-content: space-around;
  align-items: center;
`;

export const NavigationIcons = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    color: rgb(0, 187, 55);
  }
`;
