import { call, put } from "redux-saga/effects";

import api from "../../../services/api";
import { categoriesSuccess, categoriesFailure } from "./actions";

export function* loadCategories() {
  try {
    const params = "?locale=pt-br";
    const response = yield call(api.get, "categories" + params);
    yield put(categoriesSuccess(response.data));
  } catch (err) {
    yield put(categoriesFailure());
  }
}
