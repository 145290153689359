import { call, put } from "redux-saga/effects";

import api from "../../../services/api";
import { sendFeedbackSuccess, sendFeedbackFailure } from "./actions";

export function* sendFeedback(action: any) {
  try {
    const token = localStorage.getItem("AUTH_DATA");

    yield call(api.post, "feedback", JSON.stringify(action.feedback), {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    yield put(sendFeedbackSuccess());
  } catch (err) {
    yield put(sendFeedbackFailure());
  }
}
