import axios from "axios";
import {categories,states,cities,venues} from './mock/index'
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_API){
  console.log('MOCK API')
  
  var MockAdapter = require("axios-mock-adapter");
  var mock = new MockAdapter(axios,{ delayResponse: 10 });
 
  mock.onGet(/categories\/?.*/).reply(200, categories);
  mock.onGet(/locations\/states\/?.*/).reply(200, states);
  mock.onGet(/locations\/cities\/?.*/).reply(200, cities);
 
  mock.onGet(/mobile\/venues_ext\/?.*/).reply(200, venues);
  mock.onGet(/venues\/?.*/).reply(200, venues.venues);
}

// mock.onGet(/locations\/cities\/?.*/).reply((config:any) => {
//   console.log(config.url, parseQueryString(config.url));
//   return [202, []];
// });

/*function parseQueryString(url: string) {
  const queryString = url.replace(/.*\?/, '');

  if (queryString === url || !queryString) {
    return null;
  }

  const urlParams = new URLSearchParams(queryString);
  const result:any = {};

  urlParams.forEach((val, key) => {
    if (result.hasOwnProperty(key)) {
      result[key] = [result[key], val];
    } else {
      result[key] = val;
    }
  });

  return result;
}*/

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "X-Gdr-App-Id": process.env.REACT_APP_API_KEY,
    "Content-Type": "application/json",
  },
});

export default api;
