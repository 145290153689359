import React from "react"
import { FiArrowLeft } from "react-icons/fi"
import { MdShare } from "react-icons/md"

import venueImg from "../../../resources/img/venue_placeholder.png"
import {
  MainContainer,
  RateButton,
  VenueContainer,
  VenueInfos,
  HeaderContainer
} from "./styles"

export default ({
  history,
  urlSearch,
  loading,
  venue,
  renderCertificationBadge,
  renderBadge,
  renderCategory,
  renderPrice,
  renderAccessibility,
  renderComments,
  onRateClicked,
  onBookingClicked
}: any) => {
  const newNavigator: any = window.navigator
  return (<MainContainer>
    <HeaderContainer>
      <div
        style={{
          marginLeft: 10,
          display: "flex", flexDirection: "row",
          alignItems: 'center', justifyContent: 'center'
        }}
        onClick={() => {
          history.push('/' + urlSearch)
        }}>
        <FiArrowLeft
          style={{ cursor: "pointer", marginRight: 10 }}
          size={25}
          color={"rgb(48,48,48)"} />
        <span
          style={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}>{venue?.name}</span>
      </div>

      <MdShare
        style={{ cursor: "pointer", position: 'fixed', right: 15, top: 15 }}
        size={25}
        color={"rgb(48,48,48)"}
        onClick={() => {
          if (newNavigator?.share) {
            const subject = `Confira ${venue?.name}, ${renderCategory} no Guiaderodas`
            const body = (`
            Dá uma olhada nesse lugar que achei no Guiaderodas:\n
            ${venue?.name}, ${renderCategory}
            ${venue?.formatted_address}
            ${document.location.href} \n\n
            Baixe o aplicativo e confira mais locais. (link para https://bit.ly/appguiaderodas)
            `)
            newNavigator?.share({
              title: subject,
              text: body,
              url: document.location.href,
            })
              .then(() => {
                console.log('Successfully shared')
              })
              .catch((error: any) => {
                console.error('Something went wrong sharing the blog', error)
              })
          }
        }} />
    </HeaderContainer>
    {!loading && (
      <VenueContainer>
        {renderCertificationBadge}
        {renderBadge}
        <img
          src={venue?.photo_url ? venue?.photo_url : venueImg}
          alt="venue"
        />
        <VenueInfos>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                maxWidth: 320,
                fontSize: 24,
                overflow: "hidden",
                fontWeight: "bold",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                color: "rgba(0,0,0,0.8)",
              }}
            >
              {venue?.name}
            </span>
            <span
              style={{
                marginTop: 18,
                fontSize: 16,
                fontWeight: "bold",
                color: "rgba(0,0,0,0.6)",
              }}
            >
              {renderCategory}
            </span>
            <span style={{ marginTop: 5, color: "rgba(0,0,0,0.8)" }}>
              {venue?.formatted_address}
            </span>
            <span style={{ marginTop: 5, color: "rgba(0,0,0,0.6)" }}>
              {venue?.open_now ? "Aberto" : "Fechado"}
            </span>
            <span style={{ color: "rgba(0,0,0,0.6)" }}>
              {renderPrice}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              // href={`tel:+55${venue?.phone}`}
              style={{ marginTop: 10, textDecoration: "none" }}
            >
              <span style={{ color: "rgba(0,0,0,0.6)" }}>
                {venue?.phone}
              </span>
            </div>
            {venue?.urls?.booking && <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "flex-start",
              marginTop: 10,
              cursor: "pointer"
            }}
              onClick={onBookingClicked}>
              <img
                style={{ width: 24, height: 24, marginRight: 6 }}
                alt=""
                src={require("../../../resources/icons/icon_reserva.png")}
              />
              <span
                style={{ color: "rgb(74, 144, 226)", fontSize: 15 }}>Reservar
                      </span>
            </div>}

          </div>
          <RateButton onClick={onRateClicked}>
            Avaliar
                  </RateButton>
        </VenueInfos>
        {renderAccessibility}
        {renderComments}
      </VenueContainer>
    )}
  </MainContainer>
  )
}

