import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 55px;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  border-bottom: 1px solid rgb(240, 240, 240);
`

export const Container = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color:  rgb(249, 249, 249);

  @media(max-width: 450px) {
    display: flex;
  }
`

export const FeedbackModal = styled.div` 
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color:  rgb(249, 249, 249);
  margin-top: 100px;
  width: 100%;
  height: 100%;
`;

export const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;

  span {
    font-size: 15px;
    font-weight: bold;
    color: rgb(0, 0, 0, 0.7);
  }
`;

export const StarsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  width: 68%;
`;

export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-top: 1px solid rgb(240, 240, 240);
  border-bottom: 1px solid rgb(240, 240, 240);

  span {
    color: rgb(0, 0, 0, 0.7);
  }
`;

export const SubjectInput = styled.input`
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;
`;

export const EmailInput = styled.input`
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;
`;

export const ContentInput = styled.textarea`
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;
  resize: none;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 225px;
`;

export const SendButton = styled.button`
  margin: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #d90368;
  border: 0px none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;

  :disabled {
    background-color: #d9036855;
  }
`;


export const StarIcon = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;
