import { FaLessThan } from "react-icons/fa"
import { GoTriangleDown } from "react-icons/go"
import React, { Component } from "react"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import OutsideClickHandler from "react-outside-click-handler"

import { userUpdateRequest } from "../../store/ducks/user/actions"
import { UserInfos } from "../../store/ducks/user/types"
import { ApplicationState } from "../../store"
import MobileEditProfile from './mobile/MobileEditProfile'
import {
  MobileContainer,
  Container,
  SendButton,
  DateGenderContainer,
  Input,
  EditProfileContainer,
  EditProfileModal,
  DropdownButton,
  DropDownContainer,
  ContainerAlert,
  Alert,
  Title,
  Message,
  Button
} from "./styles"

interface StateProps {
  loading: boolean
  error: boolean
}

interface DispatchProps {
  userUpdateRequest(userInfo: any): void
}

interface OwnProps {
  user: UserInfos | null
  changeModal(renderEditProfile: boolean, renderProfile: boolean, renderFeedback: boolean): void
}

type Props = StateProps & DispatchProps & OwnProps

interface YesNoDropDownProps {
  onOutsideClick(): void
  onYesClick(): void
  onNoClick(): void
}

const YesNoDropDown = (props: YesNoDropDownProps) => {
  return (
    <OutsideClickHandler
      onOutsideClick={props.onOutsideClick}
    >
      <DropDownContainer style={{
        right: "0",
        marginTop: "5pt",
        marginRight: "8%",
        width: 250,
      }}>
        <span
          onClick={props.onYesClick}
          style={{ borderBottom: "1px solid rgb(200, 200, 200)" }}
        >
          Sim
        </span>
        <span
          onClick={props.onNoClick}
          style={{ borderBottom: "1px solid rgb(200, 200, 200)" }}
        >
          Não
        </span>
      </DropDownContainer>
    </OutsideClickHandler>)
}

interface AlertContainerOwnProps {
  title: String
  message: String
  handleOK(): void
}

const AlertContainer = (props: AlertContainerOwnProps) => {
  const { message, title } = props
  return (
    <ContainerAlert >
      <Alert>
        <Title>{title}</Title>
        <Message>{message}</Message>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={() => props.handleOK()}>OK</Button>
        </div>
      </Alert>
    </ContainerAlert>)
}

class EditProfile extends Component<Props> {

  getState(): any {
    const user = this.props.user
    if (!user) {
      return {
        name: "",
        lastName: "",
        email: "",
        birthday: "",
        wheelchair: "",
        hasFriend: "",
        birthdayError: false,
        gender: "Masculino",
        otherDisability: "",
        showAlert: false,
        showGenderDropdown: false,
        wheelChairDropDown: false,
        otherDisabilityDropDown: false,
        hasFriendDropDown: false
      }
    }

    return {
      name: user.first_name ? user.first_name : "",
      lastName: user.last_name ? user.last_name : "",
      email: user.email,
      birthday: user.birthday ? user.birthday : "",
      wheelchair: user.wheelchair,
      hasFriend: user.has_friend,
      birthdayError: false,
      gender: user.gender,
      otherDisability: user.other_disability ? user.other_disability : "",
      showAlert: false,
      showGenderDropdown: false,
      wheelChairDropDown: false,
      otherDisabilityDropDown: false,
      hasFriendDropDown: false
    }
  }

  state = this.getState();

  render() {
    const {
      name,
      lastName,
      gender,
      birthday,
      wheelchair,
      otherDisability,
      hasFriend,
      birthdayError,
      showAlert,
      showGenderDropdown,
      wheelChairDropDown,
      otherDisabilityDropDown,
      hasFriendDropDown
    } = this.state
    const { loading, error } = this.props

    return (
      <Container>
        {window.innerWidth > 450 && <EditProfileModal>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              padding: "10pt",
            }}
          >
            <FaLessThan color="rgb(0,0,0,0.6)" size={13} />
            <span
              onClick={() => this.props.changeModal(false, true, false)}
              style={{
                marginLeft: "5pt",
                fontSize: "13pt",
                color: "rgb(0,0,0,0.6)",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Voltar
          </span>
          </div>

          <EditProfileContainer>
            <span>Nome</span>
            <Input
              type="text"
              placeholder={name}
              value={name}
              onChange={this.saveName.bind(this)}
            />
            <span style={{ marginTop: 17 }}>Sobrenome</span>
            <Input
              type="text"
              placeholder={lastName}
              value={lastName}
              onChange={this.saveLastName.bind(this)}
            />

            {birthdayError && <span
              style={{ fontSize: 16, color: 'red', marginTop: 5, marginBottom: -10 }}>
              Data inválida!</span>}
            <DateGenderContainer>
              <div style={{ width: 140 }}>
                <span style={{ marginTop: 17 }}>Data de Nascimento</span>
                <Input
                  type="date"
                  style={{ width: 140 }}
                  value={this.formatBirthday(birthday)}
                  onChange={this.saveBirthday.bind(this)}
                />
              </div>
              <div style={{ width: 160 }}>
                <span style={{ marginTop: 17 }} >Gênero</span>
                <DropdownButton
                  style={{ paddingTop: "11pt", paddingBottom: "11pt" }}
                  onClick={() =>
                    this.setState({ showGenderDropdown: !showGenderDropdown })
                  }
                >
                  <span>{this.formatGender(gender)}</span>
                  <GoTriangleDown color={"rgb(147,147,147)"} />
                </DropdownButton>
                {showGenderDropdown && this.renderGenderDropDown()}
              </div>
            </DateGenderContainer>
            <span style={{ marginTop: 17 }} >Usa cadeira de rodas?</span>
            <DropdownButton
              style={{ paddingTop: "11pt", paddingBottom: "11pt" }}
              onClick={() =>
                this.setState({ wheelChairDropDown: !wheelChairDropDown })
              }
            >
              <span>{this.formatYesNo(wheelchair)}</span>
              <GoTriangleDown color={"rgb(147,147,147)"} />
            </DropdownButton>
            {wheelChairDropDown && this.renderWheelChairDropDown()}

            <span style={{ marginTop: 17 }} >Tem outra deficiência?</span>
            <DropdownButton
              style={{ paddingTop: "11pt", paddingBottom: "11pt" }}
              onClick={() =>
                this.setState({ otherDisabilityDropDown: !otherDisabilityDropDown })
              }
            >
              <span>{this.formatDisability(otherDisability)}</span>
              <GoTriangleDown color={"rgb(147,147,147)"} />
            </DropdownButton>
            {otherDisabilityDropDown && this.renderOtherDisabilityDropDown()}

            <span style={{ marginTop: 17 }} >Tem amigo(s) com deficiência?</span>
            <DropdownButton
              style={{ paddingTop: "11pt", paddingBottom: "11pt" }}
              onClick={() =>
                this.setState({ hasFriendDropDown: !hasFriendDropDown })
              }
            >
              <span>{this.formatYesNo(hasFriend)}</span>
              <GoTriangleDown color={"rgb(147,147,147)"} />
            </DropdownButton>
            {hasFriendDropDown && this.renderHasFriendDropDown()}

            <SendButton onClick={() => this.validateDate()}>
              <span>Salvar</span>
            </SendButton>

            {!loading && error && showAlert && (
              <span
                style={{ fontSize: 16, color: 'red', marginTop: 10 }}>
                Algo deu errado. Tente novamente.</span>
            )}
          </EditProfileContainer>

          {!loading && !error && showAlert && (
            <AlertContainer
              title="Sucesso!"
              message="Alterações editadas com sucesso."
              handleOK={() => this.props.changeModal(false, true, false)}
            />
          )}

          {!loading && error && showAlert && (
            <AlertContainer
              title="Oppps!"
              message="Algo deu errado. Tente novamente."
              handleOK={() => this.setState({ showAlert: false })}
            />
          )}
        </EditProfileModal >}
        {window.innerWidth <= 450 && <MobileContainer>
          <MobileEditProfile
            name={name}
            lastName={lastName}
            birthday={this.formatBirthday(birthday)}
            gender={this.formatGender(gender)}
            wheelchair={this.formatYesNo(wheelchair)}
            otherDisability={this.formatDisability(otherDisability)}
            hasFriend={this.formatYesNo(hasFriend)}
            birthdayError={birthdayError}
            showSuccessAlert={!loading && !error && showAlert}
            showGenericError={!loading && error && showAlert}
            renderGenderDropDown={showGenderDropdown && this.renderGenderDropDown()}
            renderHasFriendDropDown={hasFriendDropDown && this.renderHasFriendDropDown()}
            renderWheelChairDropDown={wheelChairDropDown && this.renderWheelChairDropDown()}
            renderOtherDisabilityDropDown={otherDisabilityDropDown && this.renderOtherDisabilityDropDown()}
            handleNameChanged={this.saveName.bind(this)}
            handleLastNameChanged={this.saveLastName.bind(this)}
            handleBirthdayChanged={this.saveBirthday.bind(this)}
            handleBackClicked={() => this.props.changeModal(false, true, false)}
            handleCloseClicked={() => this.props.changeModal(false, false, false)}
            handleSaveClicked={() => this.validateDate()}
            handleGenderDropdownClicked={() =>
              this.setState({ showGenderDropdown: !showGenderDropdown })
            }
            handleWheelChairDropDownClicked={() =>
              this.setState({ wheelChairDropDown: !wheelChairDropDown })
            }
            handleOtherDisabilityDropDownClicked={() =>
              this.setState({ otherDisabilityDropDown: !otherDisabilityDropDown })
            }
            handleHasFriendDropDownClicked={() =>
              this.setState({ hasFriendDropDown: !hasFriendDropDown })
            }
            handleCloseGenericError={() => this.setState({ showAlert: false })}
          />
        </MobileContainer>}
      </Container>)
  }

  formatBirthday(date: string) {
    const [day, month, year] = date.split("/")
    return `${year}-${month}-${day}`
  }

  formatYesNo(response: any) {
    if (response === "yes") {
      return "Sim"
    } else {
      return "Não"
    }
  }

  formatDisability(disability: string | undefined) {
    switch (disability) {
      case "":
        return "Não"
      case "physical":
        return "Sim - Física"
      case "visual":
        return "Sim - Visual"
      case "hearing":
        return "Sim - Auditiva"
      case "intellectual":
        return "Sim - Intelectual"
      case "multiple":
        return "Sim - Múltiplas"
    }
  }

  renderGenderDropDown() {
    return (
      <OutsideClickHandler
        onOutsideClick={() => this.setState({ showGenderDropdown: false })}
      >
        <DropDownContainer style={{
          right: "0",
          marginTop: "5pt",
          marginRight: "8%",
          width: 100,
        }}>
          <span
            onClick={() => {
              this.setState({ showGenderDropdown: false, gender: "male" })
            }}
            style={{ borderBottom: "1px solid rgb(200, 200, 200)" }}
          >
            Masculino
        </span>
          <span
            onClick={() => {
              this.setState({ showGenderDropdown: false, gender: "female" })
            }}
            style={{ borderBottom: "1px solid rgb(200, 200, 200)" }}
          >
            Feminino
        </span>
        </DropDownContainer>
      </OutsideClickHandler>)
  }

  renderWheelChairDropDown() {
    return (
      <YesNoDropDown
        onOutsideClick={() => this.setState({ wheelChairDropDown: false })}
        onYesClick={() => this.setState({ wheelChairDropDown: false, wheelchair: "yes" })}
        onNoClick={() => this.setState({ wheelChairDropDown: false, wheelchair: "no" })}
      />)
  }

  renderOtherDisabilityDropDown() {
    const setDisability = (otherDisability: any) => {
      this.setState({ otherDisabilityDropDown: false, otherDisability })
    }
    return (
      <OutsideClickHandler
        onOutsideClick={() => this.setState({ otherDisabilityDropDown: false })}
      >
        <DropDownContainer style={{
          right: "0",
          marginTop: "5pt",
          marginRight: "8%",
          width: 250,
        }}>
          <span
            onClick={() => setDisability("")}
            style={{ borderBottom: "1px solid rgb(200, 200, 200)" }}
          >
            Não
        </span>
          <span
            onClick={() => setDisability("physical")}
            style={{ borderBottom: "1px solid rgb(200, 200, 200)" }}
          >
            Sim - Física
        </span>
          <span
            onClick={() => setDisability("visual")}
            style={{ borderBottom: "1px solid rgb(200, 200, 200)" }}
          >
            Sim - Visual
        </span>
          <span
            onClick={() => setDisability("hearing")}
            style={{ borderBottom: "1px solid rgb(200, 200, 200)" }}
          >
            Sim - Auditiva
        </span>
          <span
            onClick={() => setDisability("intellectual")}
            style={{ borderBottom: "1px solid rgb(200, 200, 200)" }}
          >
            Sim - Intelectual
        </span>
          <span
            onClick={() => setDisability("multiple")}
            style={{ borderBottom: "1px solid rgb(200, 200, 200)" }}
          >
            Sim - Múltiplas
        </span>
        </DropDownContainer>
      </OutsideClickHandler>)
  }

  renderHasFriendDropDown() {
    return (
      <YesNoDropDown
        onOutsideClick={() => this.setState({ hasFriendDropDown: false })}
        onYesClick={() => this.setState({ hasFriendDropDown: false, hasFriend: "yes" })}
        onNoClick={() => this.setState({ hasFriendDropDown: false, hasFriend: "no" })}
      />)
  }

  confirmAlert() {
    this.setState({ showAlert: false })
  }

  validateDate() {
    const { birthday } = this.state

    const [day, month, year] = birthday.split("/")

    const newDate = new Date(year, month - 1, day)

    if (!newDate) {
      this.setState({ birthdayError: true })
      return
    }

    const today = new Date()
    let yearsDifference = today.getFullYear() - newDate.getFullYear()

    if (
      new Date(today.getFullYear(), today.getMonth(), today.getDate()) <
      new Date(today.getFullYear(), newDate.getMonth(), newDate.getDate())
    ) {
      yearsDifference--
    }

    if (!yearsDifference || yearsDifference <= 0 || yearsDifference > 150) {
      this.setState({ birthdayError: true })
      return
    }
    this.saveChanges()
  }

  saveChanges() {
    const {
      name,
      lastName,
      birthday,
      hasFriend,
      wheelchair,
      otherDisability,
      gender,
    } = this.state
    const { userUpdateRequest } = this.props

    let disability = otherDisability
    if (otherDisability === "none") {
      disability = ""
    }

    const userOBJ = {
      first_name: name,
      last_name: lastName,
      birthday: birthday,
      has_friend: hasFriend === "yes" ? true : false,
      wheelchair: wheelchair === "yes" ? true : false,
      other_disability: disability,
      gender: gender,
    }

    this.setState({ showAlert: true })
    userUpdateRequest(userOBJ)
  }

  formatGender(gender: string) {
    switch (gender) {
      case "male":
        return "Masculino"
      case "female":
        return "Feminino"
      default:
        return "Outros"
    }
  }

  saveName(event: any) {
    this.setState({ name: event.target.value })
  }

  saveLastName(event: any) {
    this.setState({ lastName: event.target.value })
  }

  saveBirthday(event: any) {
    this.setState({ birthdayError: false })
    const [year, month, day] = event.target.value.split("-")
    this.setState({ birthday: `${day}/${month}/${year}` })
  }

  saveGender(event: any) {
    this.setState({ gender: event.target.value })
  }

  saveWheelchair(event: any) {
    this.setState({ wheelchair: event.target.value })
  }

  saveDisability(event: any) {
    this.setState({ otherDisability: event.target.value })
  }

  saveHasFriend(event: any) {
    this.setState({ hasFriend: event.target.value })
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.user.loading,
  error: state.user.error,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    userUpdateRequest: (userInfo: any) => dispatch(userUpdateRequest(userInfo)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)
