import React, { Component } from "react"
import {
  AiOutlineCloseCircle,
  // AiFillApple,
} from "react-icons/ai"

import Loading from "../loading/LoadingScreen"
import FacebookButton from "../facebook/FacebookButton"
import bgImage from "../../resources/img/venue_placeholder.png"
import pin from "../../resources/icons/PIN-Verde.png"
import { ApplicationState } from "../../store"
import { Dispatch } from "redux"
import { User } from "../../store/ducks/user/types"
import { loginRequest, loginResetState } from "../../store/ducks/user/actions"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { LoginContainer, Footer, MainContainer, CloseContainer } from "./styles"

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MobileLogin from './mobile/MobileLogin'

interface OwnProps {
  showLogin(show: boolean): void
  showSignUp(show: boolean): void
  showRecover(show: boolean): void
}

interface StateProps {
  logged: boolean
  loading: boolean
  error: boolean
}

interface DispatchProps {
  loginRequest(
    grant_type: string,
    user: User,
    access_token: string | null
  ): void
  loginResetState(): void
}

type Props = OwnProps & StateProps & DispatchProps

class Login extends Component<Props> {
  componentDidUpdate() {
    const { logged, error, showLogin } = this.props

    if (logged && !error) {
      showLogin(false)
    }

    if (error && !this.state.openErrorDialog) {
      this.handleClickOpen()
    }
  }

  state = {
    email: "",
    password: "",
    openErrorDialog: false
  };

  render() {
    const { showLogin, showSignUp, showRecover, loading } = this.props

    return (<MainContainer>

      <div
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          borderRadius: "4pt",
        }}
      >
        <LoginContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "30pt",
            }}
          >
            <CloseContainer>
              <AiOutlineCloseCircle
                onClick={() => showLogin(false)}
                size={35}
                color="white"
              />
            </CloseContainer>

            <img src={pin} alt="pin" />
            <span
              style={{
                fontSize: "20pt",
                fontWeight: "bold",
                color: "rgba(0,0,0,0.8)",
                marginLeft: "10pt",
              }}
            >
              guiaderodas
              </span>
          </div>

          <div
            style={{
              textAlign: "center",
              width: "25vw",
              minWidth: "285.58px",
              marginBottom: "30pt",
            }}
          >
            <span
              style={{
                fontSize: "14pt",
                fontWeight: "bold",
                color: "rgba(0,0,0,0.8)",
              }}
            >
              Faça o login ou crie uma conta para aproveitar melhor o
              guiaderodas.
              </span>
          </div>

          <input
            type="email"
            placeholder="email@email.com"
            onChange={this.saveEmail.bind(this)}
            style={{ marginBottom: "10pt" }}
          />
          <input
            type="password"
            placeholder="Senha"
            onChange={this.savePassword.bind(this)}
            style={{ marginBottom: "10pt" }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "25vw",
              marginBottom: "40pt",
            }}
          >
            
              <span onClick={() => {
              showLogin(false)
              showRecover(true)
               }}
                style={{
                  fontSize: "11pt",
                  color: "#D90368",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Esqueci minha senha
              </span>
          </div>

          <button
            style={{ backgroundColor: "#D90368" }}
            onClick={() => this.login()}
          >
            <span style={{ color: "white" }}>Login</span>
          </button>
          <FacebookButton loginRequest={this.props.loginRequest} />
          {/* <button style={{ backgroundColor: "black" }}>
              <AiFillApple size={30} color="white" />
              <span
                style={{
                  color: "white",
                  marginLeft: "10pt",
                }}
              >
                Entrar com Apple
              </span>
            </button> */}
          <Footer>
            <span style={{ marginBottom: "7pt", marginTop: "7pt" }}>
              Ainda não tem conta?
              </span>

            <span style={{
              color: "white",
              marginBottom: "7pt",
              marginTop: "7pt",
              marginLeft: "5pt",
              fontWeight: "bold",
              cursor: "pointer",
              textDecoration: "none"
            }} onClick={() => {
              showLogin(false)
              showSignUp(true)
            }}>Cadastre-se.</span>
          </Footer>
        </LoginContainer>
      </div>


      <MobileLogin
        showLogin={(show) => showLogin(show)}
        showSignUp={(show) => showSignUp(show)}
        onEmailChanged={this.saveEmail.bind(this)}
        onPasswordChanged={this.savePassword.bind(this)}
        handleLogin={() => this.login()}
        handleFacebookLogin={this.props.loginRequest}
      />
      {loading && <Loading height={window.innerHeight} title="Carregando" subtitle="Loading" />}
      <Dialog
        open={this.state.openErrorDialog}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Erro no login</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Email e/ou Senha inválidos!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

    </MainContainer>)
  }

  saveEmail(event: any) {
    this.setState({ email: event.target.value })
  }

  savePassword(event: any) {
    this.setState({ password: event.target.value })
  }

  handleClickOpen = () => {
    this.setState({ openErrorDialog: true })
  };

  handleClose = () => {
    this.setState({ openErrorDialog: false })
    this.props.loginResetState()
  };


  login() {
    const { email, password } = this.state
    const { loginRequest } = this.props

    const user: User = {
      email,
      password,
    }

    loginRequest("password", user, null)
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  logged: state.user.logged,
  loading: state.user.loading,
  error: state.user.error,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loginRequest: (
      grant_type: string,
      user: User,
      access_token: string | null
    ) => dispatch(loginRequest(grant_type, user, access_token)),
    loginResetState: () => dispatch(loginResetState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
