import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import React from "react";

import ServiceRegister from "./screens/catalog/serviceRegister/ServiceRegister";
import ServiceRequest from "./screens/catalog/serviceRequest/ServiceRequest";
import Details from "./screens/catalog/catalogDetails/CatalogDetails";
import Register from "./screens/user/registerScreen/RegisterScreen";
import Catalog from "./screens/catalog/catalogScreen/CatalogScreen";
import Recover from "./screens/user/recoverScreen/RecoverScreen";
import Home from "./screens/home/HomeScreen";
import store from "./store";

import "./app.css"

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/service-register" component={ServiceRegister} />
        <Route path="/service-request" component={ServiceRequest} />
        <Route path="/venue-details/:venueId" exact={true} component={Home} />
        <Route path="/" exact={true} component={Home} />
        <Route path="/register" component={Register} />
        <Route path="/catalog" component={Catalog} />
        <Route path="/recover" component={Recover} />
        <Route path="/details" component={Details} />
      </Switch>
    </BrowserRouter>
  </Provider>
);

export default App;
