import React, { Component } from "react"
import { FiMenu, FiX } from "react-icons/fi"
import { GoSearch } from "react-icons/go"

import {
  HeaderContainer,
  Header,
  Logo,
  Navigation,
  MobileHeaderContainer,
  Menu
} from "./styles"

interface OwnProps {
  isSearchBarOpen: boolean
  searchChanged: boolean
  showSearchBar(): void
  showMenu(): void
}

class HeaderComponent extends Component<OwnProps> {
  render() {
    return (
      <MobileHeaderContainer>
        <HeaderContainer>
          <Header>
            <Menu>
              <FiMenu
                size={30}
                color={"rgb(48,48,48)"}
                style={{
                  cursor: "pointer"
                }}
                onClick={() => this.props.showMenu()} />
            </Menu>
            <Logo>
              <img
                onClick={() => {
                  window.open('https://guiaderodas.com')
                }}
                src={require("../../../resources/img/img_logo_guiaderodas.png")}
                alt="logo"
              />
            </Logo>
            <Navigation>
              {this.props.isSearchBarOpen ? (
                <FiX
                  style={{ cursor: "pointer" }}
                  size={25}
                  color={"rgb(48,48,48)"}
                  onClick={() => this.props.showSearchBar()} />) : (
                <div>
                  {this.props.searchChanged && <span style={{
                    padding: 1,
                    width: 5,
                    height: 5,
                    borderRadius: '50%',
                    backgroundColor: 'red',
                    position: 'absolute',
                    right: 20,
                    top: 30
                  }} />}
                  <GoSearch
                    style={{ cursor: "pointer" }}
                    size={25}
                    color={"rgb(48,48,48)"}
                    onClick={() => this.props.showSearchBar()} />
                </div>)}
            </Navigation>
          </Header>
        </HeaderContainer>
      </MobileHeaderContainer>)
  }
}


export default HeaderComponent
