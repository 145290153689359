import React, { Component } from "react"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { FaLessThan } from "react-icons/fa"
import { FiArrowLeft } from "react-icons/fi"
import { BsArrowRight } from "react-icons/bs"
import { connect } from "react-redux"
import { Dispatch } from "redux"

import Help from "../help/Help"
import { ApplicationState } from "../../store"
import {
  questionsRequest,
  reviewRequest,
  questionsResetState,
} from "../../store/ducks/review/actions"
import { Question } from "../../store/ducks/review/types"
import Loading from "../loading/LoadingScreen"
import {
  Alternative,
  AnswersContainer,
  ButtonNext,
  Container,
  InLineText,
  QuestionCard,
  QuestionContainer,
  RadioButton,
  RateContainer,
  Selected,
  TextQuestion,
  AnswerImg,
} from "./styles"
import MobileRate from "./mobile/MobileRate"

interface Answer {
  answer: any
  question_id: string
}

interface OwnProps {
  hideRate(hide: boolean): void
  showLogin(showLogin: boolean): void
  venueId: string | undefined
  categoryId: number | undefined
}

interface StateProps {
  questions: Array<Question[]>
  loading: boolean
  error: boolean
  sent: boolean
}

interface DispatchProps {
  questionsRequest(categoryId: number, venueId: string): void
  reviewRequest(answers: any, venueId: string): void
  questionsResetState(): void
}

type Props = OwnProps & StateProps & DispatchProps

class Rate extends Component<Props> {
  state = {
    currentQuestion: 0,
    answers: [],
    selectedOption: -1,
    text: "",
    showHelp: false,
    loadingTitle: "Carregado",
  };

  componentDidMount() {
    const {
      questionsRequest,
      questionsResetState,
      venueId,
      categoryId,
    } = this.props
    if (venueId && categoryId) {
      questionsResetState()
      questionsRequest(categoryId, venueId)
    }
  }

  render() {
    const { hideRate, questions, loading } = this.props
    const { currentQuestion, showHelp, loadingTitle } = this.state

    return (
      <Container>
        {window.innerWidth > 450 ? (<RateContainer>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {this.renderBackButton(currentQuestion)}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 10,
              }}
            >
              <AiOutlineCloseCircle
                onClick={() => hideRate(true)}
                size={35}
                color="white"
                style={{
                  right: 0,
                  alignItems: "right",
                  backgroundColor: "rgba(0,0,0,0.4)",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>

          {this.renderQuestions(questions[currentQuestion], currentQuestion)}
          {currentQuestion > 3 && this.renderFinalScreen()}
          {!loading && this.renderNextButton()}
          {this.renderHelpButton(currentQuestion)}
        </RateContainer>) : null}

        <MobileRate
          handleCloseClicked={() => hideRate(true)}
          handleBackClicked={() => {}}
          renderBackButton={this.renderBackButton(currentQuestion)}
          renderQuestions={this.renderQuestions(
            questions[currentQuestion],
            currentQuestion
          )}
          renderFinalScreen={currentQuestion > 3 && this.renderFinalScreen()}
          renderNextButton={!loading && this.renderNextButton()}
          renderHelpButton={!loading && this.renderHelpButton(currentQuestion)}
        />
        {loading && (
          <Loading
            height={window.innerHeight}
            title={loadingTitle}
            subtitle="Avaliação"
          />
        )}
        {showHelp && (
          <Help
            currentQuestion={currentQuestion}
            questions={questions}
            hideHelp={() => this.hideHelp()}
          />
        )}
      </Container>
    )
  }

  renderNextButton() {
    const {
      venueId,
      showLogin,
      questions,
      hideRate,
      reviewRequest,
      sent,
    } = this.props
    const { selectedOption, answers, text, currentQuestion } = this.state
    const question =
      questions[currentQuestion]?.length > 0
        ? questions[currentQuestion][0]
        : null
    const token = localStorage.getItem("AUTH_DATA")

    if (sent || (token && venueId && currentQuestion >= questions.length)) {
      return null
    }

    return (
      <ButtonNext
        onClick={() => {
          if (question?.type === "info" || selectedOption !== -1) {
            this.setState({ currentQuestion: currentQuestion + 1 })
          }
          if (currentQuestion === 3) {
            const newAnswers: Answer[] = answers
            newAnswers.push({
              answer: text,
              question_id: questions[3][0].id,
            })
            if (!sent && token && venueId) {
              this.setState({ loadingTitle: "Enviando" })
              reviewRequest(answers, venueId)
            }
          } else if (!sent && !token && currentQuestion >= questions.length) {
            showLogin(true)
          } else if (
            !sent &&
            token &&
            venueId &&
            currentQuestion >= questions.length
          ) {
            this.setState({ loadingTitle: "Enviando" })
            reviewRequest(answers, venueId)
            hideRate(true)
          } else if (sent && token) {
            hideRate(true)
          }
        }}
      >
        <span>Prosseguir</span>
        <BsArrowRight color="white" size={30} style={{ right: "42%" }} />{" "}
      </ButtonNext>
    )
  }

  hideHelp() {
    this.setState({ showHelp: false })
  }

  renderHelpButton(currentQuestion: number) {
    if (currentQuestion < 4) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "right",
            right: "26%",
            bottom: "6.5%",
            marginRight: 10,
            paddingRight: 10,
          }}
        >
          <span style={{ color: "rgba(0,0,0,0.6)" }}>Precisa de ajuda?</span>
          <span
            style={{ marginLeft: "5pt", color: "#3366BB", cursor: "pointer" }}
            onClick={() => this.setState({ showHelp: true })}
          >
            Clique aqui.
          </span>
        </div>
      )
    }
  }

  renderBackButton(currentQuestion: number) {
    if (currentQuestion > 0) {
      return (
        <div
          onClick={() =>
            this.setState({ currentQuestion: currentQuestion - 1 })
          }
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            // position: "relative",
            marginLeft: 10,
            cursor: "pointer",
          }}
        >
          {window.innerWidth > 450 ? (<>
            <FaLessThan size={15} color={"rgba(0,0,0,0.6)"} />
            <span
              style={{
                color: "rgba(0,0,0,0.6)",
                marginLeft: "5pt",
                fontSize: "14pt",
                fontWeight: "bold",
              }}
            >
              Voltar
          </span>
          </>) : (<FiArrowLeft
            style={{ cursor: "pointer", marginRight: 10 }}
            size={25}
            color={"rgb(48,48,48)"}
          />)}
        </div>
      )
    }
  }

  renderQuestions(questions: Question[], currentQuestion: number) {
    if (questions !== undefined) {
      switch (currentQuestion) {
        case 0:
          return this.renderRadioQuestion(questions[0])
        case 1:
          return this.renderMultipleQuestions(questions)
        case 2:
          return this.renderMultipleQuestions(questions)
        case 3:
          return this.renderTextQuestion(questions[0])
      }
    }
  }

  renderFinalScreen() {
    const token = localStorage.getItem("AUTH_DATA")

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          textAlign: "center",
          width: "60%",
        }}
      >
        <span
          style={{
            marginBottom: 30,
            fontSize: 28,
            fontWeight: "bold",
            color: "rgba(0,0,0,0.8)",
          }}
        >
          Muito Obrigado!
        </span>
        <img
          src={require("../../resources/icons/group.png")}
          alt="happy_face"
          style={{
            width: window.innerWidth > 450 ? 250: 150,
            height: window.innerWidth > 450 ? 250: 150,
            borderRadius: "50%",
          }}
        />
        <span
          style={{
            marginTop: 30,
            fontSize: 18,
            fontWeight: "bold",
            color: "rgba(0,0,0,0.8)",
          }}
        >
          Agradecemos sua participação!
        </span>
        <span
          style={{
            marginTop: 10,
            fontSize: 14,
            fontWeight: "bold",
            color: "rgba(0,0,0,0.8)",
          }}
        >
          {token
            ? ""
            : "Para enviar sua avaliação, você precisa logar. Clique em prosseguir para fazer o login ou criar sua conta."}
        </span>
      </div>
    )
  }

  renderTextQuestion(question: Question) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          marginTop: 10,
          padding: 10,
        }}
      >
        <h1
          style={{
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          {question.text}
        </h1>
        <QuestionCard>
          <InLineText>
            <span>Avaliação</span>
          </InLineText>
          <TextQuestion
            placeholder="Escreva aqui sua resposta."
            onChange={this.saveText.bind(this)}
          />
        </QuestionCard>
      </div>
    )
  }

  saveText(event: any) {
    this.setState({ text: event.target.value })
  }

  renderMultipleQuestions(questions: Question[]) {
    const answer1 = require("../../resources/icons/answer_1.png")
    const answer3 = require("../../resources/icons/answer_3.png")
    const answer5 = require("../../resources/icons/answer_5.png")

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 40,
          marginBottom: 10,
          flex: 1,
        }}
      >
        <QuestionCard>
          {questions.map((question) => {
            const answerColor = this.getAnswerColor(question.id)
            const backgroundColor = (answerNumber: number) =>
              answerColor?.answer === answerNumber
                ? answerColor?.color
                : undefined
            return (
              <QuestionContainer key={question.id}>
                <span>{question.text}</span>
                <AnswersContainer>
                  <AnswerImg
                    onClick={() => this.saveAnswer(1, question.id)}
                    src={answer1}
                    alt="pin"
                    backgroundColor={`${backgroundColor(1)}`}
                    hoverBackgroundColor="#DD352F"
                  />
                  <AnswerImg
                    onClick={() => this.saveAnswer(3, question.id)}
                    src={answer3}
                    alt="pin"
                    backgroundColor={`${backgroundColor(3)}`}
                    hoverBackgroundColor="#FFDB24"
                  />
                  <AnswerImg
                    onClick={() => this.saveAnswer(5, question.id)}
                    src={answer5}
                    alt="pin"
                    backgroundColor={`${backgroundColor(5)}`}
                    hoverBackgroundColor="#00A652"
                  />
                </AnswersContainer>
              </QuestionContainer>
            )
          })}
        </QuestionCard>
      </div>
    )
  }

  getAnswerColor(questionId: string) {
    const { answers } = this.state
    let colorCode: number = 0

    if (answers.length > 0) {
      answers.forEach((answer: Answer) => {
        if (answer.question_id === questionId) {
          colorCode = answer.answer
        }
      })
    }

    switch (colorCode) {
      case 0:
        return { answer: colorCode, color: null }
      case 1:
        return { answer: colorCode, color: "#DD352F" }
      case 3:
        return { answer: colorCode, color: "#FFDB24" }
      case 5:
        return { answer: colorCode, color: "#00A652" }
    }
  }

  renderRadioQuestion(question: Question) {
    const { selectedOption } = this.state

    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 20,
            textAlign: "center",
          }}
        >
          <span
            style={{
              color: "rgba(0,0,0,0.8)",
              fontWeight: "bold",
              fontSize: 28,
            }}
          >
            {question.text}
          </span>
        </div>
        <QuestionCard>
          {question.alternatives?.map((alternative, index) => {
            return (
              <Alternative
                key={index}
                onClick={() => {
                  if (question.alternatives) {
                    this.saveAnswer(
                      question.alternatives[index].value,
                      question.id
                    )
                    this.setState({ selectedOption: index })
                  }
                }}
              >
                <RadioButton>
                  {index === selectedOption && <Selected />}
                </RadioButton>
                {this.renderPin(index)}
                <span style={{ width: 260, marginLeft: 24 }}>
                  {alternative.text}
                </span>
              </Alternative>
            )
          })}
        </QuestionCard>
      </div>
    )
  }

  renderPin(index: number) {
    const redPin = require("../../resources/icons/PIN-Vermelho.png")
    const yellowPin = require("../../resources/icons/PIN-Amarelo.png")
    const greenPin = require("../../resources/icons/PIN-Verde.png")

    const style = { width: "35px", height: "50px" }
    switch (index) {
      case 0:
        return <img src={greenPin} alt="pin" style={style} />
      case 1:
        return <img src={yellowPin} alt="pin" style={style} />
      case 2:
        return <img src={redPin} alt="pin" style={style} />
    }
  }

  saveAnswer(newAnswer: number, questionId: string) {
    const { answers } = this.state
    let newAnswers: Answer[] = answers

    let answerIndex = newAnswers.findIndex(
      (answer) => answer.question_id === questionId
    )

    if (answerIndex > 0) {
      newAnswers[answerIndex].answer = newAnswer
    } else {
      newAnswers.push({ answer: newAnswer, question_id: questionId })
    }

    this.setState({ answers: newAnswers })
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  questions: state.review.data,
  loading: state.review.loading,
  error: state.review.error,
  sent: state.review.sent,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    questionsRequest: (categoryId: number, venueId: string) =>
      dispatch(questionsRequest(categoryId, venueId)),
    reviewRequest: (answers: any, venueId: string) =>
      dispatch(reviewRequest(answers, venueId)),
    questionsResetState: () => dispatch(questionsResetState()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rate)
