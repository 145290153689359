import React, { Component } from "react"
import {
  AiOutlineCloseCircle
} from "react-icons/ai"
import bgImage from "../../resources/img/venue_placeholder.png"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import { FiMail, FiUnlock, FiUser } from "react-icons/fi"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import MobileSignUp from "./mobile/MobileSignUp"
import Loading from "../loading/LoadingScreen"
import { signUpRequest, signUpResetState } from "../../store/ducks/user/actions"
import { User } from "../../store/ducks/user/types"
import { ApplicationState } from "../../store"
import {
  Container,
  InputsContainer,
  Input,
  ButtonsContainer,
  Button,
  SignUpContainer,
  TermsContainer,
  CloseContainer
} from "./styles"

interface OwnProps {
  showLogin(show: boolean): void
  showSignUp(show: boolean): void
}

interface StateProps {
  registered: boolean
  loading: boolean
  error: boolean
  errorMessage: string
}

interface DispatchProps {
  signUpRequest(user: User): void
  signUpResetState(): void
}

type Props = StateProps & DispatchProps & OwnProps

class SignUp extends Component<Props> {

  state = {
    name: "",
    lastName: "",
    email: "",
    password: "",
    acceptedTermsAndPrivacy: false,
    openErrorDialog: false
  };

  componentDidUpdate() {
    const { error } = this.props

    if (error && !this.state.openErrorDialog) {
      this.handleClickOpenErrorDialog()
    }
  }

  render() {
    const { showLogin, showSignUp, loading, registered } = this.props

    return (
      <Container>
        <div
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            borderRadius: "4pt",
          }}
        >
          <SignUpContainer>

          <CloseContainer>
            <AiOutlineCloseCircle
              onClick={() => {
                showSignUp(false)
                showLogin(true)
              }}
              size={35}
              color="white"
            />
          </CloseContainer>
            <span style={{
              width: '80%',
              fontSize: 30,
              fontWeight: 500,
            }}>Preencha suas informações abaixo.</span>
            <InputsContainer>
              <Input>
                <FiUser size={30} color="gray" />
                <input
                  type="text"
                  placeholder="Nome"
                  onChange={this.saveName.bind(this)}
                />
              </Input>
              <Input>
                <FiUser size={30} color="gray" />
                <input
                  type="text"
                  placeholder="Sobrenome"
                  onChange={this.saveLastName.bind(this)}
                />
              </Input>
              <Input>
                <FiMail size={30} color="gray" />
                <input
                  type="email"
                  placeholder="Email"
                  onChange={this.saveEmail.bind(this)}
                />
              </Input>
              <Input>
                <FiUnlock size={30} color="gray" />
                <input
                  type="password"
                  placeholder="Senha"
                  onChange={this.savePassword.bind(this)}
                />
              </Input>
              <TermsContainer>
                <input
                  type="checkbox"
                  name="terms"
                  onChange={this.updateTermsAndPrivacy.bind(this)}></input>
                <span style={{
                  marginLeft: 10,
                  fontSize: 18,
                  fontWeight: 800,
                }}>Li e concordo com os termos da politica de&nbsp;</span>
                <a href="#/"
                  onClick={() => {
                    window.open('https://guiaderodas.com/politica-de-privacidade')
                  }} >Privacidade Guiaderodas</a><br />
              </TermsContainer>
            </InputsContainer>
            <ButtonsContainer>
              <Button
                disabled={this.buttonDisabled()}
                onClick={() => this.signUp()}
              >
                Cadastrar-se
            </Button>
            </ButtonsContainer>
          </SignUpContainer>

        </div>
        <MobileSignUp
          buttonDisabled={this.buttonDisabled()}
          handleCloseClicked={() => {
            showSignUp(false)
            showLogin(true)
          }}
          handleSignUpClicked={() => this.signUp()}
          onNameChanged={this.saveName.bind(this)}
          onLastNameChanged={this.saveLastName.bind(this)}
          onEmailChanged={this.saveEmail.bind(this)}
          onPasswordChanged={this.savePassword.bind(this)}
          onTermsAndPrivacyChanged={this.updateTermsAndPrivacy.bind(this)}

        />
        {loading && <Loading height={window.innerHeight} title="Carregando" subtitle="Loading" />}
        <Dialog
          open={this.state.openErrorDialog}
          onClose={this.handleClickCloseErrorDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Ocorreu um erro no cadastro</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.getErrorMessage()}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickCloseErrorDialog} color="primary" autoFocus>
              OK
          </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={registered}
          onClose={this.handleCloseRegisteredDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Sucesso!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Seu cadastro foi realizado com sucesso! Agora você pode fazer o login com sua nova conta.
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseRegisteredDialog} color="primary" autoFocus>
              OK
          </Button>
          </DialogActions>
        </Dialog>

      </Container>
    )
  }

  getErrorMessage = () => {
    if ('Account already exists for this email.' === this.props.errorMessage) {
      return 'Uma conta já existe para este e-mail'
    }
    return 'Email e/ou Senha inválidos!'
  }


  handleClickOpenErrorDialog = () => {
    this.setState({ openErrorDialog: true })
  };

  handleClickCloseErrorDialog = () => {
    this.props.signUpResetState()
    this.setState({ openErrorDialog: false })
  };

  handleCloseRegisteredDialog = () => {
    const { showLogin, showSignUp, signUpResetState } = this.props
    signUpResetState()
    showSignUp(false)
    showLogin(true)
  };

  buttonDisabled(): boolean {
    const { name, lastName, email, password, acceptedTermsAndPrivacy } = this.state

    if (
      name.trim().length > 0 &&
      lastName.trim().length > 0 &&
      email.trim().length > 0 &&
      password.trim().length > 0 &&
      acceptedTermsAndPrivacy === true
    ) {
      return false
    }

    return true
  }

  signUp() {
    const { name, lastName, email, password, acceptedTermsAndPrivacy } = this.state
    const { signUpRequest } = this.props

    const user: User = {
      first_name: name,
      last_name: lastName,
      privacy_policy_accepted: acceptedTermsAndPrivacy,
      email,
      password,
    }

    signUpRequest(user)
  }

  saveName(event: any) {
    this.setState({ name: event.target.value })
  }

  saveLastName(event: any) {
    this.setState({ lastName: event.target.value })
  }

  saveEmail(event: any) {
    this.setState({ email: event.target.value })
  }

  savePassword(event: any) {
    this.setState({ password: event.target.value })
  }

  updateTermsAndPrivacy(event: any) {
    this.setState({ acceptedTermsAndPrivacy: event.target.checked })
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  registered: state.user.registered,
  loading: state.user.loading,
  error: state.user.error,
  errorMessage: state.user.errorMessage,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    signUpRequest: (user: User) => dispatch(signUpRequest(user)),
    signUpResetState: () => dispatch(signUpResetState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
