import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(249, 249, 249);
`

export const Container = styled.div`
  display: none;
  background-color: rgba(249, 249, 249);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  @media(max-width: 450px) {
    display: flex;
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(249, 249, 249);
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;

  img {
    width: 50px;
    height: 70px;
  }

  input {
    width: 24vw;
    min-width: 270px;
    padding: 10px;
    border: 1px solid rgb(200, 200, 200);
    border-radius: 4px;
    cursor: pointer;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24vw;
    min-width: 290px;
    height: 44px;
    border: 0px none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: white;
  width: 100%;
  background-color: rgb(0, 187, 55);
  position: absolute;
  bottom: 130px;
`;


export const SignUp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: white;
  min-width: 280px;
  margin-top: 10px;
  background-color: rgb(0, 187, 55);
`;
