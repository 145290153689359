import { call, put } from "redux-saga/effects";

import api from "../../../services/api";
import {
  sendServiceSuccess,
  sendServiceFailure,
  sendRequisitionSuccess,
  sendRequisitionFailure,
} from "./actions";

export function* sendService(action: any) {
  try {
    const response = yield call(api.post, "svc/items", action.service, {
      headers: {
        Authorization: "Bearer " + action.token,
      },
    });
    yield put(sendServiceSuccess(response.data));
  } catch (err) {
    yield put(sendServiceFailure());
  }
}

export function* sendRequisition(action: any) {
  try {
    const response = yield call(api.post, "feedback", action.requisition, {
      headers: {
        Authorization: "Bearer " + action.token,
      },
    });
    yield put(sendRequisitionSuccess(response.data));
  } catch (err) {
    yield put(sendRequisitionFailure());
  }
}
