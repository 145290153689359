import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 5;
  position: fixed;
  justify-content: center;
  align-items: center;  
`

export const RateContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(242, 242, 242);
  width: 50vw;
  height: 85vh;
  border-radius: 4pt;
  justify-content: space-between;
  align-items: center;
  padding-top: 20pt;
  padding-bottom: 20pt;
  @media(max-height: 500px){
    height: 350px;
    overflow: scroll;
  }
  @media(max-height: 600px) and (min-height: 500px){
    height: 430px;
    overflow: scroll;
  }
  @media(max-height: 700px) and (min-height: 600px){
    height: 530px;
    overflow: scroll;
  }
  @media(max-width: 800px) and (min-width: 700px) {
    width: 100vw;
    overflow: scroll;
  }
`

export const QuestionCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(242, 242, 242);
  border-radius: 5px;
  padding: 20px;

  @media(max-width: 450px){ 
    width: 100%;
    padding: 0px;
    margin: 0px;
  }
`

export const Alternative = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 15px;
  cursor: pointer;
  &:hover{
    background-color: rgb(200,200,200, 0.4);
  }
  @media(max-width: 350px) {
    width: 300px;
    margin: 0px;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
  }

  @media(max-width: 450px) and (min-width: 350px) {
    width: 350px;
    margin: 0px;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
  }
`

export const RadioButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid gray;
  margin-left: 20px;
  margin-right: 15px;
  @media(max-width: 450px) {
    height: 22px;
  }
`

export const Selected = styled.div`
  width: 10pt;
  height: 10pt;
  border-radius: 50%;
  background-color: gray;
`

export const ButtonNext = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15vw;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #d90368;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;

  span {
    color: white;
    font-size: 16pt;
  }

  @media(max-width: 450px) {
    span {
      font-size: 12pt;
    }
  }

`

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  text-align: center;
  margin-top: 20px;

  span {
    font-weight: bold;
    font-size: 14pt;
    color: rgba(0, 0, 0, 0.8);
  }
`

export const AnswersContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`

export const TextQuestion = styled.textarea`
  display: flex;
  width: 30vw;
  height: 40vh;
  resize: none;
  background-color: rgba(242, 242, 242);
  border: 1px solid rgb(200, 200, 200);
  padding: 10pt;
  border-radius: 4pt;
  @media(max-width: 450px) and (min-width: 300px) {
    width: 80vw;
  }
  @media(max-width: 800px) and (min-width: 700px) {
    width: 80vw;
  }
  @media(max-width: 580px) and (min-width: 500px) {
    width: 80vw;
  }
  @media(max-width: 300px) and (min-width: 200px) {
    width: 80vw;
  }
`

export const InLineText = styled.div`
  display: flex;
  width: 30px;
  background-color: rgba(242, 242, 242);
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -10.5pt;
  margin-left: 30pt;
  z-index: 2;

  span {
    font-size: 10pt;
    color: rgba(0,0,0,0.6);
    background-color: rgba(242, 242, 242);
    padding: 5pt;
  }
`

interface AnswerImgProps {
  backgroundColor: string
  hoverBackgroundColor?: string | null
}

export const AnswerImg = styled.img<AnswerImgProps>`
  width: 40pt;
  height: 40pt;
  cursor: pointer;
  border-radius: 20pt;
  background-color:  ${props => props.backgroundColor || 'rgb(200,200,200, 0.2)'};
  &:hover{
    background-color: ${props => props.hoverBackgroundColor || 'rgb(200,200,200, 0.2)'};
  }
`
