import styled from "styled-components"


export const HeaderContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 55px;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  border-bottom: 1px solid rgb(240, 240, 240);
`

export const Container = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  background-color:  rgb(249, 249, 249);
  justify-content: center;
  align-items: center;

  @media(max-width: 450px) {
    display: flex;
  }
`

export const ProfileModal = styled.div`
  display: flex;
  flex-direction: column;
  background-color:  rgb(249, 249, 249);
  margin-top: 100px;
  width: 100%;
  height: 100%;
`

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 20px;
  margin-top: 5px;
  border-top: 1px solid rgb(238, 238, 238);
  border-bottom: 1px solid rgb(238, 238, 238);
`

export const UserInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const EditButton = styled.button`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9036855;
  background-color:  rgb(249, 249, 249);
  color: #d90368;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14pt;
  cursor: pointer;
`
