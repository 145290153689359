import { action } from "typesafe-actions";
import { ReviewTypes, Question } from "./types";

export const questionsResetState = () => ({
  type: ReviewTypes.QUESTIONS_RESET_STATE
})

export const questionsRequest = (categoryId: number, venueId: string) => ({
  type: ReviewTypes.QUESTIONS_REQUEST,
  categoryId,
  venueId,
});

export const questionsSuccess = (data: Array<Question[]>) =>
  action(ReviewTypes.QUESTIONS_SUCCESS, { data });

export const questionsFailure = () => action(ReviewTypes.QUESTIONS_FAILURE);

export const reviewRequest = (answers: any, venueId: string) => ({
  type: ReviewTypes.REVIEW_REQUEST,
  answers,
  venueId,
});

export const reviewSuccess = () => action(ReviewTypes.REVIEW_SUCCESS);

export const reviewFailure = () => action(ReviewTypes.REVIEW_FAILURE);
