import { all, takeLatest } from "redux-saga/effects";

import {
  LoginTypes,
  SignUpTypes,
  UpdateTypes,
  RecoverTypes,
  FetchUserTypes,
} from "./user/types";
import { login, signUp, recover, fetchUser, updateUser, acceptPrivacyPolicy } from "./user/sagas";
import { loadVenues, searchVenues, loadSingleVenue } from "./venues/sagas";
import { ServiceTypes, RequisitionTypes } from "./service/types";
import { questionsRequest, reviewRequest } from "./review/sagas";
import { sendService, sendRequisition } from "./service/sagas";
import { VenuesTypes, SearchTypes } from "./venues/types";
import { CategoriesTypes } from "./categories/types";
import { loadCategories } from "./categories/sagas";
import { LocationsTypes } from "./locations/types";
import { loadStates, loadCities } from "./locations/sagas";
import { CompaniesTypes } from "./companies/types";
import { loadCompanies } from "./companies/sagas";
import { FeedbackTypes } from "./feedback/types";
import { sendFeedback } from "./feedback/sagas";
import { ReviewTypes } from "./review/types";

export default function* rootSaga() {
  return yield all([
    takeLatest(RequisitionTypes.REQUISITION_REQUEST, sendRequisition),
    takeLatest(CategoriesTypes.CATEGORIES_REQUEST, loadCategories),
    takeLatest(LocationsTypes.STATES_REQUEST, loadStates),
    takeLatest(LocationsTypes.CITIES_REQUEST, loadCities),
    takeLatest(ReviewTypes.QUESTIONS_REQUEST, questionsRequest),
    takeLatest(VenuesTypes.SINGLE_VENUE_REQUEST, loadSingleVenue),
    takeLatest(CompaniesTypes.COMPANY_REQUEST, loadCompanies),
    takeLatest(FeedbackTypes.FEEDBACK_REQUEST, sendFeedback),
    takeLatest(FetchUserTypes.FETCH_USER_REQUEST, fetchUser),
    takeLatest(UpdateTypes.USER_UPDATE_REQUEST, updateUser),
    takeLatest(ReviewTypes.REVIEW_REQUEST, reviewRequest),
    takeLatest(ServiceTypes.SERVICE_REQUEST, sendService),
    takeLatest(SearchTypes.SEARCH_REQUEST, searchVenues),
    takeLatest(VenuesTypes.VENUES_REQUEST, loadVenues),
    takeLatest(RecoverTypes.RECOVER_REQUEST, recover),
    takeLatest(SignUpTypes.SIGNUP_REQUEST, signUp),
    takeLatest(LoginTypes.LOGIN_REQUEST, login),
    takeLatest(UpdateTypes.USER_ACCEPT_PRIVACY_POLICY_REQUEST, acceptPrivacyPolicy),
  ]);
}
