import React from "react"
import { FiX, FiArrowLeft } from "react-icons/fi"
import { UserInfos } from "../../../store/ducks/user/types"
import {
  ContentInput,
  FeedbackContainer,
  FeedbackModal,
  RatingContainer,
  SendButton,
  StarsContainer,
  SubjectInput,
  EmailInput,
  StarIcon,
  Container,
  HeaderContainer
} from "./styles"

interface OwnProps {
  user: UserInfos | null
  reviewStarsIcon: any
  shouldDisableButton: boolean
  handleContentChanged(event: any): void
  handleEmailChanged(event: any): void
  handleSubjectChanged(event: any): void
  handleRatingChanged(postion: number): void
  handleSendClicked(): void
  handleBackClicked(): void
  handleCloseClicked(): void
}

export default ({
  user,
  reviewStarsIcon,
  shouldDisableButton,
  handleContentChanged,
  handleEmailChanged,
  handleSubjectChanged,
  handleRatingChanged,
  handleSendClicked,
  handleBackClicked,
  handleCloseClicked
}: OwnProps) => {
  return (
    <Container>
      <HeaderContainer>
        <div
          style={{
            marginLeft: 10,
            display: "flex", flexDirection: "row",
            alignItems: 'center', justifyContent: 'center'
          }}
          onClick={handleBackClicked}>
          <FiArrowLeft
            style={{ cursor: "pointer", marginRight: 10 }}
            size={25}
            color={"rgb(48,48,48)"} />
          <span>Feedback</span>
        </div>

        <FiX
          style={{ cursor: "pointer", position: 'fixed', right: 15, top: 15 }}
          size={25}
          color={"rgb(48,48,48)"}
          onClick={handleCloseClicked} />
      </HeaderContainer>
      <FeedbackModal>
        <RatingContainer>
          <span>Que nota você dá para o Guiaderodas?</span>
          <StarsContainer>
            <StarIcon
              src={reviewStarsIcon[0]}
              onClick={() => handleRatingChanged(0)}
            />
            <StarIcon
              src={reviewStarsIcon[1]}
              onClick={() => handleRatingChanged(1)}
            />
            <StarIcon
              src={reviewStarsIcon[2]}
              onClick={() => handleRatingChanged(2)}
            />
            <StarIcon
              src={reviewStarsIcon[3]}
              onClick={() => handleRatingChanged(3)}
            />
            <StarIcon
              src={reviewStarsIcon[4]}
              onClick={() => handleRatingChanged(4)}
            />
          </StarsContainer>
        </RatingContainer>
        <FeedbackContainer>
          <span>Envie-nos seu Feedback</span>
          <SubjectInput
            type="text"
            placeholder="Assunto"
            onChange={handleSubjectChanged}
          />
          {!user?.email ? <EmailInput
            type="email"
            placeholder="email@email.com"
            onChange={handleEmailChanged}
            style={{ marginBottom: 10 }}
          /> : null}
          <ContentInput
            placeholder="Mensagem"
            onChange={handleContentChanged}
          />
        </FeedbackContainer>
        <SendButton
          disabled={shouldDisableButton}
          onClick={handleSendClicked}
        >
          Enviar
        </SendButton>
      </FeedbackModal>

    </Container>)
}
