import React, { Component } from "react";

import { Container, Alert, Title, Message, Button } from "./styles";

interface OwnProps {
  title: String;
  message: String;
  textButton: String;
  cancelTextButton?: String;
  height?: number;
  width?: number;
  onOKClicked(): void;
  onCancelClicked?(): void;
}

type Props = OwnProps;

export default class AlertComponent extends Component<Props> {
  render() {
    const { message, title, width, height, textButton, cancelTextButton, onCancelClicked } = this.props;

    return (
      <Container height={height} width={width}>
        <Alert>
          <Title>{title}</Title>
          <Message>{message}</Message>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={() => onCancelClicked && onCancelClicked()}>{cancelTextButton}</Button>
            <Button onClick={() => this.props.onOKClicked()}>{textButton}</Button>
          </div>
        </Alert>
      </Container>
    );
  }
}
