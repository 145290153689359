import { action } from "typesafe-actions"
import {
  FetchUserTypes,
  RecoverTypes,
  LogoutTypes,
  SignUpTypes,
  UpdateTypes,
  LoginTypes,
  User,
} from "./types"

export const loginRequest = (
  grant_type: string,
  user: User | null,
  access_token: string | null
) => ({
  type: LoginTypes.LOGIN_REQUEST,
  grant_type,
  user,
  access_token,
})

export const loginResetState = () => ({
  type: LoginTypes.LOGIN_RESET_STATE
})

export const loginSuccess = (data: any) =>
  action(LoginTypes.LOGIN_SUCCESS, data)

export const loginFailure = () => action(LoginTypes.LOGIN_FAILURE)

export const signUpResetState = () => ({
  type: SignUpTypes.SIGNUP_RESET_STATE
})

export const signUpRequest = (user: User) => ({
  type: SignUpTypes.SIGNUP_REQUEST,
  user,
})

export const signUpSuccess = (status: number) =>
  action(SignUpTypes.SIGNUP_SUCCESS, status)

export const signUpFailure = (errorMessage: string) => action(SignUpTypes.SIGNUP_FAILURE, errorMessage)

export const recoverPasswordRequest = (email: string) => ({
  type: RecoverTypes.RECOVER_REQUEST,
  email,
})

export const recoverPasswordSuccess = (status: number) =>
  action(RecoverTypes.RECOVER_SUCCESS, status)

export const recoverPasswordFailure = () =>
  action(RecoverTypes.RECOVER_FAILURE)

export const logOut = () => action(LogoutTypes.LOGOUT_REQUEST)

export const fetchUserRequest = (access_token: string) => ({
  type: FetchUserTypes.FETCH_USER_REQUEST,
  access_token,
})

export const fetchUserSuccess = (data: any) =>
  action(FetchUserTypes.FETCH_USER_SUCCESS, data)

export const fetchUserFailure = () => action(FetchUserTypes.FETCH_USER_FAILURE)

export const userUpdateRequest = (userInfo: any) => ({
  type: UpdateTypes.USER_UPDATE_REQUEST,
  userInfo,
})

export const userUpdateSuccess = (data: any) =>
  action(UpdateTypes.USER_UPDATE_SUCCESS, data)

export const userUpdateFailure = () => action(UpdateTypes.USER_UPDATE_FAILURE)


export const userAcceptPrivacyPolicyRequest = () => ({
  type: UpdateTypes.USER_ACCEPT_PRIVACY_POLICY_REQUEST
})

export const userAcceptPrivacyPolicySuccess = (data: any) =>
  action(UpdateTypes.USER_ACCEPT_PRIVACY_POLICY_SUCCESS, data)

export const userAcceptPrivacyPolicyFailure = () => action(UpdateTypes.USER_ACCEPT_PRIVACY_POLICY_FAILURE)
