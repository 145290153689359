export enum CategoriesTypes {
    CATEGORIES_REQUEST = "@categories/CATEGORIES_REQUEST",
    CATEGORIES_SUCCESS = "@categories/CATEGORIES_SUCCESS",
    CATEGORIES_FAILURE = "@categories/CATEGORIES_FAILURE",
}

export interface Categories {
    category_id: number;
    name: string;
    image_urls: ImageUrls
}

interface ImageUrls {
    default: string;
}

export interface CategoriesState {
    readonly data: any;
    readonly error: boolean;
}