import React from "react"
import {
  LinksContainer,
  UserContent,
  ProfileContainer,
  LogInOutContainer
} from "./styles"

interface Props {
  history: any
  user: any
  urlSearch: any
  showMenu: () => void
  onLogoutClicked: () => void
  onProfileClicked: () => void
  onLoginClicked: () => void
}

export default ({
  history,
  urlSearch,
  user,
  showMenu,
  onLogoutClicked,
  onProfileClicked,
  onLoginClicked
}: Props) => {

  return (<div>
    <ProfileContainer >
      <img
        src={require("../../../resources/img/img_logo_guiaderodas.png")}
        alt="logo"
      />
      {user && <UserContent >
        <span id="full-name">{`${user?.first_name} ${user?.last_name}`}</span>
        <span id="email">{user?.email}</span>
        <span id="my-profile" onClick={() => onProfileClicked()}>MEU MENU</span>
      </UserContent>}
    </ProfileContainer>
    <LinksContainer>
      <span
        onClick={() => {
          showMenu()
          history.push('/' + urlSearch)
        }}
      >ONDE IR</span>

      <span
        onClick={() => {
          showMenu()
          window.open('https://guiaderodas.com/quem-somos/')
        }}>QUEM SOMOS</span>

       <span
        onClick={() => {
          window.open('https://guiaderodas.com/seja-destaque-no-portal-guiaderodas-com/')
          showMenu()
        }}>SEJA DESTAQUE</span>

      <span
        onClick={() => {
          window.open('https://guiaderodas.com/certificacao-guiaderodas/')
          showMenu()
        }}>CERTIFICAÇÃO</span>

      <span
        onClick={() => {
          window.open('https://guiaderodas.com/treinamento/')
          showMenu()
        }}>TREINAMENTO</span>

      <span
        onClick={() => {
          window.open('https://guiaderodas.com/ideiaboaparatodos/')
          showMenu()
        }}>BLOG IDEIA BOA</span>

      <span
        onClick={() => {
          window.open('https://guiaderodas.com/contato/')
          showMenu()
        }}>CONTATO</span>
    </LinksContainer>

    <LogInOutContainer>{user?.first_name ? (<span
      onClick={() => {
        onLogoutClicked()
      }}>LOGOUT</span>) :
      (<span
        onClick={() => {
          onLoginClicked()
        }}>LOGIN</span>)}
        </LogInOutContainer>

  </div>)
}
