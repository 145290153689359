import { call, put } from "redux-saga/effects";

import api from "../../../services/api";
import { statesSuccess, statesFailure, citiesSuccess, citiesFailure } from "./actions";

export function* loadStates() {
  try {
    const params = "?locale=pt-br";
    const response = yield call(api.get, "locations/states" + params);
    if (response.data.states){
      yield put(statesSuccess(response.data.states));
    }
    else {
      throw(new Error('No states'))
    }
  } catch (err) {
    yield put(statesFailure());
  }
}

export function* loadCities(action: any) {
  try {
    const stateUF = action.stateUF;
    const params = "?locale=pt-br&state="+stateUF;
    const response = yield call(api.get, "locations/cities" + params);
    if (response.data.cities){
      yield put(citiesSuccess(response.data.cities));
    }
    else {
      throw(new Error('No cities'))
    }
  } catch (err) {
    yield put(citiesFailure());
  }
}