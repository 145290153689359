export enum CompaniesTypes {
  COMPANY_REQUEST = "@companys/COMPANY_REQUEST",
  COMPANY_SUCCESS = "@companys/COMPANY_SUCCESS",
  COMPANY_FAILURE = "@companys/COMPANY_FAILURE",
}

export interface Company {
  name: string;
  category_name: string;
  state: string;
  city: string;
  contact: Contact;
  coords?: Coords;
  info: Info;
}

export interface Contact {
  address: string;
  email: string;
  phone: string;
  website: string;
  whatsapp: string;
}

export interface Coords {
  lat: number;
  lng: number;
}

export interface Info {
  area: string;
  description: string;
  other_info: string;
}

export interface CompaniesState {
  readonly data: Company[];
  readonly loading: boolean;
  readonly error: boolean;
}
