import { Reducer } from "redux"

import {
  UserState,
  LoginTypes,
  SignUpTypes,
  LogoutTypes,
  UpdateTypes,
  RecoverTypes,
  FetchUserTypes,
} from "./types"

const INITIAL_STATE: UserState = {
  logged: false,
  registered: false,
  sent: false,
  error: false,
  loading: false,
  tokens: null,
  user: null,
  errorMessage: '',
}

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LoginTypes.LOGIN_REQUEST:
      return { ...state, loading: true }
    case LoginTypes.LOGIN_SUCCESS:
      localStorage.setItem("AUTH_DATA", action.payload.token.access_token)
      return {
        ...state,
        loading: false,
        logged: true,
        error: false,
        tokens: {
          access_token: action.payload.token.access_token,
          refresh_token: action.payload.token.refresh_token,
        },
        user: action.payload,
      }
    case LoginTypes.LOGIN_FAILURE:
      return { ...state, loading: false, error: true }
    case LoginTypes.LOGIN_RESET_STATE:
      return INITIAL_STATE
    case SignUpTypes.SIGNUP_RESET_STATE:
      return INITIAL_STATE
    case SignUpTypes.SIGNUP_REQUEST:
      return { ...state, loading: true }
    case SignUpTypes.SIGNUP_SUCCESS:
      return { ...state, loading: false, registered: true }
    case SignUpTypes.SIGNUP_FAILURE:
      return { ...state, loading: false, error: true, errorMessage: action.payload }
    case RecoverTypes.RECOVER_REQUEST:
      return { ...state, loading: true }
    case RecoverTypes.RECOVER_SUCCESS:
      return { ...state, loading: false, sent: true, error: false }
    case RecoverTypes.RECOVER_FAILURE:
      return { ...state, loading: false, error: true }
    case LogoutTypes.LOGOUT_REQUEST:
      localStorage.removeItem("AUTH_DATA")
      return { ...state, logged: false, user: null }
    case FetchUserTypes.FETCH_USER_REQUEST:
      return { ...state, loading: true }
    case FetchUserTypes.FETCH_USER_SUCCESS:
      return { ...state, loading: false, error: false, user: action.payload }
    case FetchUserTypes.FETCH_USER_FAILURE:
      localStorage.removeItem("AUTH_DATA")
      return { ...state, loading: false, error: true }
    case UpdateTypes.USER_UPDATE_REQUEST:
      return { ...state, loading: true, error: false }
    case UpdateTypes.USER_UPDATE_SUCCESS:
      return { ...state, loading: false, error: false, user: action.payload }
    case UpdateTypes.USER_UPDATE_FAILURE:
      return { ...state, loading: false, error: true }

    case UpdateTypes.USER_ACCEPT_PRIVACY_POLICY_REQUEST:
      return { ...state, loading: true, error: false }
    case UpdateTypes.USER_ACCEPT_PRIVACY_POLICY_SUCCESS:
      return { ...state, loading: false, error: false, user: action.payload }
    case UpdateTypes.USER_ACCEPT_PRIVACY_POLICY_FAILURE:
      return { ...state, loading: false, error: true }
    default:
      return state
  }
}

export default reducer
