import { action } from "typesafe-actions";
import { LocationsTypes, State } from "./types";

export const statesRequest = () => action(LocationsTypes.STATES_REQUEST);

export const statesSuccess = (data: State[]) => action(LocationsTypes.STATES_SUCCESS, { data });

export const statesFailure = () => action(LocationsTypes.STATES_FAILURE);

export const citiesRequest = (stateUF: string) => ({
    type: LocationsTypes.CITIES_REQUEST,
    stateUF,
})

export const citiesSuccess = (data: string[]) => action(LocationsTypes.CITIES_SUCCESS, { data });


export const citiesFailure = () => action(LocationsTypes.CITIES_FAILURE);
