import { FaArrowLeft } from "react-icons/fa";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { sendRequisitionRequest } from "../../../store/ducks/service/actions";
import Loading from "../../../featureComponents/loading/LoadingScreen";
import { fetchUserRequest } from "../../../store/ducks/user/actions";
import { Requisition } from "../../../store/ducks/service/types";
import { UserInfos } from "../../../store/ducks/user/types";
import Alert from "../../../featureComponents/alert/Alert";
import { ApplicationState } from "../../../store";
import {
  Container,
  HeaderContainer,
  Header,
  Body,
  Content,
  MessageInput,
  BorderText,
  Button,
  EmailInput,
  EmailContainer,
} from "./styles";

interface StateProps {
  user: UserInfos | null;
  loading: boolean;
  requisitionSent: boolean;
}

interface DispatchProps {
  sendServiceRequisition(requisition: Requisition, token: string | null): void;
  fetchUserRequest(access_token: String): void;
}

interface OwnProps {
  history: any;
  location: any;
}

type Props = StateProps & DispatchProps & OwnProps;

class ServiceRequest extends Component<Props> {
  componentDidMount() {
    const { loading, location, user } = this.props;

    if (
      !loading &&
      location.state.requisition &&
      ((user && user.email) ||
        location.state.requisition.email_for_feedback.trim().length > 0)
    ) {
      this.sendService();
    }

    this.fetchUser();
  }

  state = {
    message: "",
    successAlert: false,
    email: "",
  };

  render() {
    const { successAlert } = this.state;
    const { loading, location, requisitionSent } = this.props;

    return (
      <Container id="Requisition">
        <HeaderContainer>
          <Header>
            <Link
              to="/catalog"
              style={{
                textDecoration: "none",
                display: "flex",
              }}
            >
              <FaArrowLeft color="white" size={20} />
            </Link>
            <p>Registro de Serviços</p>
          </Header>
        </HeaderContainer>
        <Body>
          <Content>
            <span>
              Qual serviço você gostaria de encontrar aqui no Catálogo
              Guiaderodas?
            </span>
            <EmailContainer>
              <span>
                Insira um email caso não tenha cadastrado um anteriormente!
              </span>
              <EmailInput
                onChange={this.saveEmail.bind(this)}
                type="email"
                placeholder="Email"
              />
            </EmailContainer>
            <BorderText>Mensagem</BorderText>
            <MessageInput
              onChange={this.saveMessage.bind(this)}
              defaultValue={
                location.state.requisition
                  ? location.state.requisition.content
                  : null
              }
            />
            <Button
              disabled={this.buttonDisabled()}
              onClick={() => this.sendService()}
            >
              Enviar
            </Button>
          </Content>
        </Body>
        {loading && (
          <Loading
            height={window.innerHeight}
            title="Enviando"
            subtitle="Serviço"
          />
        )}
        {requisitionSent && successAlert && (
          <Alert
            onOKClicked={() => this.alertConfirm()}
            height={window.innerHeight}
            title="Sucesso!"
            message="Sua requisição foi enviada com sucesso."
            textButton="OK"
          />
        )}
      </Container>
    );
  }

  alertConfirm() {
    const { history } = this.props;

    this.setState({ successAlert: false });
    history.push("/catalog");
  }

  fetchUser() {
    const token = localStorage.getItem("AUTH_DATA");

    if (token) {
      const { fetchUserRequest } = this.props;

      fetchUserRequest(token);
    }
  }

  saveEmail(event: any) {
    if (event.target.value.includes("@")) {
      this.setState({ email: event.target.value });
    } else {
      this.setState({ email: "" })
    }
  }

  saveMessage(event: any) {
    this.setState({ message: event.target.value });
  }

  buttonDisabled() {
    const { message, email } = this.state;
    const { user, location } = this.props;

    if (user && !user.email) {
      if ((message.trim().length > 0 || location.state.requisition) && email.trim().length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      if (message.trim().length > 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  sendService() {
    const token = localStorage.getItem("AUTH_DATA");
    const { user, history, location, sendServiceRequisition } = this.props;

    if (location.state.requisition && user) {
      const { id, email, first_name } = user;
      const requisition = location.state.requisition;

      requisition.user_id = id;
      requisition.user_email = email ? email : "";
      requisition.user_name = first_name ? first_name : "";

      sendServiceRequisition(requisition, token);

      this.setState({ successAlert: true });
    } else {
      if (user && token) {
        const { id, email, first_name } = user;

        const requisition: Requisition = {
          user_id: id,
          user_email: email ? email : "",
          user_name: first_name ? first_name : "",
          subject: "Requisição de Serviço",
          content: this.state.message,
          email_for_feedback: this.state.email,
        };

        this.setState({ successAlert: true });
        sendServiceRequisition(requisition, token);
      } else {
        const requisition: Requisition = {
          user_id: "",
          user_email: "",
          user_name: "",
          subject: "Requisição de Serviço",
          content: this.state.message,
          email_for_feedback: this.state.email,
        };

        history.push("/login", { screen: "service-request", requisition });
      }
    }
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.user,
  loading: state.service.loading,
  requisitionSent: state.service.requisitionSent,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    sendServiceRequisition: (requisition: Requisition, token: string | null) =>
      dispatch(sendRequisitionRequest(requisition, token)),
    fetchUserRequest: (access_token: string) =>
      dispatch(fetchUserRequest(access_token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequest);
